 
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type FilterOption = {
    field: string
    operator: 'hash_in' | 'equals' | 'not_equals' | 'contains' | 'not_contains' | 'regex'
    value: string
}

export type AiringCombinationPerformanceTableQueryParameters = {
    date_end: string
    date_start: string
}

export type AiringCombinationPerformanceTimelineQueryParameters = {
    date_end: string
    date_start: string
}

export type FlightPerformanceQueryParameters = {
    date_end: string
    date_start: string
}

export type PerformanceTableQueryParameters = {
    date_end: string
    date_start: string
    flight_level_hash: string
    station_level_hash: string
}

export type StationPerformanceQueryParameters = {
    date_end: string
    date_start: string
}

export type TimeslotPerformanceQueryParameters = {
    date_end: string
    date_start: string
}

export type TotalsQueryParameters = {
    date_end: string
    date_start: string
}

export class TvService {
    static async getAiringCombinationPerformanceTable(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        queryParameters: AiringCombinationPerformanceTableQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/airing-combination-performance/table?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getAiringCombinationPerformanceTimeline(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string,
        queryParameters: AiringCombinationPerformanceTimelineQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/airing-combination-performance/timeline/${type}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getFilter(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string // flight, genre-after, genre-before, program-after, program-before, spot-duration, station, timeslot
    ): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/filter/${type}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getFlightPerformance(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string, // cpa, cpv
        queryParameters: FlightPerformanceQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/flight-performance/${type}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getPerformanceTable(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        level: string, // flight, station, timeslot
        queryParameters: PerformanceTableQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/performance/table/${level}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getStationPerformance(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string, // cpa, cpv
        queryParameters: StationPerformanceQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/station-performance/${type}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getTimeslotPerformance(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string, // cpa, cpv
        queryParameters: TimeslotPerformanceQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/timeslot-performance/${type}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getTotals(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        queryParameters: TotalsQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/totals?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }
}
