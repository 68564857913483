import { Button, ButtonText, Panel, View } from '@adtriba/ui'
import React, { useEffect, useRef } from 'react'
import { DialogComponent } from './dialog.component'

interface NoAccountsDialogProps {
    show: boolean
    logout: () => void
}

const NoAccountsDialog = ({ show, logout }: NoAccountsDialogProps) => {
    const ref = useRef<HTMLDialogElement>(null)

    useEffect(() => {
        if (show) {
            ref.current?.showModal()
        } else {
            ref.current?.close()
        }
    }, [show])

    return (
        <DialogComponent ref={ref}>
            <View class="p-fixed top-0 left-0" width="100%" height="100%" row style={{ zIndex: 10 }}>
                <Panel
                    width={600}
                    innerClass="d-flex flex-column gap-6 pb-0"
                    style={{ padding: '1.5rem', boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.05)' }}
                >
                    <h2
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        No accounts found
                    </h2>
                    <div className="gap-4 d-flex align-items-center">
                        <img src={'/favicon.png'} height={20} alt="Adtriba Accounts" />
                        <h3
                            style={{
                                fontWeight: 600,
                            }}
                        >
                            Welcome to Adtriba Core
                        </h3>
                    </div>
                    <p className="leading-6">
                        There was an error while we were trying to load your account(s).
                        <br />
                        Please log out and try again.
                    </p>
                    <p>
                        If you run into any trouble again, please contact:
                        <br />
                        <a href="mailto:support@adtriba.com">
                            <b
                                style={{
                                    fontWeight: 600,
                                    color: '#1CC7D0',
                                }}
                            >
                                support@adtriba.com
                            </b>
                        </a>
                    </p>

                    <Button onClick={() => logout()} class="mr-auto">
                        <ButtonText>Logout</ButtonText>
                    </Button>
                </Panel>
            </View>
        </DialogComponent>
    )
}

export default NoAccountsDialog
