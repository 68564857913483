import numeral from 'numeral'

/*
 * Locale (taken from numeral, but pasted here to we are able to customise)
 * https://github.com/adamwdraper/Numeral-js/blob/master/locales/de.js
 */

export const VALID_LOCALES = ['GBP', 'CAD', 'SEK', 'CHF', 'EUR', 'USD', 'HUF', 'DKK', 'CZK', 'PLN', 'SAR', 'NOK', 'CNY']

numeral.register('locale', 'default', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: ' ',
    },
})

numeral.register('locale', 'GBP', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '£',
    },
})

numeral.register('locale', 'CAD', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '$',
    },
})

numeral.register('locale', 'SEK', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'kr',
    },
})

numeral.register('locale', 'CHF', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'Fr.',
    },
})

numeral.register('locale', 'EUR', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '€',
    },
})

numeral.register('locale', 'USD', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '$',
    },
})

numeral.register('locale', 'HUF', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'Ft',
    },
})

numeral.register('locale', 'DKK', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'kr',
    },
})

numeral.register('locale', 'CZK', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'Kč',
    },
})

numeral.register('locale', 'PLN', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'zł',
    },
})

numeral.register('locale', 'SAR', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '﷼',
    },
})

numeral.register('locale', 'NOK', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: 'kr',
    },
})

numeral.register('locale', 'CNY', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => '.',
    currency: {
        symbol: '¥',
    },
})
