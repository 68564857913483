import { Label } from '@adtriba/ui'
import React from 'react'
import './spend-mapper-v3.component.sass'

export const ChannelMapped = ({ mapped }) => {
    return (
        <Label solidElectric={mapped} class="mr-500" icon={mapped ? 'check' : null}>
            {mapped ? 'Mapped' : 'Unmapped'}
        </Label>
    )
}
