import { createURLQueryString, returnParser } from '../../../helpers/util'

export class ChannelMapperV3Service {
    static async put(token: string, accountId: string, mappings: any[]): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/channel-mapper`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(mappings),
        })
        return await returnParser(result)
    }

    static async preview(token: string, accountId: string, mappings: any, queryStringParameters: any): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/channel-mapper/preview?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(mappings),
        })
        return await returnParser(result)
    }

    static async verify(token: string, accountId: string, mappings: any): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/channel-mapper/verify`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(mappings),
        })
        return await returnParser(result)
    }

    static async get(token: string, accountId: string, projectId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async delete(token: string, accountId: string, projectId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getCopy(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper/copy?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }
}
