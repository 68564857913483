import * as Sentry from '@sentry/react'
import { Dayjs } from 'dayjs'
import numeral from 'numeral'
import {
    CHART_TIME_PRESETS,
    DATE_FORMAT_API,
    JSON,
    LOGIC,
    LOG_ENABLED_ENVIRONMENTS,
    LOG_ERROR,
    PLACEHOLDER_UUID_FOR_URL,
    TEXT,
} from '../constants'
import process from 'process'
import { VALID_LOCALES } from '../locales'
import { FilterOption } from '../modules/app/services/customer-journey-v2.service'
import { StorageService } from '../services/storage.service'
import { currencySymbolMap } from './currencySymbolMap'

export const formatIncomingRulesets = (rules, options) => {
    return rules.map((rules: any) => {
        return {
            logic: LOGIC.OR,
            rows: rules.map((rule: any) => {
                const { field, operator, value } = rule
                const comparison = operator.toUpperCase()
                const optionIndex = options.findIndex((option: any) => option.value == field)
                const option = options[optionIndex]
                const { valuesFor, values } = option
                const selectValue =
                    !!values && valuesFor.includes(comparison)
                        ? values.map((value) => value.value).indexOf(value)
                        : value

                return {
                    option: optionIndex,
                    comparison,
                    value: selectValue,
                    logic: LOGIC.AND,
                }
            }),
        }
    })
}

export const formatOutgoingRulesets = (groups, options) => {
    return groups.map((group) => {
        return group.rows.map((row: any) => {
            // if the field has a dropdown
            // then we need to get the value from those possibilities
            // because it will be an array index
            const option = options[row.option]
            const field = option.value
            const values = option.values
            const valuesFor = option.valuesFor
            const comparison = row.comparison.toUpperCase()
            const value = !!values && valuesFor.includes(comparison) ? values[+row.value].value : row.value

            return { field, operator: comparison.toLowerCase(), value }
        })
    })
}

export const hasEmptyRules = (groups) => {
    if (groups.length == 0) return true
    let empty = false
    groups.map((group: any) => {
        if (group.rows.length == 0) empty = true
    })
    return empty
}

export const filterGroupsAreEmpty = (groups) => {
    if (groups.length == 0) return true
    let emptyGroups = 0
    groups.map((group: any) => {
        if (group.rows.length == 0) emptyGroups++
    })
    return emptyGroups == groups.length
}

export const formatRulesForSending = (rules) => {
    return rules.map((rule: any, index: number) => {
        let mutableRule = {
            ...rule,
            display_name: rule.displayName,
            position: index,
        }
        delete mutableRule.index
        delete mutableRule.displayName
        delete mutableRule.originalPosition
        return mutableRule
    })
}

export const getChartTimePreset = (startDate: Dayjs, endDate: Dayjs) => {
    let timePreset: any = { text: 'N/A' }

    CHART_TIME_PRESETS.map((preset: any) => {
        if (
            startDate.startOf('day').isSame(preset.startDate.startOf('day')) &&
            endDate.endOf('day').isSame(preset.endDate.endOf('day'))
        ) {
            timePreset = preset
        }
    })

    return timePreset
}

export const validateFilterRows = (rows: any[]): boolean => {
    let valid = true

    rows.map((row: any) => {
        if (row.value.trim() == '') valid = false
    })

    return valid
}

export const getCachedFilterRules = (accountId: string, key: string) => {
    const filter = StorageService.getStorage(getCacheKey(accountId, key))

    if (filter) {
        const parsedFilter = window.JSON.parse(filter)

        if (parsedFilter.length != 0) {
            return parsedFilter
        }
    }

    return null
}

export const findIndexHash = (arr: any[], hash: any) => {
    const index = arr.findIndex((a: any) => a.hash == hash)
    return index == -1 ? null : index
}

export const getFilterOption = (field: string, value: string): FilterOption => {
    return {
        field,
        operator: 'hash_in',
        value,
    }
}

export const getFilterOptionHashWithId = (selection: number[], array: any[]) => {
    return selection.map((selectedIndex: number) => array[selectedIndex].id).join(',')
}

export const getFilterOptionHash = (selection: number[], array: any[]) => {
    return selection.map((selectedIndex: number) => array[selectedIndex].hash).join(',')
}

export const getCacheKey = (accountId: string, key: string) => {
    return key + '_' + accountId
}

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace)
}

export const getEnabledRule = (rule: string, rules: any[]) => {
    let enabled = false
    rules.map((r: any) => {
        if (r.name == rule) enabled = r.enabled
    })
    return enabled
}

export const sanitizeLocale = (locale: string) => {
    if (!locale) return 'EUR'

    const index = VALID_LOCALES.findIndex((l: string) => l.toUpperCase() == locale.toUpperCase())

    if (index == -1) {
        return 'EUR'
    } else {
        return locale
    }
}

export const waitForState = (callback, timeout = 200) => {
    setTimeout(() => {
        callback()
    }, timeout)
}

export const isValidReport = (accountId, projectId, reportId) => {
    if (!accountId) return false
    if (!projectId) return false
    if (!reportId) return false
    if (accountId == PLACEHOLDER_UUID_FOR_URL) return false
    if (projectId == PLACEHOLDER_UUID_FOR_URL) return false
    if (reportId == PLACEHOLDER_UUID_FOR_URL) return false

    return true
}

export const filterValidLabelHash = (d: any) => !!d.hash && !!d.label

export const filterValidLabelValue = (d: any) => !!d.value && !!d.label

export const shortenBarChartLabel = (label: string) => {
    if (label.length > 10) {
        return label.slice(0, 10) + '...'
    } else {
        return label
    }
}

export const getAccountProductRole = (productName: string, account: any) => {
    const product = account.products.find((product: any) => product.name == productName)
    return product ? product.role : ''
}

export const sanitizeNumber = (number) => {
    return number ? parseFloat(parseFloat(number).toFixed(5)) : 0
}

export const setLocale = (locale: string) => {
    setTimeout(() => numeral.locale(locale))
}

export const getUrlParam = (param: string) => {
    const parts = window.location.href.split('/')
    const location = parts.findIndex((part) => part == param)
    return location == -1 ? null : parts[location + 1]
}

export const getPropertyNameAtIndex = (object: any, index1: number): string => {
    let property = ''

    Object.keys(object).map((key: string, index2: number) => {
        if (index1 == index2) property = key
    })

    return property
}

export const routeIsActive = (path: string): boolean => {
    const { pathname, search } = window.location
    return path == pathname + search
}

export const returnParser = async (result: any, type: string = JSON): Promise<any> => {
    try {
        // Special case for reports that have no data
        // Only for customer journey, tracking, tv & performance
        if (result.status == 204) {
            throw result.status
        } else if (result.status != 200) {
            Sentry.captureException(result)
            throw result.status
        } else {
            if (result.headers.get('content-type')) {
                switch (type) {
                    case JSON:
                        return result.json()
                    case TEXT:
                        return result.text()
                    default:
                        return result.json()
                }
            } else {
                return result
            }
        }
    } catch (e) {
        Sentry.captureException(e)
        throw result.status
    }
}

export const returnGoogleAdsApiIntegrationParser = async (result: any, type: string = JSON): Promise<any> => {
    try {
        if (result.status != 200) {
            Sentry.captureException(result)
            return result.json()
        } else {
            return result.json()
        }
    } catch (e) {
        Sentry.captureException(e)
        throw e
    }
}

export const formatDateForAPI = (dayjs: Dayjs): string => {
    return dayjs.format(DATE_FORMAT_API)
}

export const nFormatter = (num: number, digits: number): string => {
    const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    let i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const createURLQueryString = (parameters: any): string => {
    const params = []
    for (const parameter in parameters) {
        if (parameters[parameter] != undefined && parameters[parameter] != null) {
            // If it's a string array, then flatten it
            const value = Array.isArray(parameters[parameter]) ? parameters[parameter].join(',') : parameters[parameter]
            params.push(parameter + '=' + encodeURIComponent(value))
        }
    }
    return params.join('&')
}

export const getQueryStringValue = (name: string): string => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const bytesToSize = (bytes: number): string => {
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb']
    if (bytes == 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) + '', 10)
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const urlParser = (url: string): string[] => {
    if (!url) return []
    if (typeof url != 'string') return []

    const match = url.match(/(http[s]?:\/\/.*)/i)
    return match ? match[0].split(' ') : []
}

export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

export const showLocalPushNotification = (title: string, body: string): void => {
    navigator.serviceWorker.ready.then((register) => {
        const serviceWorkerRegistration = register

        if (serviceWorkerRegistration) {
            serviceWorkerRegistration.showNotification(title, {
                body,
                icon: '',
                image: '',
            })
        }
    })
}

export const copyToClipboard = (value: string): void => {
    const tempInput: any = document.createElement('input')
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px;'
    tempInput.value = value
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
}

export const logError = (...args: any) => {
    if (LOG_ERROR && LOG_ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_NODE_ENV)) {
        console.log(...args)
    }
}

export const isValidEmail = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const decimalToMinutes = (minutes: number): string => {
    const sign = minutes < 0 ? '-' : ''
    const min = Math.floor(Math.abs(minutes))
    const sec = Math.floor((Math.abs(minutes) * 60) % 60)
    return sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
}

export const stripSpecialChars = (text: string): string => {
    return text ? text.replace(/[`~!@#$%^&*()|+\= ?;:'",.<>\{\}\[\]\\\/]/gi, '') : ''
}

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const classNames = (object: any): string => {
    const classArray = []

    for (const property in object) {
        if (object[property]) classArray.push(property)
    }

    return classArray.join(' ')
}

export const findRoleTypeWithRoleId = (roles: any[], roleId: string): string => {
    return roles.reduce((acc, val) => {
        if (val.id == roleId) {
            return acc + val.type
        } else {
            return acc + ''
        }
    }, '')
}

export const groupBy = (collection: any[], property: string) => {
    let i = 0,
        val,
        index,
        values = [],
        result = []
    for (; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        if (index > -1) result[index].push(collection[i])
        else {
            values.push(val)
            result.push([collection[i]])
        }
    }
    return result
}

export const extractHash = (source: string): string => {
    const regex = /\.(\w+)\.js$/
    const match = source.match(regex)
    return match && match[1]
}

export const isDev = process.env.REACT_APP_NODE_ENV == 'development'
export const isProd = process.env.REACT_APP_NODE_ENV == 'production'

export const getSymbolFromCurrency = (currencyCode: string) => {
    if (typeof currencyCode !== 'string') {
        return undefined
    }

    const code = currencyCode.toUpperCase()

    if (!Object.prototype.hasOwnProperty.call(currencySymbolMap, code)) {
        return undefined
    }

    return currencySymbolMap[code]
}
