import process from 'process'
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type FilterOption = {
    field: string
    operator: 'hash_in' | 'equals' | 'not_equals' | 'contains' | 'not_contains' | 'regex'
    value: string
}

export type PerformanceQueryParameters = {
    date_end: string
    date_start: string
    channel_level_hash?: string
    source_level_hash?: string
    customer_type: string
}

export class PerformanceService {
    static async getFilter(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        level: string, // campaign, channel, source
        customerType: string
    ): Promise<any> {
        const url = !!customerType
            ? `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/filter/${level}?customer_type=${customerType}`
            : `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/filter/${level}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getComparisonTable(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        level: string,
        filterOptions: FilterOption[],
        queryParameters: PerformanceQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/comparison-table/${level}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getLastClickValidation(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        filterOptions: FilterOption[],
        queryParameters: PerformanceQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/last-click-validation?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getTable(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        level: string, // campaign, channel, source
        filterOptions: FilterOption[],
        queryParameters: PerformanceQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/table/${level}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getTimeline(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        type: string, // roas, spend
        filterOptions: FilterOption[],
        queryParameters: PerformanceQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/timeline/${type}?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }

    static async getTotals(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        filterOptions: FilterOption[],
        queryParameters: PerformanceQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/totals?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })

        return await returnParser(result)
    }
}
