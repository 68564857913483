import { Label, Tooltip, View } from '@adtriba/ui'
import React from 'react'
import { POPOVER } from '../../../../constants'
import './spend-mapper-v3.component.sass'

export const MappedProgress = ({ blue, red, total }) => {
    const blueWidth = Math.floor((blue / total) * 100)
    const redWidth = Math.floor((red / total) * 100)

    return (
        <View row width="fit-content" flex="none">
            <View class="p-relative mr-500" width={200} height={5}>
                <View class="bg-cl-gray-300 bdr-r-900 p-absolute w-100 h-100" />
                <View style={{ float: 'left', width: redWidth + '%' }} class="bg-cl-red bdr-r-900 h-100 p-relative" />
                <View
                    style={{ float: 'left', width: blueWidth + '%' }}
                    class="bg-cl-electric bdr-r-900 h-100 p-relative"
                />
            </View>

            {!red && <Label solidElectric={blueWidth == 100}>{String(blue)}</Label>}

            {!!red && (
                <Tooltip
                    position={POPOVER.POSITION.LEFT}
                    text={`${red} ${red == 1 ? 'campaign' : 'campaigns'} have been premapped (red)`}
                >
                    <Label solidElectric={blueWidth == 100}>{String(blue + red)}</Label>
                </Tooltip>
            )}
        </View>
    )
}
