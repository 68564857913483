import { ProjectService } from './project.service'
import { ReportService } from './report.service'

export class AccountService {
    static async getAccountStructure(token: string, accountId: string) {
        // Get the list of projects
        const projects = await ProjectService.get(token, accountId)

        // Append the list children reports to each project
        const reportPromises = projects.map(async (project: any) => {
            try {
                project.reports = await ReportService.get(token, accountId, project.id)
                return project // Return the project with reports
            } catch (error) {
                console.error(`Error fetching reports for project ${project.id}:`, error)
                project.reports = []
                return project // Return the project even if an error occurs
            }
        })

        return Promise.all(reportPromises)
    }
}
