import { Input, Modal, Table, View } from '@adtriba/ui'
import { IDataSet } from '@adtriba/ui/lib/table/table'
import { useAuth0 } from '@auth0/auth0-react'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import { ERROR, NUMERAL, POSITION, TABLE } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { getSymbolFromCurrency, logError } from '../../../../helpers/util'
import { SpendMapperService } from '../../services/spend-mapper-v3.service'
import './spend-mapper-v3.component.sass'

export const UnmappedSpendTable = (props) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [filter, setFilter] = useState('')
    const [data, setData] = useState<IDataSet[]>([])
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const selectedProject = app.selectedAccount.find((project: any) => project.id == app.selectedProjectId)

    const fetchData = async () => {
        setLoading(true)
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getUnmappedSourceTable(token, accountId, projectId, {
                project_id: projectId,
            })

            setLoading(false)
            setData([
                {
                    lastDataSet: true,
                    heading: 'Data Sources',
                    headings: ['Data Source', 'Sub Platform', 'Spend'],
                    align: [null, null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                    formats: [
                        null,
                        null,
                        (val) =>
                            `${getSymbolFromCurrency(selectedProject.currency ?? 'EUR')} ${numeral(val).format(
                                NUMERAL.FORMATS.CURRENCY_NO_LOCALE
                            )}`,
                    ],
                    data: result.map((d: any) => [d.datasource_name, d.sub_platform_name, d.spend]),
                },
            ])
        } catch (e) {
            setLoading(false)
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Modal
            width="60%"
            height={800}
            position={POSITION.CENTER}
            borderRadius={20}
            onDismiss={props.onDismiss}
            footer={null}
            header={null}
            icon="money"
            title="Unmapped Spend"
            showClose={true}
        >
            <View class="p-900 pb-none">
                <LoaderComponent loading={loading} />
                <NotificationComponent notification={notification} error={error} />

                <Input
                    clear
                    type="text"
                    placeholder="Filter"
                    value={filter}
                    onChange={(value) => setFilter(value)}
                    onBlur={(value) => setFilter(value)}
                />
            </View>
            <Table
                large
                pageSize={15}
                filter={filter}
                data={data}
                onCleanup={(data1) => console.log(data1)}
                onLoad={(dataIndex: number, row: any) => {
                    // console.log('LOADING! -> ', dataIndex, row)
                    // Fetch some new data via API
                    // And add that data to the history
                    //setData1([...data1, defaultDataNoWidths('Second one', 'Second Wine', 'Second Water', true)])
                }}
            />
        </Modal>
    )
}
