import React, { FunctionComponent, ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const ProtectedRoute = (props: any) => {
    const { component, ...args } = props

    return (
        <>
            <Route
                component={withAuthenticationRequired(component, {
                    onRedirecting: () => <strong>Loading</strong>,
                })}
                {...args}
            />
        </>
    )
}

export default ProtectedRoute
