import React, { forwardRef, RefObject } from 'react'
import './dialog.component.sass'
import classNames from 'classnames'

interface IDialogComponent {
    children?: any
    args?: any
    className?: string
}

export const DialogComponent = forwardRef(
    ({ children, className, args }: IDialogComponent, ref: RefObject<HTMLDialogElement>): JSX.Element => {
        return (
            <dialog
                {...args}
                ref={ref}
                style={{
                    zIndex: 10000,
                }}
                className={classNames('dialog-component', className)}
            >
                {children}
            </dialog>
        )
    }
)
