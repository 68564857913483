import {
    Check,
    Flexer,
    Heading,
    Icon,
    If,
    Input,
    Label,
    Menu,
    MenuItem,
    MenuItems,
    Modal,
    Option,
    Options,
    Panel,
    ScrollView,
    Text,
    View,
} from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs from 'dayjs'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import { COLOR, DATE_FORMAT_API, DATE_FORMAT_METRIC, INSIGHTS, INSIGHTS_REPORTS, POSITION } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { classNames, createURLQueryString, logError } from '../../../../helpers/util'
import { InsightsService } from '../../services/insights.service'
import './insights.component.sass'

const navigateToTarget = (history: any, accountId, projectId, target: any) => {
    switch (target.level) {
        case INSIGHTS.LEVELS.REPORT:
            const reportId = target.id
            const reportType = target.type
            const queryString = createURLQueryString(target.filter)

            switch (reportType) {
                case INSIGHTS.TYPES.TRACKING:
                    history.push(`/accounts/${accountId}/projects/${projectId}/tracking?${queryString}`)
                    break
                case INSIGHTS.TYPES.CUSTOMER_JOURNEY:
                    history.push(
                        `/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey?${queryString}`
                    )
                    break
                default:
                    history.push(
                        `/accounts/${accountId}/projects/${projectId}/reports/${reportId}/${reportType}?${queryString}`
                    )
                    break
            }
            break
    }
}

interface IInsight {
    color: string
    type: string
    name?: string
    onClick: any
    onRead: any
    onCheck: any
    date: any
    compact?: boolean
    read: boolean
    checked: boolean
    id: any
    description: string
    labels: any
    children?: any
}

export const Insight = (props: IInsight) => {
    const [show, setShow] = useState(false)
    const app = useContext(AppContext)

    const getIcon = () => {
        switch (props.type) {
            case INSIGHTS.TYPES.CUSTOMER_JOURNEY:
                return 'dataquality'
            case INSIGHTS.TYPES.TV:
                return 'tv'
            case INSIGHTS.TYPES.PERFORMANCE:
                return 'performance'
            case INSIGHTS.TYPES.TRACKING:
                return 'customer-journey'
            default:
                return 'zap'
        }
    }

    const description = () => {
        const text = show ? props.description : props.description.substring(0, 100)
        const textClasses = classNames({
            'fw-700': !props.read,
            'buttonize': true,
        })

        return (
            <>
                <span className={textClasses} onClick={props.onClick}>
                    {text}
                </span>
                <If if={props.description.length > 100}>
                    <span onClick={() => setShow(!show)} className="buttonize fw-500 cl-gray-400">
                        {show ? ' ... Read less' : ' ... Read more'}
                    </span>
                </If>
            </>
        )
    }

    const borderClasses = classNames({
        'pl-500': true,
        'pr-500': true,
        'pt-300': true,
        'pb-300': true,
        'bdr-left': true,
    })

    const moreClasses = classNames({
        'pl-300': !props.compact,
        'buttonize': true,
    })

    return (
        <View class="bdr-bottom bdr-cl-gray-300 w-100">
            <View
                class={borderClasses}
                style={{ borderColor: props.read ? 'transparent' : props.color, borderWidth: '0.3rem' }}
                row={!props.compact}
            >
                <View row justify="flex-start">
                    <If if={!props.compact}>
                        <View class="mr-500">
                            <Check
                                onChange={props.onCheck}
                                checked={props.checked}
                                disabled={app.role === 'READER'}
                                small
                            />
                        </View>
                    </If>

                    <View flex={1} style={{ paddingRight: 10 }}>
                        <Text>{description()}</Text>
                    </View>

                    <If if={!props.compact}>
                        <Label icon={getIcon()} aqua class="mr-500">
                            {props.name}
                        </Label>
                    </If>
                </View>
                <View row flex="none" class={props.compact ? 'mt-200' : ''}>
                    {props.labels}
                    <If if={props.compact}>
                        <Flexer />
                    </If>
                    <View width={80} row flex="none" justify="flex-end">
                        <Text small class="cl-gray-400">
                            {props.date.format(DATE_FORMAT_METRIC)}
                        </Text>
                    </View>
                </View>
                <View>
                    <Menu
                        position={props.compact ? POSITION.LEFT : POSITION.RIGHT}
                        width={175}
                        menu={
                            <MenuItems>
                                <If if={!props.read}>
                                    <MenuItem>
                                        <View row onClick={props.onRead} justify="flex-start">
                                            <Icon icon="check" color={COLOR.COLOR_GRAY_500} size={16} />
                                            <Text class="pl-300 cl-gray-600">Mark as read</Text>
                                        </View>
                                    </MenuItem>
                                </If>
                                <MenuItem>
                                    <View row onClick={props.onClick} justify="flex-start">
                                        <Icon icon="zap" color={COLOR.COLOR_GRAY_500} size={16} />
                                        <Text class="pl-300 cl-gray-600">View insight</Text>
                                    </View>
                                </MenuItem>
                            </MenuItems>
                        }
                    >
                        <View row class={moreClasses} style={{ position: 'relative', left: props.compact ? -5 : 0 }}>
                            <Icon icon="more" size={15} color={COLOR.COLOR_GRAY_500} />
                        </View>
                    </Menu>
                </View>
            </View>
        </View>
    )
}

interface IQuickInsightsComponent {
    accountId: string
    projectId: string
    reportId?: string
    type: any
    onDismiss: any
    children?: any
}

export const QuickInsightsComponent = (props: IQuickInsightsComponent): ReactElement => {
    const { getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const history = useHistory()
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId, reportId, type } = props
    const [value, setValue] = useState('')
    const [insights, setInsights] = useState<any>([])
    const [read, setRead] = useState(true)

    const fetchData = async () => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await InsightsService.get(token, accountId, {
                project_id: projectId,
                report_id: reportId,
                type,
            })

            setLoading(false)
            setInsights(
                result.map((insight: any) => ({
                    id: insight.id,
                    checked: false,
                    read: insight.read,
                    description: insight.description,
                    date: dayjs(insight.date),
                    target: insight.target,
                    labels: insight.labels,
                }))
            )
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleInsightClick = (insight: any) => {
        handleInsightRead(insight)
        navigateToTarget(history, accountId, projectId, insight.target)
    }

    const handleInsightCheck = (index1) => {
        setInsights(
            insights.map((insight: any, index2: number) => {
                if (index1 == index2) return { ...insight, checked: !insight.checked }
                return insight
            })
        )
    }

    const handleMarkAllAsRead = async () => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await InsightsService.patch(
                token,
                accountId,
                insights.map((insight: any) => ({
                    id: insight.id,
                    read: true,
                }))
            )

            setLoading(false)
            setInsights(insights.map((insight: any) => ({ ...insight, read: true })))
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleInsightRead = async (newInsight) => {
        if (app.role === 'READER') return
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            await InsightsService.patch(token, accountId, [{ id: newInsight.id, read: true }])

            setInsights(
                insights.map((insight: any) => {
                    if (insight.id == newInsight.id) return newInsight
                    return insight
                })
            )
            setLoading(false)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        if (!projectId || projectId == 'null' || projectId == null) return
        fetchData()
    }, [accountId, projectId, reportId, type])

    return (
        <Modal
            class="mr-900"
            width="500px"
            icon="zap"
            height="95%"
            position={POSITION.RIGHT}
            borderRadius={10}
            onDismiss={props.onDismiss}
            footer={null}
            header={
                <View
                    row
                    justify="flex-end"
                    class="mr-900 buttonize"
                    onClick={() => window.open('https://help.adtriba.com/en/collections/2825246-adtriba-core')}
                >
                    <Label>What is this?</Label>
                    <Flexer />
                </View>
            }
            title="Quick Insights"
            showClose={true}
        >
            <>
                <LoaderComponent loading={loading} />
                <NotificationComponent notification={notification} error={error} />
                <View column align="stretch" height="100%">
                    <View row flex="none">
                        <View column class="p-500 bdr-bottom bdr-cl-gray-300">
                            <View row width="100%">
                                <Input
                                    type="text"
                                    clear
                                    unit={<Icon icon="search" size={15} color={COLOR.COLOR_GRAY_400} />}
                                    placeholder="Filter"
                                    value={value}
                                    onChange={(value) => setValue(value)}
                                    onBlur={(value) => setValue(value)}
                                />
                            </View>
                            <View row width="100%" justify="flex-start" class="mt-500">
                                <Icon icon="flag" size={15} color={COLOR.COLOR_ELECTRIC} />
                                <Text class="fw-600 pl-200 cl-electric" small>
                                    You have {insights.length} {insights.length == 1 ? 'insight' : 'insights'}
                                </Text>
                                <Flexer />
                                {app.role !== 'READER' && (
                                    <Text class="fw-600 cl-electric buttonize" small onClick={handleMarkAllAsRead}>
                                        Mark all as read
                                    </Text>
                                )}
                                <Text class="fw-600 ml-900 cl-electric buttonize" small onClick={() => setRead(!read)}>
                                    {read ? 'Hide read' : 'Show read'}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <ScrollView>
                        {insights
                            .filter((insight: any) => (read ? true : insight.read == false))
                            .filter((insight: any) => insight.description.toLowerCase().includes(value.toLowerCase()))
                            .map((insight: any, index1: number) => (
                                <Insight
                                    id={insight.id}
                                    color={COLOR.COLOR_ELECTRIC}
                                    onClick={() => handleInsightClick({ ...insight, read: true })}
                                    compact
                                    read={insight.read}
                                    type={insight.target.type}
                                    name={null}
                                    key={index1}
                                    checked={insight.checked}
                                    onRead={() => handleInsightRead({ ...insight, read: true })}
                                    onCheck={() => {}}
                                    date={insight.date}
                                    description={insight.description}
                                    labels={insight.labels.map((label: string, index2: number) => (
                                        <View key={index2} row flex="none" width="fit-content" class="pr-100">
                                            <If if={!!label}>
                                                <Label>{label}</Label>
                                            </If>
                                        </View>
                                    ))}
                                />
                            ))}
                    </ScrollView>
                </View>
            </>
        </Modal>
    )
}

interface IInsightsGroupComponent {
    insights: any[]
    report: string
    grouped: boolean
    onUpdate: any
    filter: string
    children?: any
}

export const InsightsGroupComponent = (props: IInsightsGroupComponent): ReactElement => {
    const { getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const history = useHistory()
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const [insights, setInsights] = useState([])
    const [read, setRead] = useState(true)
    const [color, setColor] = useState('')
    const [icon, setIcon] = useState('')
    const [name, setName] = useState('')

    const handleInsightClick = async (newInsight: any) => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()

            if (app.role !== 'READER') {
                await InsightsService.patch(token, accountId, [{ id: newInsight.id, read: true }])
            }

            props.onUpdate(
                insights.map((insight: any) => {
                    if (insight.id == newInsight.id) return newInsight
                    return insight
                })
            )
            setLoading(false)
            navigateToTarget(history, accountId, projectId, newInsight.target)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const getCheckedInsights = (read: boolean) => {
        return insights
            .filter((insight: any) => insight.checked)
            .map((insight: any) => ({
                id: insight.id,
                read,
            }))
    }

    const handleInsightCheck = (index1) => {
        if (app.role === 'READER') return

        setInsights(
            insights.map((insight: any, index2: number) => {
                if (index1 == index2) return { ...insight, checked: !insight.checked }
                return insight
            })
        )
    }

    const handleInsightRead = async (newInsight) => {
        if (app.role === 'READER') return

        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await InsightsService.patch(token, accountId, [{ id: newInsight.id, read: true }])

            props.onUpdate(
                insights.map((insight: any) => {
                    if (insight.id == newInsight.id) return newInsight
                    return insight
                })
            )
            setLoading(false)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleInsightsRead = async () => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            if (app.role !== 'READER') {
                await InsightsService.patch(token, accountId, getCheckedInsights(true))
            }

            props.onUpdate(
                insights.map((insight: any) => {
                    if (insight.checked) return { ...insight, read: true, checked: false }
                    return insight
                })
            )
            setLoading(false)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleInsightsUnread = async () => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await InsightsService.patch(token, accountId, getCheckedInsights(false))

            props.onUpdate(
                insights.map((insight: any) => {
                    if (insight.checked) return { ...insight, read: false, checked: false }
                    return insight
                })
            )
            setLoading(false)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleExportCSV = () => {
        const nav: any = navigator

        if (nav.msSaveBlob) {
            const data = insights
                .map((insight: any) => {
                    return `${insight.description},${insight.report},${insight.labels.join(' ')}\n`
                })
                .join('')

            const exportedFilenmae = dayjs().format(DATE_FORMAT_API) + '.csv'
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
            nav.msSaveBlob(blob, exportedFilenmae)
        } else {
            const csvContent =
                'data:text/csv;charset=utf-8,' +
                insights
                    .map((insight: any) => {
                        return `${insight.description},${insight.report},${insight.labels.join(' ')}\n`
                    })
                    .join('')

            const content = encodeURI(csvContent)

            const downloadLink = document.createElement('a')
            downloadLink.href = content
            downloadLink.download = dayjs().format(DATE_FORMAT_API) + '.csv'

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }
    }

    useEffect(() => {
        setInsights(props.insights)
        setColor(INSIGHTS_REPORTS[props.report].color)
        setIcon(INSIGHTS_REPORTS[props.report].icon)
        setName(INSIGHTS_REPORTS[props.report].name)
    }, [props])

    const filteredInsights = insights
        .filter((insight: any) => (read ? true : insight.read == false))
        .filter((insight: any) => insight.description.toLowerCase().includes(props.filter.toLowerCase()))

    return (
        <>
            <View class="mb-900">
                <View row>
                    <View row flex="none" class="p-400 bdr-r-tl-500 bdr-r-tr-500" style={{ backgroundColor: color }}>
                        <Icon icon={icon} size={15} color={COLOR.COLOR_WHITE} />
                        <Text class="fw-600 pl-200 cl-white" small>
                            {name}
                        </Text>
                    </View>
                    <Text class="fw-600 pl-500 cl-gray-400" small>
                        You have {filteredInsights.length} {filteredInsights.length == 1 ? 'insight' : 'insights'}
                    </Text>
                    <Flexer />
                    <If
                        if={
                            filteredInsights.filter((insight: any) => insight.checked).length != 0 ||
                            app.role !== 'READER'
                        }
                    >
                        <Text class="fw-600 ml-900 buttonize" small onClick={handleInsightsUnread} style={{ color }}>
                            Mark as unread
                        </Text>
                        <Text class="fw-600 ml-900 buttonize" small onClick={handleInsightsRead} style={{ color }}>
                            Mark as read
                        </Text>
                    </If>
                    <Text class="fw-600 ml-900 buttonize hide" small onClick={handleExportCSV} style={{ color }}>
                        Export CSV
                    </Text>
                    <Text class="fw-600 ml-900 buttonize" small onClick={() => setRead(!read)} style={{ color }}>
                        {read ? 'Hide read' : 'Show read'}
                    </Text>
                </View>

                <Panel width="100%" innerClass="p-none">
                    {filteredInsights.map((insight: any, index1: number) => (
                        <Insight
                            color={color}
                            onClick={() => handleInsightClick(insight)}
                            id={insight.id}
                            type={insight.target.type}
                            name={insight.target.name}
                            read={insight.read}
                            key={index1}
                            checked={insight.checked}
                            onCheck={() => handleInsightCheck(index1)}
                            onRead={() => handleInsightRead({ ...insight, read: true })}
                            date={insight.date}
                            description={insight.description}
                            labels={insight.labels.map((label: string, index2: number) => (
                                <View key={index2} row width="fit-content" class="pr-100">
                                    {(label !== '' || label !== null) && <Label>{label ?? 'N/A'}</Label>}
                                </View>
                            ))}
                        />
                    ))}

                    <If if={filteredInsights.length == 0}>
                        <Text class="p-900">There are no insights</Text>
                    </If>
                </Panel>
            </View>
        </>
    )
}

interface IInsightsComponent {
    children?: any
}

export const InsightsComponent: FunctionComponent = (props: IInsightsComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const [insights, setInsights] = useState([])
    const days = 10
    const [start, setStart] = useState(dayjs())
    const [end, setEnd] = useState(dayjs().add(days, 'days'))
    const max = dayjs().add(days, 'days')
    const [text, setText] = useState('')
    const [grouped, setGrouped] = useState(false)
    const [read, setRead] = useState(true)
    const [types, setTypes] = useState({})

    const handleOptionSelect = (index: number) => {
        if (index == 0) setGrouped(false)
        if (index == 1) setGrouped(true)
    }

    const fetchData = async () => {
        setLoading(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await InsightsService.get(token, accountId, {
                project_id: projectId,
            })
            let types = {}

            setLoading(false)
            setInsights(
                result.map((insight: any) => {
                    types[insight.target.type] = true

                    return {
                        id: insight.id,
                        checked: false,
                        read: insight.read,
                        description: insight.description,
                        date: dayjs(insight.date),
                        target: insight.target,
                        labels: insight.labels,
                    }
                })
            )
            setTypes(types)
        } catch (e) {
            logError(e)
            setLoading(false)
            setError(e.message)
        }
    }

    const handleInsightUpdates = (newInsights) => {
        setInsights(
            insights.map((insight1: any) => {
                const newInsight = newInsights.find((insight2) => insight1.id == insight2.id)

                if (newInsight) {
                    return newInsight
                } else {
                    return insight1
                }
            })
        )
    }

    useEffect(() => {
        if (!projectId || projectId == 'null' || projectId == null) return
        if (app.selectedProjectId !== projectId) {
            setInsights([])
            return
        }
        fetchData()
    }, [accountId, projectId])

    return (
        <>
            <LoaderComponent loading={loading} />
            <NotificationComponent notification={notification} error={error} />
            <View row justify="flex-end" class="mb-500">
                <Heading large class="mr-900">
                    Insights
                </Heading>
                <Flexer />
                <View width="50%">
                    <Input
                        clear
                        type="text"
                        placeholder="Filter insights"
                        value={text}
                        onChange={(value) => setText(value)}
                        onBlur={(value) => setText(value)}
                        class="bg-cl-gray-300"
                    />
                </View>
                <Options onOptionSelected={handleOptionSelect} class="ml-300">
                    <Option selected>All</Option>
                    <Option>Report</Option>
                </Options>
            </View>

            <If if={grouped}>
                <If if={types[INSIGHTS.TYPES.PERFORMANCE]}>
                    <InsightsGroupComponent
                        onUpdate={handleInsightUpdates}
                        grouped={grouped}
                        filter={text}
                        insights={insights.filter((insight: any) => insight.target.type == INSIGHTS.TYPES.PERFORMANCE)}
                        report={INSIGHTS.TYPES.PERFORMANCE}
                    />
                </If>

                <If if={types[INSIGHTS.TYPES.CUSTOMER_JOURNEY]}>
                    <InsightsGroupComponent
                        onUpdate={handleInsightUpdates}
                        grouped={grouped}
                        filter={text}
                        insights={insights.filter(
                            (insight: any) => insight.target.type == INSIGHTS.TYPES.CUSTOMER_JOURNEY
                        )}
                        report={INSIGHTS.TYPES.CUSTOMER_JOURNEY}
                    />
                </If>

                <If if={types[INSIGHTS.TYPES.TV]}>
                    <InsightsGroupComponent
                        onUpdate={handleInsightUpdates}
                        grouped={grouped}
                        filter={text}
                        insights={insights.filter((insight: any) => insight.target.type == INSIGHTS.TYPES.TV)}
                        report={INSIGHTS.TYPES.TV}
                    />
                </If>

                <If if={types[INSIGHTS.TYPES.TRACKING]}>
                    <InsightsGroupComponent
                        onUpdate={handleInsightUpdates}
                        grouped={grouped}
                        filter={text}
                        insights={insights.filter((insight: any) => insight.target.type == INSIGHTS.TYPES.TRACKING)}
                        report={INSIGHTS.TYPES.TRACKING}
                    />
                </If>
            </If>

            <If if={!grouped}>
                <InsightsGroupComponent
                    onUpdate={handleInsightUpdates}
                    grouped={grouped}
                    filter={text}
                    insights={insights}
                    report="none"
                />
            </If>
        </>
    )
}
