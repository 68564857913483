import React, { useEffect, useState, useContext, FunctionComponent, ReactElement } from 'react'
import { If, Notification, NotificationText, View } from '@adtriba/ui'

interface INoReportComponent {
    visible?: boolean
    children?: any
}

export const NoReportComponent = (props: INoReportComponent): ReactElement => {
    return (
        <>
            <If if={props.visible}>
                <View
                    width="50%"
                    class="ml-auto mr-auto p-relative"
                    style={{ top: 50, zIndex: 10, pointerEvents: 'none' }}
                >
                    <Notification info>
                        <NotificationText>There is no data for this report</NotificationText>
                    </Notification>
                </View>
            </If>
        </>
    )
}
