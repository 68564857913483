import {
    Button,
    ButtonIcon,
    ButtonText,
    Flexer,
    Heading,
    If,
    Label,
    LineChart,
    Panel,
    Select,
    SelectOption,
    Sparkline,
    Table,
    View,
} from '@adtriba/ui'
import { ILineChartLine } from '@adtriba/ui/lib/line-chart/line-chart'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs from 'dayjs'
import numeral from 'numeral'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NoReportComponent } from '../../../../components/no-report/no-report.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    COLOR,
    DATE_FORMAT_API,
    DATE_FORMAT_METRIC,
    DEFAULT_TABLE,
    ERROR,
    INSIGHTS,
    NUMERAL,
    TABLE,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { sanitizeLocale, setLocale } from '../../../../helpers/util'
import { TrackingService } from '../../services/tracking.service'
import { QuickInsightsComponent } from '../insights/insights.component'
import './tracking.component.sass'

interface ITrackingComponent {
    children?: any
}

export const TrackingComponent: FunctionComponent = (props: ITrackingComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorNoReport, setErrorNoReport] = useState(null)
    const [notification, setNotification] = useState(null)
    const { accountId, projectId } = useParams()
    const [conversionTableData, setConversionTableData] = useState<any>(DEFAULT_TABLE)
    const [conversionsLinechartData, setConversionsLinechartData] = useState<ILineChartLine[]>([])
    const [pageviewsLinechartData, setPageviewsLinechartData] = useState<ILineChartLine[]>([])
    const [conversionType, setConversionType] = useState(-1)
    const [conversionTypes, setConversionTypes] = useState([])
    const [publishers, setPublishers] = useState([])
    const [publisher, setPublisher] = useState(-1)
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState(-1)
    const [impressionTracking, setImpressionTracking] = useState<any>({ trend: [], daily: [] })
    const [microConversion, setMicroConversion] = useState<any>({ trend: [] })
    const [insightsModal, setInsightsModal] = useState(false)
    const [loadingSelectData, setLoadingSelectData] = useState(null)
    const [loadingConversionsTable, setLoadingConversionsTable] = useState(null)
    const [loadingConversionsTimeline, setLoadingConversionsTimeline] = useState(null)
    const [loadingImpressionsTimeline, setLoadingImpressionsTimeline] = useState(null)
    const [loadingPageviewsAndTable, setLoadingPageviewsAndTable] = useState(null)
    const [loadingMicroConversionCountTimeline, setLoadingMicroConversionCountTimeline] = useState(null)

    const handleError = (e) => {
        if (e == 204 || e == 400) {
            setLoadingSelectData(false)
            setLoadingConversionsTimeline(false)
            setLoadingImpressionsTimeline(false)
            setLoadingPageviewsAndTable(false)
            setLoadingConversionsTable(false)
            setLoadingMicroConversionCountTimeline(false)
            setNotification(null)
            setError(null)
            setErrorNoReport(ERROR.API.NO_REPORT_DATA)
        } else {
            setLoadingSelectData(false)
            setLoadingConversionsTimeline(false)
            setLoadingImpressionsTimeline(false)
            setLoadingPageviewsAndTable(false)
            setLoadingConversionsTable(false)
            setLoadingMicroConversionCountTimeline(false)
            setNotification(null)
            setError(ERROR.API.GENERAL)
            setErrorNoReport(null)
        }
    }

    const formatNumbers = (data: any) => [data.date, Number(data.value)]

    const fetchSelectData = async () => {
        setLoadingSelectData(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultMicroConversionCountEvents = await TrackingService.getMicroConversionCountEvents(
                token,
                accountId,
                projectId
            )
            const resultConversionsTypes = await TrackingService.getConversionsTypes(token, accountId, projectId)
            const resultImpressionsPublisher = await TrackingService.getImpressionsPublisher(
                token,
                accountId,
                projectId
            )

            setEvents(resultMicroConversionCountEvents)
            setConversionTypes(resultConversionsTypes)
            setPublishers(resultImpressionsPublisher)
            setLoadingSelectData(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchConversionsTimeline = async () => {
        setLoadingConversionsTimeline(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const conversionTypeHash = conversionTypes[conversionType] ? conversionTypes[conversionType].hash : null
            const result = await TrackingService.getConversionsTimeline(token, accountId, projectId, {
                date_end: dateEnd,
                date_start: dateStart,
                conversion_type_hash: conversionTypeHash,
            })

            setConversionsLinechartData([
                {
                    legend: 'Conversions',
                    color: COLOR.COLOR_ELECTRIC,
                    points: result.map((data: any) => [data.date, data.value]),
                },
            ])
            setLoadingConversionsTimeline(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchConversionsTable = async () => {
        setLoadingConversionsTable(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const resultConversionsTable = await TrackingService.getConversionsTable(token, accountId, projectId, {
                date_end: dateEnd,
                date_start: dateStart,
            })

            setConversionTableData([
                {
                    lastDataSet: true,
                    heading: 'Conversions',
                    headings: ['Conversion Date', 'Conversion ID', 'Revenue'],
                    align: [null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                    formats: [
                        (val) => dayjs(val).format(DATE_FORMAT_METRIC),
                        null,
                        (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    ],
                    data: resultConversionsTable.map((data: any, index: number) => [
                        data.date,
                        data.conversion_id,
                        data.revenue,
                    ]),
                },
            ])

            setLoadingConversionsTable(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchMicroConversionCountTimeline = async () => {
        setLoadingMicroConversionCountTimeline(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const eventHash = events[event] ? events[event].hash : null
            const impressionsCount = await TrackingService.getMicroConversionCountTimeline(
                token,
                accountId,
                projectId,
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    event_hash: eventHash,
                }
            )
            const months = impressionsCount.map((ic: any) => ic.month)
            const month = months.length > 1 ? `${months[0]} - ${months[months.length - 1]}` : months[0]
            const count = impressionsCount.reduce((acc, val) => acc + val.count, 0)
            const trend = impressionsCount
                .reduce((acc, val) => [...acc, ...val.trend], [])
                .sort((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix())

            setLoadingMicroConversionCountTimeline(false)
            setMicroConversion({
                count: numeral(count).format(NUMERAL.FORMATS.NUMERIC),
                month,
                trend: trend.map(formatNumbers),
            })
        } catch (e) {
            handleError(e)
        }
    }

    const fetchImpressionsTimeline = async () => {
        setLoadingImpressionsTimeline(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const publisherHash = publishers[publisher] ? publishers[publisher].hash : null
            const result = await TrackingService.getImpressionsTimeline(token, accountId, projectId, {
                date_end: dateEnd,
                date_start: dateStart,
                publisher_hash: publisherHash,
            })

            const { average, impressions_count, impressions_per_day } = result[0]
            const months = impressions_count.map((ic: any) => ic.month)
            const month = months.length > 1 ? `${months[0]} - ${months[months.length - 1]}` : months[0]
            const count = impressions_count.reduce((acc, val) => acc + val.count, 0)
            const trend = impressions_count
                .reduce((acc, val) => [...acc, ...val.trend], [])
                .sort((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix())

            setLoadingImpressionsTimeline(false)
            setImpressionTracking({
                count: numeral(count).format(NUMERAL.FORMATS.NUMERIC),
                month,
                average: numeral(average).format(NUMERAL.FORMATS.NUMERIC),
                trend: trend.map(formatNumbers),
                daily: impressions_per_day.map(formatNumbers),
            })
        } catch (e) {
            handleError(e)
        }
    }

    const fetchPageviewsAndTable = async () => {
        setLoadingPageviewsAndTable(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const resultPageviewsTimeline = await TrackingService.getPageviewsTimeline(token, accountId, projectId, {
                date_end: dateEnd,
                date_start: dateStart,
            })

            setPageviewsLinechartData([
                {
                    legend: 'Page views',
                    color: COLOR.COLOR_ELECTRIC,
                    points: resultPageviewsTimeline.map((data: any) => [data.date, data.value]),
                },
            ])
            setLoadingPageviewsAndTable(false)
        } catch (e) {
            handleError(e)
        }
    }

    const handleConversionsTableDownload = async () => {
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const result = await TrackingService.getConversionsTableDownload(token, accountId, projectId, {
                date_end: dateEnd,
                date_start: dateStart,
            })
            const blob = await result.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.href = url
            a.download = `conversions_${dateStart}_${dateEnd}.csv`
            document.body.appendChild(a)

            a.click()
            a.remove()
        } catch (e) {
            handleError(e)
        }
    }

    useEffect(() => {
        fetchSelectData()
    }, [app.fromDate, app.toDate, accountId, projectId])

    useEffect(() => {
        fetchPageviewsAndTable()
        fetchConversionsTable()
    }, [app.fromDate, app.toDate, accountId, projectId])

    useEffect(() => {
        fetchImpressionsTimeline()
    }, [app.fromDate, app.toDate, publishers, publisher, accountId, projectId])

    useEffect(() => {
        fetchMicroConversionCountTimeline()
    }, [app.fromDate, app.toDate, events, event, accountId, projectId])

    useEffect(() => {
        fetchConversionsTimeline()
    }, [app.fromDate, app.toDate, conversionType, accountId, projectId])

    useEffect(() => {
        const currency = app.selectedAccount.find((project: any) => project.id == projectId)?.currency ?? 'EUR'
        setLocale(sanitizeLocale(currency))
    }, [app])

    return (
        <>
            <If if={insightsModal}>
                <QuickInsightsComponent
                    accountId={accountId}
                    projectId={projectId}
                    onDismiss={() => setInsightsModal(false)}
                    type={INSIGHTS.TYPES.TRACKING}
                />
            </If>

            <NotificationComponent notification={notification} error={error} />
            <NoReportComponent visible={errorNoReport} />

            <View class={!!errorNoReport ? 'blur' : null}>
                <View row justify="flex-start">
                    <Heading large class="mr-900">
                        Tracking report
                    </Heading>
                    <Flexer />
                    <Button circle onClick={() => setInsightsModal(true)}>
                        <ButtonIcon icon="zap" />
                    </Button>
                </View>

                <Panel width="100%" class="mt-500 mb-500" loading={loadingConversionsTable}>
                    <Table
                        header={
                            <Button onClick={handleConversionsTableDownload} small class="mr-900">
                                <ButtonText>Download as CSV</ButtonText>
                            </Button>
                        }
                        data={conversionTableData}
                        onCleanup={(data) => setConversionTableData(data)}
                        onLoad={(dataIndex: number, row: any) => {
                            // Fetch some new data via API
                            // And add that data to the history
                            // setData([...data, defaultTableData('Second one', 'Second Wine', 'Second Water', true)])
                        }}
                    />
                </Panel>

                <Panel width="100%" class="mb-500" loading={loadingConversionsTimeline}>
                    <LineChart
                        title="Conversions by day"
                        header={
                            <View>
                                <Select
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    width={300}
                                    placeholder="Select an option"
                                    selected={conversionType}
                                    onSelect={(index: number) => setConversionType(index)}
                                >
                                    {conversionTypes.map((conversionType: any, index: number) => (
                                        <SelectOption key={index}>{conversionType.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        }
                        data={conversionsLinechartData}
                    />
                </Panel>

                <Panel width="100%" class="mb-500" loading={loadingPageviewsAndTable}>
                    <LineChart title="Pageviews by day" data={pageviewsLinechartData} />
                </Panel>

                <View row class="mb-500">
                    <Panel
                        class="flex-1 mr-500"
                        style={{ height: 200, alignItems: 'flex-start' }}
                        loading={loadingImpressionsTimeline}
                    >
                        <View row width="100%" class="mb-500">
                            <Heading>Impression Tracking</Heading>
                            <Flexer />
                            <View>
                                <Select
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    width={200}
                                    placeholder="Publisher"
                                    selected={publisher}
                                    onSelect={(index: number) => setPublisher(index)}
                                >
                                    {publishers.map((publisher: any, index: number) => (
                                        <SelectOption key={index}>{publisher.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>
                        <View column justify="flex-start">
                            <View row width="100%">
                                <View column align="flex-start" width="100%">
                                    <Label>{impressionTracking.month}</Label>
                                    <Heading large>{impressionTracking.count}</Heading>
                                </View>
                                <View flex={2.25}>
                                    <If if={impressionTracking.trend.length != 0}>
                                        <Sparkline
                                            color={COLOR.COLOR_ELECTRIC}
                                            data={impressionTracking.trend}
                                            height={75}
                                            square
                                            solid
                                        />
                                    </If>
                                </View>
                            </View>
                            <View row width="100%" class="mt-100">
                                <View width={100}>
                                    <Label electric>Daily: {impressionTracking.average}</Label>
                                </View>
                                <View row flex={1} justify="flex-end">
                                    <View width="100%">
                                        <If if={impressionTracking.daily.length != 0}>
                                            <Sparkline
                                                color={COLOR.COLOR_GRAY_300}
                                                data={impressionTracking.daily}
                                                height={40}
                                                clean
                                                square
                                                hideBars
                                            />
                                        </If>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Panel>

                    <Panel
                        class="flex-1"
                        style={{ height: 200, alignItems: 'flex-start' }}
                        loading={loadingMicroConversionCountTimeline}
                    >
                        <View row width="100%" class="mb-500">
                            <Heading>Micro Conversion Count</Heading>
                            <Flexer />
                            <View>
                                <Select
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    width={200}
                                    placeholder="Event"
                                    selected={event}
                                    onSelect={(index: number) => setEvent(index)}
                                >
                                    {events.map((event: any, index: number) => (
                                        <SelectOption key={index}>{event.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>
                        <View row justify="flex-start">
                            <View column align="flex-start">
                                <Label>{microConversion.month}</Label>
                                <Heading large>{microConversion.count}</Heading>
                            </View>
                            <View flex={2.25}>
                                <If if={microConversion.trend.length != 0}>
                                    <Sparkline
                                        color={COLOR.COLOR_ELECTRIC}
                                        data={microConversion.trend}
                                        height={75}
                                        square
                                        solid
                                    />
                                </If>
                            </View>
                        </View>
                    </Panel>
                </View>
            </View>
        </>
    )
}
