import {
    BarChart,
    Button,
    ButtonIcon,
    Filter,
    Flexer,
    Heading,
    If,
    Metric,
    Panel,
    Select,
    SelectOption,
    Table,
    Text,
    Toggle,
    View,
} from '@adtriba/ui'
import { IBarChartGroup } from '@adtriba/ui/lib/bar-chart/bar-chart'
import { ILineChartLine, LineChartDual } from '@adtriba/ui/lib/line-chart/line-chart'
import { useAuth0 } from '@auth0/auth0-react'
import numeral from 'numeral'
import queryString from 'query-string'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { NoReportComponent } from '../../../../components/no-report/no-report.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    COLOR,
    DEFAULT_TABLE,
    ERROR,
    FILTER,
    INSIGHTS,
    NUMERAL,
    STORAGE,
    TABLE,
    TV_LEVELS,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import {
    filterValidLabelHash,
    filterValidLabelValue,
    findIndexHash,
    formatDateForAPI,
    getCacheKey,
    getCachedFilterRules,
    getFilterOption,
    getFilterOptionHash,
    logError,
    sanitizeLocale,
    setLocale,
    validateFilterRows,
} from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { FilterOption, TvService } from '../../services/tv-v2.service'
import { QuickInsightsComponent } from '../insights/insights.component'
import './tv.component.sass'

interface ITvComponent {
    children?: any
}

export const TvComponent: FunctionComponent = (props: ITvComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorNoReport, setErrorNoReport] = useState(null)
    const [notification, setNotification] = useState(null)
    const { accountId, projectId, reportId } = useParams()
    const { search } = useLocation()
    const {
        station_hash,
        timeslot_hash,
        genre_after_hash,
        genre_before_hash,
        program_after_hash,
        program_before_hash,
        flight_hash,
        duration_hash,
    } = queryString.parse(search)
    const [metrics, setMetrics] = useState<any>({})
    const [performanceTableData, setPerformanceTableData] = useState<any>(DEFAULT_TABLE)
    const [airingTableData, setAiringTableData] = useState<any>(DEFAULT_TABLE)
    const [linechartData, setLinechartData] = useState<ILineChartLine[]>([])
    const [stationBarchartCPA, setStationBarchartCPA] = useState<IBarChartGroup[]>([])
    const [stationBarchartCPV, setStationBarchartCPV] = useState<IBarChartGroup[]>([])
    const [flightBarchartCPA, setFlightBarchartCPA] = useState<IBarChartGroup[]>([])
    const [flightBarchartCPV, setFlightBarchartCPV] = useState<IBarChartGroup[]>([])
    const [timeslotBarchartCPA, setTimeslotBarchartCPA] = useState<IBarChartGroup[]>([])
    const [timeslotBarchartCPV, setTimeslotBarchartCPV] = useState<IBarChartGroup[]>([])
    const [level, setLevel] = useState(TV_LEVELS.STATION)
    const [performanceData, setPerformanceData] = useState<any>(null)
    const [stationLevel, setStationLevel] = useState<any>(null)
    const [flightLevel, setFlightLevel] = useState<any>(null)
    const [insightsModal, setInsightsModal] = useState(false)
    const [loadingFilters, setLoadingFilters] = useState(null)
    const [loadingAiringCombinationPerformanceTable, setLoadingAiringCombinationPerformanceTable] = useState(null)
    const [loadingPerformanceTable, setLoadingPerformanceTable] = useState(null)
    const [loadingTotals, setLoadingTotals] = useState(null)
    const [loadingStationPerformance, setLoadingStationPerformance] = useState(null)
    const [loadingFlightPerformance, setLoadingFlightPerformance] = useState(null)
    const [loadingTimeslotPerformance, setLoadingTimeslotPerformance] = useState(null)
    const [loadingAiringCombinationPerformanceTimeline, setLoadingAiringCombinationPerformanceTimeline] = useState(null)
    const [advanced, setAdvanced] = useState(false)
    const defaultFilterRules = [{ option: 0, comparison: FILTER.COMPARISONS[0], value: '', logic: '' }]
    const [filter, setFilter] = useState(defaultFilterRules)
    const [filterStations, setFilterStations] = useState([])
    const [filterTimeslots, setFilterTimeslots] = useState([])
    const [filterGenresAfter, setFilterGenresAfter] = useState([])
    const [filterGenresBefore, setFilterGenresBefore] = useState([])
    const [filterProgramsAfter, setFilterProgramsAfter] = useState([])
    const [filterProgramsBefore, setFilterProgramsBefore] = useState([])
    const [filterFlights, setFilterFlights] = useState([])
    const [filterDurations, setFilterDurations] = useState([])
    const [stationsSelected, setStationsSelected] = useState([])
    const [timeslotsSelected, setTimeslotsSelected] = useState([])
    const [genresAfterSelected, setGenresAfterSelected] = useState([])
    const [genresBeforeSelected, setGenresBeforeSelected] = useState([])
    const [programsAfterSelected, setProgramsAfterSelected] = useState([])
    const [programsBeforeSelected, setProgramsBeforeSelected] = useState([])
    const [flightsSelected, setFlightsSelected] = useState([])
    const [durationsSelected, setDurationsSelected] = useState([])
    const [stationsSelectedCache, setStationsSelectedCache] = useState([])
    const [timeslotsSelectedCache, setTimeslotsSelectedCache] = useState([])
    const [genresAfterSelectedCache, setGenresAfterSelectedCache] = useState([])
    const [genresBeforeSelectedCache, setGenresBeforeSelectedCache] = useState([])
    const [programsAfterSelectedCache, setProgramsAfterSelectedCache] = useState([])
    const [programsBeforeSelectedCache, setProgramsBeforeSelectedCache] = useState([])
    const [flightsSelectedCache, setFlightsSelectedCache] = useState([])
    const [durationsSelectedCache, setDurationsSelectedCache] = useState([])
    const filterOptions = [
        { label: 'Station', value: 'station' },
        { label: 'Timeslot', value: 'timeslot' },
        { label: 'Genre After', value: 'genre_after' },
        { label: 'Genre Before', value: 'genre_before' },
        { label: 'Program After', value: 'program_after' },
        { label: 'Program before', value: 'program_before' },
        { label: 'Flight', value: 'flight' },
        { label: 'Duration', value: 'duration' },
    ]

    const handleToggleAdvanced = () => {
        const defaultFilterGroup = [{ logic: '', rows: [] }]
        if (advanced) {
            if (filter.length == 1) {
                if (filter[0].value != '') handleFilterChange(defaultFilterGroup)
            } else if (filter.length > 1) {
                handleFilterChange(defaultFilterGroup)
            }
        }
        setAdvanced(!advanced)
    }

    const resetSelectFilters = () => {
        // Don't reset anything here if it's on the initial state
        // Only causes a unnecessary render
        if (
            stationsSelected.length == 0 &&
            timeslotsSelected.length == 0 &&
            genresBeforeSelected.length == 0 &&
            genresAfterSelected.length == 0 &&
            programsAfterSelected.length == 0 &&
            programsBeforeSelected.length == 0 &&
            flightsSelected.length == 0 &&
            durationsSelected.length == 0 &&
            stationsSelectedCache.length == 0 &&
            timeslotsSelectedCache.length == 0 &&
            genresBeforeSelectedCache.length == 0 &&
            genresAfterSelectedCache.length == 0 &&
            programsAfterSelectedCache.length == 0 &&
            programsBeforeSelectedCache.length == 0 &&
            flightsSelectedCache.length == 0 &&
            durationsSelectedCache.length == 0
        )
            return

        setStationsSelected([])
        setTimeslotsSelected([])
        setGenresBeforeSelected([])
        setGenresAfterSelected([])
        setProgramsAfterSelected([])
        setProgramsBeforeSelected([])
        setFlightsSelected([])
        setDurationsSelected([])
        setStationsSelectedCache([])
        setTimeslotsSelectedCache([])
        setGenresBeforeSelectedCache([])
        setGenresAfterSelectedCache([])
        setProgramsAfterSelectedCache([])
        setProgramsBeforeSelectedCache([])
        setFlightsSelectedCache([])
        setDurationsSelectedCache([])
    }

    const resetFilters = () => {
        resetSelectFilters()
        setFilter(defaultFilterRules)
    }

    const handleFilterUpdate = () => {
        setStationsSelected(stationsSelectedCache)
        setTimeslotsSelected(timeslotsSelectedCache)
        setGenresBeforeSelected(genresBeforeSelectedCache)
        setGenresAfterSelected(genresAfterSelectedCache)
        setProgramsAfterSelected(programsAfterSelectedCache)
        setProgramsBeforeSelected(programsBeforeSelectedCache)
        setFlightsSelected(flightsSelectedCache)
        setDurationsSelected(durationsSelectedCache)
    }

    const handleFilterChange = (groups: any) => {
        const rules = groups[0].rows

        if (rules.length == 0) {
            StorageService.deleteStorage(getCacheKey(reportId, STORAGE.TV))
            resetSelectFilters()
            setFilter([])
        } else {
            if (validateFilterRows(rules)) {
                StorageService.setStorage(getCacheKey(reportId, STORAGE.TV), JSON.stringify(rules))
                resetSelectFilters()
                setFilter(rules)
            }
        }
    }

    const getFilters = (): FilterOption[] => {
        let options: FilterOption[] = []
        const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.TV)

        // Always used cache values as source of truth
        if (cachedFilterRules) {
            if (cachedFilterRules.length > 0) {
                options = cachedFilterRules.map((filterRow: any) => ({
                    field: filterOptions[filterRow.option].value,
                    operator: filterRow.comparison,
                    value: filterRow.value,
                }))
            }
        } else {
            const stations = getFilterOptionHash(stationsSelected, filterStations)
            const timeslots = getFilterOptionHash(timeslotsSelected, filterTimeslots)
            const genresBefore = getFilterOptionHash(genresBeforeSelected, filterGenresBefore)
            const genresAfter = getFilterOptionHash(genresAfterSelected, filterGenresAfter)
            const programsBefore = getFilterOptionHash(programsBeforeSelected, filterProgramsBefore)
            const programsAfter = getFilterOptionHash(programsAfterSelected, filterProgramsAfter)
            const flights = getFilterOptionHash(flightsSelected, filterFlights)
            const durations = getFilterOptionHash(durationsSelected, filterDurations)

            if (stations != '') options.push(getFilterOption('station', stations))
            if (timeslots != '') options.push(getFilterOption('timeslot', timeslots))
            if (genresBefore != '') options.push(getFilterOption('genre_before', genresBefore))
            if (genresAfter != '') options.push(getFilterOption('genre_after', genresAfter))
            if (programsBefore != '') options.push(getFilterOption('program_before', programsBefore))
            if (programsAfter != '') options.push(getFilterOption('program_after', programsAfter))
            if (flights != '') options.push(getFilterOption('flight', flights))
            if (durations != '') options.push(getFilterOption('duration', durations))
        }

        return options
    }

    const handleError = (e) => {
        if (e == 204 || e == 400) {
            setLoadingFilters(false)
            setLoadingAiringCombinationPerformanceTable(false)
            setLoadingPerformanceTable(false)
            setLoadingTotals(false)
            setLoadingStationPerformance(false)
            setLoadingFlightPerformance(false)
            setLoadingTimeslotPerformance(false)
            setLoadingAiringCombinationPerformanceTimeline(false)
            setNotification(null)
            setError(null)
            setErrorNoReport(ERROR.API.NO_REPORT_DATA)
        } else {
            setLoadingFilters(false)
            setLoadingAiringCombinationPerformanceTable(false)
            setLoadingPerformanceTable(false)
            setLoadingTotals(false)
            setLoadingStationPerformance(false)
            setLoadingFlightPerformance(false)
            setLoadingTimeslotPerformance(false)
            setLoadingAiringCombinationPerformanceTimeline(false)
            setNotification(null)
            setError(ERROR.API.GENERAL)
            setErrorNoReport(null)
        }
    }

    const fetchFilters = async () => {
        setLoadingFilters(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        resetFilters()

        try {
            const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.TV)
            const token = await getAccessTokenSilently()
            const resultFlight = await TvService.getFilter(token, accountId, projectId, reportId, 'flight')
            const resultGenreAfter = await TvService.getFilter(token, accountId, projectId, reportId, 'genre_after')
            const resultGenreBefore = await TvService.getFilter(token, accountId, projectId, reportId, 'genre_before')
            const resultProgramAfter = await TvService.getFilter(token, accountId, projectId, reportId, 'program_after')
            const resultProgramBefore = await TvService.getFilter(
                token,
                accountId,
                projectId,
                reportId,
                'program_before'
            )
            const resultDuration = await TvService.getFilter(token, accountId, projectId, reportId, 'duration')
            const resultStation = await TvService.getFilter(token, accountId, projectId, reportId, 'station')
            const resultTimeslot = await TvService.getFilter(token, accountId, projectId, reportId, 'timeslot')

            if (cachedFilterRules) {
                setFilter(cachedFilterRules)
                setAdvanced(true)
            }

            setFilterFlights(resultFlight.filter(filterValidLabelHash))
            setFilterGenresAfter(resultGenreAfter.filter(filterValidLabelHash))
            setFilterGenresBefore(resultGenreBefore.filter(filterValidLabelHash))
            setFilterProgramsAfter(resultProgramAfter.filter(filterValidLabelHash))
            setFilterProgramsBefore(resultProgramBefore.filter(filterValidLabelHash))
            setFilterDurations(resultDuration.filter(filterValidLabelHash))
            setFilterStations(resultStation.filter(filterValidLabelHash))
            setFilterTimeslots(resultTimeslot.filter(filterValidLabelHash))

            setLoadingFilters(false)
        } catch (e) {
            logError('fetchFilters', e)
            handleError(e)
        }
    }

    const fetchAiringCombinationPerformanceTable = async () => {
        setLoadingAiringCombinationPerformanceTable(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultAiringCombinationPerformanceTable = await TvService.getAiringCombinationPerformanceTable(
                token,
                accountId,
                projectId,
                reportId,
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setAiringTableData([
                {
                    lastDataSet: true,
                    heading: 'Airing Combination Performance',
                    headings: [
                        'Stations',
                        'Flights',
                        'Timeslots',
                        'Durations',
                        'Visits',
                        'Spend',
                        'Conversions',
                        'CPV',
                        'CPA',
                    ],
                    align: [
                        null,
                        null,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                        TABLE.ALIGN.RIGHT,
                    ],
                    formats: [
                        null,
                        null,
                        null,
                        (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                        (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                        (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                        (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                        (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                        (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    ],
                    data: resultAiringCombinationPerformanceTable.map((data: any, index: number) => [
                        data.station,
                        data.flight,
                        data.timeslot == 'unknown' ? 'N/A' : data.timeslot,
                        data.spot_duration,
                        data.visits,
                        data.spend,
                        data.conversions,
                        data.cost_per_visit,
                        data.cost_per_action,
                    ]),
                },
            ])
            setLoadingAiringCombinationPerformanceTable(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchPerformanceTable = async () => {
        setLoadingPerformanceTable(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultPerformanceTable = await TvService.getPerformanceTable(
                token,
                accountId,
                projectId,
                reportId,
                level,
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                    station_level_hash: !!stationLevel ? stationLevel.hash : null,
                    flight_level_hash: !!flightLevel ? flightLevel.hash : null,
                },
                getFilters()
            )
            const getHeading = (level: string) => {
                switch (level) {
                    case TV_LEVELS.STATION:
                        return 'TV performance'
                    case TV_LEVELS.FLIGHT:
                        return stationLevel.label
                    case TV_LEVELS.TIMESLOT:
                        return flightLevel.label
                    default:
                        return ''
                }
            }
            const getTableHeading = (level: string) => {
                switch (level) {
                    case TV_LEVELS.STATION:
                        return 'Station'
                    case TV_LEVELS.FLIGHT:
                        return 'Flight'
                    case TV_LEVELS.TIMESLOT:
                        return 'Timeslot'
                    default:
                        return ''
                }
            }
            const tableData = {
                lastDataSet: level == TV_LEVELS.TIMESLOT,
                heading: getHeading(level),
                headings: [getTableHeading(level), 'Spend', 'Visits', 'CPV', ''],
                align: [null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                formats: [
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    TABLE.HIDDEN,
                ],
                data: resultPerformanceTable.map((data: any, index: number) => [
                    data.label,
                    data.spend,
                    data.visits,
                    data.cost_per_visit,
                    data.hash,
                ]),
            }

            // Because the table is connected to everything else in the UI
            // We can't manage the data on a table-level
            // So here we organise the table's data pages to have the right orders
            switch (level) {
                case TV_LEVELS.STATION:
                    setPerformanceTableData([tableData])
                    break
                case TV_LEVELS.FLIGHT:
                    setPerformanceTableData([performanceTableData[0], tableData])
                    break
                case TV_LEVELS.TIMESLOT:
                    setPerformanceTableData([performanceTableData[0], performanceTableData[1], tableData])
                    break
            }

            // Now set the data here because it's what we use to get the hash
            // So always keep this updated
            setPerformanceData([...resultPerformanceTable])
            setLoadingPerformanceTable(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchTotals = async () => {
        setLoadingTotals(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultTotals = await TvService.getTotals(
                token,
                accountId,
                projectId,
                reportId,
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )
            const {
                conversions,
                cost_per_action,
                cost_per_visit,
                revenue,
                spend,
                spots_aired,
                station_count,
                visits,
            } = resultTotals

            setMetrics({
                costPerVisit: cost_per_visit,
                costPerAction: cost_per_action,
                flights: resultTotals.flights,
                stationCount: station_count,
                conversions,
                spotsAired: spots_aired,
                spend,
                visits,
                revenue,
            })
            setLoadingTotals(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchStationPerformance = async () => {
        setLoadingStationPerformance(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultStationPerformanceCPA = await TvService.getStationPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpa',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setStationBarchartCPA([
                {
                    legend: 'CPA',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultStationPerformanceCPA.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPA',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])

            const resultStationPerformanceCPV = await TvService.getStationPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpv',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setStationBarchartCPV([
                {
                    legend: 'CPV',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultStationPerformanceCPV.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPV',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])
            setLoadingStationPerformance(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchFlightPerformance = async () => {
        setLoadingFlightPerformance(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultFlightPerformanceCPA = await TvService.getFlightPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpa',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setFlightBarchartCPA([
                {
                    legend: 'CPA',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultFlightPerformanceCPA.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPA',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])

            const resultFlightPerformanceCPV = await TvService.getFlightPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpv',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setFlightBarchartCPV([
                {
                    legend: 'CPV',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultFlightPerformanceCPV.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPV',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])
            setLoadingFlightPerformance(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchTimeslotPerformance = async () => {
        setLoadingTimeslotPerformance(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultTimeslotPerformanceCPA = await TvService.getTimeslotPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpa',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setTimeslotBarchartCPA([
                {
                    legend: 'CPA',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultTimeslotPerformanceCPA.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPA',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])

            const resultTimeslotPerformanceCPV = await TvService.getTimeslotPerformance(
                token,
                accountId,
                projectId,
                reportId,
                'cpv',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setTimeslotBarchartCPV([
                {
                    legend: 'CPV',
                    color: COLOR.COLOR_ELECTRIC,
                    values: resultTimeslotPerformanceCPV.filter(filterValidLabelValue).map((data: any) => ({
                        metric: 'CPV',
                        count: data.value,
                        label: data.label,
                    })),
                },
            ])
            setLoadingTimeslotPerformance(false)
        } catch (e) {
            handleError(e)
        }
    }

    const fetchAiringCombinationPerformanceTimeline = async () => {
        setLoadingAiringCombinationPerformanceTimeline(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const resultAiringCombinationPerformanceTimelineSpend = await TvService.getAiringCombinationPerformanceTimeline(
                token,
                accountId,
                projectId,
                reportId,
                'spend',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            const resultAiringCombinationPerformanceTimelineVisits = await TvService.getAiringCombinationPerformanceTimeline(
                token,
                accountId,
                projectId,
                reportId,
                'visits',
                {
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                },
                getFilters()
            )

            setLinechartData([
                {
                    legend: 'Spend',
                    color: COLOR.COLOR_ELECTRIC,
                    points: resultAiringCombinationPerformanceTimelineSpend.map((data: any) => [data.date, data.value]),
                },
                {
                    legend: 'Visits',
                    color: COLOR.COLOR_MAGENTA,
                    points: resultAiringCombinationPerformanceTimelineVisits.map((data: any) => [
                        data.date,
                        data.value,
                    ]),
                },
            ])
            setLoadingAiringCombinationPerformanceTimeline(false)
        } catch (e) {
            handleError(e)
        }
    }

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchFilters()
    }, [accountId, projectId, reportId])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        const filterStationsIndex = findIndexHash(filterStations, station_hash)
        const filterTimeslotsIndex = findIndexHash(filterTimeslots, timeslot_hash)
        const filterGenresBeforeIndex = findIndexHash(filterGenresBefore, genre_before_hash)
        const filterGenresAfterIndex = findIndexHash(filterGenresAfter, genre_after_hash)
        const filterProgramsBeforeIndex = findIndexHash(filterProgramsBefore, program_before_hash)
        const filterProgramsAfterIndex = findIndexHash(filterProgramsAfter, program_after_hash)
        const filterFlightsIndex = findIndexHash(filterFlights, flight_hash)
        const filterDurationsIndex = findIndexHash(filterDurations, duration_hash)

        if (filterStationsIndex != null) {
            setStationsSelectedCache([filterStationsIndex])
            setStationsSelected([filterStationsIndex])
        }

        if (filterTimeslotsIndex != null) {
            setTimeslotsSelectedCache([filterTimeslotsIndex])
            setTimeslotsSelected([filterTimeslotsIndex])
        }

        if (filterGenresBeforeIndex != null) {
            setGenresBeforeSelected([filterGenresBeforeIndex])
            setGenresBeforeSelectedCache([filterGenresBeforeIndex])
        }

        if (filterGenresAfterIndex != null) {
            setGenresAfterSelected([filterGenresAfterIndex])
            setGenresAfterSelectedCache([filterGenresAfterIndex])
        }

        if (filterProgramsBeforeIndex != null) {
            setProgramsBeforeSelected([filterProgramsBeforeIndex])
            setProgramsBeforeSelectedCache([filterProgramsBeforeIndex])
        }

        if (filterProgramsAfterIndex != null) {
            setProgramsAfterSelected([filterProgramsAfterIndex])
            setProgramsAfterSelectedCache([filterProgramsAfterIndex])
        }

        if (filterFlightsIndex != null) {
            setFlightsSelected([filterFlightsIndex])
            setFlightsSelectedCache([filterFlightsIndex])
        }

        if (filterDurationsIndex != null) {
            setDurationsSelected([filterDurationsIndex])
            setDurationsSelectedCache([filterDurationsIndex])
        }
    }, [
        accountId,
        projectId,
        reportId,
        filterStations,
        filterTimeslots,
        filterGenresBefore,
        filterGenresAfter,
        filterProgramsBefore,
        filterProgramsAfter,
        filterFlights,
        filterDurations,
        station_hash,
        timeslot_hash,
        genre_after_hash,
        genre_before_hash,
        program_after_hash,
        program_before_hash,
        flight_hash,
        duration_hash,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchTotals()
        fetchStationPerformance()
        fetchFlightPerformance()
        fetchTimeslotPerformance()
        fetchAiringCombinationPerformanceTimeline()
    }, [
        app.fromDate,
        app.toDate,
        accountId,
        projectId,
        reportId,
        stationsSelected,
        timeslotsSelected,
        genresBeforeSelected,
        genresAfterSelected,
        programsBeforeSelected,
        programsAfterSelected,
        flightsSelected,
        durationsSelected,
        filter,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchAiringCombinationPerformanceTable()
        fetchPerformanceTable()
    }, [
        app.fromDate,
        app.toDate,
        accountId,
        projectId,
        reportId,
        stationsSelected,
        timeslotsSelected,
        genresBeforeSelected,
        genresAfterSelected,
        programsBeforeSelected,
        programsAfterSelected,
        flightsSelected,
        durationsSelected,
        level,
        filter,
    ])

    useEffect(() => {
        const currency = app.selectedAccount.find((project: any) => project.id == projectId)?.currency ?? 'EUR'
        setLocale(sanitizeLocale(currency))
    }, [app])

    return (
        <>
            <If if={insightsModal}>
                <QuickInsightsComponent
                    accountId={accountId}
                    projectId={projectId}
                    reportId={reportId}
                    onDismiss={() => setInsightsModal(false)}
                    type={INSIGHTS.TYPES.TV}
                />
            </If>

            <NotificationComponent notification={notification} error={error} />
            <NoReportComponent visible={errorNoReport} />

            <View class={!!errorNoReport ? 'blur' : null}>
                <View row justify="flex-start">
                    <Heading large class="mr-900">
                        TV report
                    </Heading>
                    <Flexer />
                    <Text class="mr-300 cl-gray-600">Toggle advanced filter</Text>
                    <Toggle onChange={handleToggleAdvanced} on={advanced} disabled={false} />
                    <Button circle onClick={() => setInsightsModal(true)} class="ml-900">
                        <ButtonIcon icon="zap" />
                    </Button>
                </View>

                <If if={advanced}>
                    <Panel class="mb-900 mt-900" width="100%">
                        <View width="100%">
                            <If if={filter.length == 0}>
                                <Text class="mt-900">
                                    You do not have any filter rules applied. Click on the "Add a new filter row" button
                                    to add a rule. Remember to select "save".
                                </Text>
                            </If>
                            <Filter
                                preSelect
                                noLogic
                                noAddGroups
                                noDeleteGroups
                                buttonText="Save"
                                buttonIcon="reload"
                                comparisons={FILTER.COMPARISONS}
                                groups={[{ logic: '', rows: filter }]}
                                options={filterOptions}
                                onChange={handleFilterChange}
                            />
                        </View>
                    </Panel>
                </If>

                <If if={!advanced}>
                    <View row class="mb-900 mt-900">
                        <View flex={1} class="pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by stations"
                                    selected={stationsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setStationsSelectedCache(indexes)}
                                >
                                    {filterStations.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by timeslots"
                                    selected={timeslotsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setTimeslotsSelectedCache(indexes)}
                                >
                                    {filterTimeslots.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by genres before"
                                    selected={genresBeforeSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setGenresBeforeSelectedCache(indexes)}
                                >
                                    {filterGenresBefore.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by genres after"
                                    selected={genresAfterSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setGenresAfterSelectedCache(indexes)}
                                >
                                    {filterGenresAfter.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View class="ml-800 p-relative" style={{ top: 12 }} column flex="none">
                            <Button circle onClick={handleFilterUpdate} small>
                                <ButtonIcon icon="reload" />
                            </Button>
                        </View>
                    </View>

                    <View row class="mb-900 mt-900">
                        <View flex={1} class="pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by programs before"
                                    selected={programsBeforeSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setProgramsBeforeSelectedCache(indexes)}
                                >
                                    {filterProgramsBefore.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by programs after"
                                    selected={programsAfterSelectedCache}
                                    placeholder="No programs selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setProgramsAfterSelectedCache(indexes)}
                                >
                                    {filterProgramsAfter.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by flights"
                                    selected={flightsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setFlightsSelectedCache(indexes)}
                                >
                                    {filterFlights.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by durations"
                                    selected={durationsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setDurationsSelectedCache(indexes)}
                                >
                                    {filterDurations.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View class="ml-800 p-relative" style={{ top: 12, opacity: 0 }} column flex="none">
                            <Button circle onClick={handleFilterUpdate} small>
                                <ButtonIcon icon="reload" />
                            </Button>
                        </View>
                    </View>
                </If>

                <View row justify="flex-start" class="mt-500 mb-500">
                    <Heading xSmall class="uppercase">
                        Key Metrics
                    </Heading>
                </View>

                <View row class="mb-500" justify="space-between">
                    <>
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Cost per visit"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.costPerVisit}
                            format={NUMERAL.FORMATS.CURRENCY}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Cost per action"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.costPerAction}
                            format={NUMERAL.FORMATS.CURRENCY}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Flights"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.flights}
                            format={NUMERAL.FORMATS.NUMERIC}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Station count"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.stationCount}
                            format={NUMERAL.FORMATS.NUMERIC}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Conversions"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.conversions}
                            format={NUMERAL.FORMATS.NUMERIC}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Spots aired"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.spotsAired}
                            format={NUMERAL.FORMATS.NUMERIC}
                        />
                        <Metric
                            small
                            loading={loadingTotals}
                            width="10%"
                            label="Spend"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.spend}
                            format={NUMERAL.FORMATS.CURRENCY}
                        />
                        <Metric
                            small
                            loading={loadingTotals}
                            width="10%"
                            label="Visits"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.visits}
                            format={NUMERAL.FORMATS.NUMERIC}
                        />
                        <Metric
                            loading={loadingTotals}
                            small
                            width="10%"
                            label="Revenue"
                            icon="arrow-up"
                            color="#0faab2"
                            amount={metrics.revenue}
                            format={NUMERAL.FORMATS.CURRENCY}
                        />
                    </>
                </View>

                <Panel width="100%" class="mb-500" loading={loadingPerformanceTable}>
                    <Table
                        data={performanceTableData}
                        onCleanup={(data) => {
                            switch (level) {
                                case TV_LEVELS.FLIGHT:
                                    setLevel(TV_LEVELS.STATION)
                                    break
                                case TV_LEVELS.TIMESLOT:
                                    setLevel(TV_LEVELS.FLIGHT)
                                    break
                            }
                        }}
                        onLoad={(dataIndex: number, row: any) => {
                            const hash = row[row.length - 1]
                            const index = performanceData.findIndex((data: any) => data.hash == hash)
                            const data = performanceData[index]

                            switch (level) {
                                case TV_LEVELS.STATION:
                                    setLevel(TV_LEVELS.FLIGHT)
                                    setStationLevel(data)
                                    break
                                case TV_LEVELS.FLIGHT:
                                    setLevel(TV_LEVELS.TIMESLOT)
                                    setFlightLevel(data)
                                    break
                            }
                        }}
                    />
                </Panel>

                <Panel
                    width="100%"
                    class="mb-500"
                    loading={loadingAiringCombinationPerformanceTable && loadingAiringCombinationPerformanceTimeline}
                >
                    <Table
                        data={airingTableData}
                        onCleanup={(data) => {
                            // We do not need to set anything here
                            // setData(data)
                        }}
                        onLoad={(dataIndex: number, row: any) => {
                            // Fetch some new data via API
                            // And add that data to the history
                            // setData([...data, defaultTableData('Second one', 'Second Wine', 'Second Water', true)])
                        }}
                    />

                    <br />

                    <LineChartDual title="" data={linechartData} />
                </Panel>

                <Heading large class="mr-500">
                    Station Performance
                </Heading>

                <View row class="mb-500 mt-900">
                    <Panel class="flex-1" loading={loadingStationPerformance}>
                        <View width="100%">
                            <BarChart title="CPA by station" data={stationBarchartCPA} />
                        </View>
                    </Panel>

                    <Panel class="flex-1 ml-500" loading={loadingStationPerformance}>
                        <View width="100%">
                            <BarChart title="CPV by station" data={stationBarchartCPV} />
                        </View>
                    </Panel>
                </View>

                <Heading large class="mr-500">
                    Flight Performance
                </Heading>

                <View row class="mb-500 mt-900">
                    <Panel class="flex-1" loading={loadingFlightPerformance}>
                        <View width="100%">
                            <BarChart title="CPA by flight" data={flightBarchartCPA} />
                        </View>
                    </Panel>

                    <Panel class="flex-1 ml-500" loading={loadingFlightPerformance}>
                        <View width="100%">
                            <BarChart title="CPV by flight" data={flightBarchartCPV} />
                        </View>
                    </Panel>
                </View>

                <Heading large class="mr-500">
                    Timeslot Performance
                </Heading>

                <View row class="mb-500 mt-900">
                    <Panel class="flex-1" loading={loadingTimeslotPerformance}>
                        <View width="100%">
                            <BarChart title="CPA by timeslot" data={timeslotBarchartCPA} />
                        </View>
                    </Panel>

                    <Panel class="flex-1 ml-500" loading={loadingTimeslotPerformance}>
                        <View width="100%">
                            <BarChart title="CPV by timeslot" data={timeslotBarchartCPV} />
                        </View>
                    </Panel>
                </View>
            </View>
        </>
    )
}
