 
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type FilterOption = {
    field: string
    operator: 'hash_in' | 'equals' | 'not_equals' | 'contains' | 'not_contains' | 'regex'
    value: string
}

export type DataQueryParameters = {
    attribution_model: string
    count: number
    date_end: string
    date_start: string
    journey_length_max: number
    journey_length_min: number
    level: string
    offset: number
    umm_filter: string
}

export type TotalsQueryParameters = {
    attribution_model: string
    date_end: string
    date_start: string
    journey_length_max: number
    journey_length_min: number
    level: string
    umm_filter: string
}

export type FilterQueryParameters = {
    attribution_model: string
}

export class CustomerJourneyService {
    static async getData(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        queryParameters: DataQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey/data?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })
        return await returnParser(result)
    }

    static async getFilters(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        level: string,
        queryParameters: FilterQueryParameters
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey/filters${level}?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getTotals(
        token: string,
        accountId: string,
        projectId: string,
        reportId: string,
        queryParameters: TotalsQueryParameters,
        filterOptions: FilterOption[]
    ): Promise<any> {
        const queryString = createURLQueryString(queryParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey/totals?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filterOptions),
        })
        return await returnParser(result)
    }
}
