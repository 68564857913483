import { If, Label, SelectManual, SelectOption, Text, View } from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ERROR } from '../../../../constants'
import { logError } from '../../../../helpers/util'
import { SpendMapperService } from '../../services/spend-mapper-v3.service'
import { Validations } from './spend-mapper-v3-validations'
import './spend-mapper-v3.component.sass'

export const CampaignSelect = ({ initialSelected, options, premapped, mappingId, isFallback, onSave }) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)
    const { accountId, projectId } = useParams()
    const [open, setOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const indexesRef = useRef(null)
    const [validations, setValidations] = useState<any[]>([])

    const updateMappings = async (indexes: number[]) => {
        setLoading(true)
        setError(null)

        try {
            const token = await getAccessTokenSilently()

            // Create a unique array of ID's
            const platformMappings = options.filter((_: any, index: number) => indexes.includes(index))
            const mappingIds = platformMappings.map((option: any) => option.mapping_id)

            // We don't need this FOR NOW (leaving it here)
            // const mappingSpend = platformMappings.reduce((acc, option) => acc + option.spend, 0)
            // Construct the object for the API
            const mappings = [
                {
                    ad_platform_mapping_ids: mappingIds,
                    adtriba_campaign_mapping_id: mappingId,
                },
            ]

            // Actual API call
            await SpendMapperService.putCampaignMappings(token, accountId, projectId, {}, mappings)

            setSelected(indexes)
            setLoading(false)

            // Clear our cache / modal
            indexesRef.current = null
            setOpen(false)
            setConfirm(false)
            setValidations([])

            // Reload the campaigns table
            onSave()
        } catch (e) {
            setError(ERROR.API.GENERAL)
            setLoading(false)
            logError(e)
        }
    }

    const handleOkay = async (indexes: number[]) => {
        setLoading(true)
        setError(null)

        try {
            const token = await getAccessTokenSilently()

            // Create a unique array of ID's
            const platformMappings = options.filter((_: any, index: number) => indexes.includes(index))
            const mappingIds = platformMappings.map((option: any) => option.mapping_id)

            // We don't need this FOR NOW (leaving it here)
            // const mappingSpend = platformMappings.reduce((acc, option) => acc + option.spend, 0)
            // Construct the object for the API
            const mappings = [
                {
                    ad_platform_mapping_ids: mappingIds,
                    adtriba_campaign_mapping_id: mappingId,
                },
            ]

            // Call the api
            const result = await SpendMapperService.postCampaignMappingsValidate(
                token,
                accountId,
                projectId,
                {},
                mappings
            )

            // Check if there are any validation error
            // If there are none - then save right away
            // otherwise cache & show a confirm
            if (result.length == 0) {
                updateMappings(indexes)
            } else {
                indexesRef.current = indexes
                setValidations(result)
                setConfirm(true)
            }
        } catch (e) {
            setError(ERROR.API.GENERAL)
            setLoading(false)
            logError(e)
        }
    }

    const handleDismiss = () => {
        if (!indexesRef.current) setOpen(false)
    }

    const handleClear = () => {
        updateMappings([])
    }

    useEffect(() => {
        const selectedIds = (initialSelected || '').split(',')
        const indexes = []
        options.filter((option: any, index: number) => {
            if (selectedIds.includes(option.mapping_id)) indexes.push(index)
        })
        setSelected(indexes)
    }, [initialSelected, premapped, options])

    return (
        <>
            <If if={premapped}>
                <Label icon="check">Premapped</Label>
            </If>

            <If if={isFallback}>
                <View width={100}>
                    <a
                        href="https://help.adtriba.com/en/articles/4507112-spend-mapper"
                        target="_blank"
                        className="cl-electric fs-400 fw-900"
                    >
                        What is this?
                    </a>
                </View>
            </If>

            <Validations
                campaign={true}
                source={false}
                button="Save"
                visible={confirm}
                validations={validations}
                onConfirm={() => updateMappings(indexesRef.current)}
                onDismiss={() => setConfirm(false)}
                title="These campaign mappings will be overwritten"
            />

            <View class="p-relative">
                <If if={isFallback}>
                    <View class="p-absolute w-100 h-100 bg-cl-white" style={{ zIndex: 10, opacity: 0.5 }} />
                </If>
                <SelectManual
                    small
                    filterable
                    open={open}
                    buttonText="Save"
                    width={500}
                    selected={selected}
                    placeholder="There are no options selected"
                    backgroundColor="transparent"
                    showClear
                    onClick={() => setOpen(!open)}
                    onDismiss={handleDismiss}
                    onClear={handleClear}
                    onOkay={handleOkay}
                >
                    {options.map((option: any, index: number) => {
                        const { spend, campaign, mapped } = option
                        const text = [campaign, spend].filter((text) => !!text).join(' → ')

                        return (
                            <SelectOption key={index} class={mapped ? 'cl-green' : ''}>
                                {text}
                            </SelectOption>
                        )
                    })}
                </SelectManual>
                <If if={!!error}>
                    <Text small class="cl-red ml-500">
                        {error}
                    </Text>
                </If>
            </View>
        </>
    )
}
