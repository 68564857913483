import process from 'process'
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type GetOriginCampaigns = {
    count: number
    mapping_type: string
    order_by: string
    page: number
}

export type GetAdtribaCampaigns = {
    exclude_channels: string[]
}

export type GetCampaignRecommendations = {
    origin_campaign_ids: string[]
}

export type PostCampaignMapping = {
    adtriba_campaign_id: string
    origin_campaign_id: string
}

export type PatchOriginCampaign = {
    hidden: boolean
}

export class SpendMapperService {
    static async getAdtribaCampaigns(
        token: string,
        accountId: string,
        queryStringParameters: GetAdtribaCampaigns
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/adtriba?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getChannelFilter(token: string, accountId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/adtriba/filter/channel`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async createCampaignMappings(
        token: string,
        accountId: string,
        campaignMapping: PostCampaignMapping
    ): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/mappings`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(campaignMapping),
        })
        return await returnParser(result)
    }

    static async updateCampaignMappings(
        token: string,
        accountId: string,
        campaignMapping: PostCampaignMapping,
        mappingId: string
    ): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/mappings/${mappingId}`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(campaignMapping),
        })
        return await returnParser(result)
    }

    static async deleteCampaignMappings(token: string, accountId: string, mappingId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/mappings/${mappingId}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async patchOriginCampaign(
        token: string,
        accountId: string,
        patchOriginCampaign: PatchOriginCampaign,
        campaignId: string
    ): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/origin/${campaignId}`
        const result: any = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(patchOriginCampaign),
        })
        return await returnParser(result)
    }

    static async getOriginCampaigns(
        token: string,
        accountId: string,
        queryStringParameters: GetOriginCampaigns,
        filters: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/origin/mappings?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(filters),
        })
        return await returnParser(result)
    }

    static async getCampaignRecommendations(
        token: string,
        accountId: string,
        queryStringParameters: GetCampaignRecommendations
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/spend-mapper/campaigns/recommendations?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }
}
