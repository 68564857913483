import { createURLQueryString, returnParser } from '../../../helpers/util'

export class InsightsService {
    static async get(token: string, accountId: string, queryStringParameters: any): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/insights/results?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async patch(token: string, accountId: string, insightUpdateRequests: any): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/insights/results`
        const result: any = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(insightUpdateRequests),
        })
        return await returnParser(result)
    }
}
