import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext } from 'react'
import { Route, useHistory, useParams } from 'react-router-dom'
import { IAppContext } from 'types/IAppContext'
import { IRoute } from 'types/IRoute'
import { PLACEHOLDER_UUID_FOR_URL, ROLE } from '../../constants'
import { AppContext } from '../../contexts/app.context'
import './app.mod.sass'
import { ChannelMapperComponent } from './components/channel-mapper/channel-mapper.component'
import { CustomerJourneyComponent } from './components/customer-journey/customer-journey.component'
import { ExternalComponent } from './components/external/external.component'
import { InsightsComponent } from './components/insights/insights.component'
import { IntegrationsComponent } from './components/integrations/integrations.component'
import { PerformanceComponent } from './components/performance/performance.component'
import { SpendMapperV2Component } from './components/spend-mapper-v2/spend-mapper-v2.component'
import { SpendMapperV3Component } from './components/spend-mapper-v3/spend-mapper-v3.component'
import { SpendMapperComponent } from './components/spend-mapper/spend-mapper.component'
import { TrackingComponent } from './components/tracking/tracking.component'
import { TvComponent } from './components/tv/tv.component'

export class AppRoutes {
    static generate(role: string, features: any): IRoute[] {
        return [
            {
                path: `/accounts/:accountId/projects/:projectId/insights`,
                icon: 'opportunities',
                label: 'Insights',
                end: 'insights',
                rule: 'insights',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/performance`,
                icon: 'performance',
                label: 'Performance',
                end: 'performance',
                rule: 'performance',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/tracking`,
                icon: 'customer-journey',
                label: 'Tracking',
                end: 'tracking',
                rule: 'tracking',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/tv`,
                icon: 'tv',
                label: 'TV',
                end: 'tv',
                rule: 'tv',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/customer-journey`,
                icon: 'dataquality',
                label: 'Customer Journey',
                end: 'customer-journey',
                rule: 'customer_journey',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                hidden: role == ROLE.READER || role == ROLE.EDITOR,
                path: `/accounts/:accountId/projects/:projectId/integrations`,
                icon: 'integrations',
                label: 'Integrations',
                end: 'integrations',
                rule: 'integrations',
                enabled: false,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                hidden: role == ROLE.READER,
                path: `/accounts/:accountId/projects/:projectId/channel-mapper`,
                icon: 'zap',
                label: 'Channel Mapper',
                end: 'channel-mapper',
                rule: 'channel_mapper',
                enabled: false,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                hidden: role == ROLE.READER || features.spendmapperv3,
                path: `/accounts/:accountId/spend-mapper`,
                icon: 'money',
                label: 'Spend Mapper',
                end: 'spend-mapper',
                rule: 'spend_mapper',
                enabled: true,
                required: {
                    accountId: true,
                },
            },
            {
                hidden: role == ROLE.READER || !features.spendmapperv3,
                path: `/accounts/:accountId/projects/:projectId/spend-mapper-v3`,
                icon: 'money',
                label: 'Spend Mapper',
                end: 'spend-mapper-v3',
                rule: 'spend_mapper',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
        ]
    }
}

export const NavRoutes = ({ component, className }: { component: React.FunctionComponent<{}>; className?: string }) => {
    return (
        <div className={className}>
            <Route
                exact
                component={component}
                path={[
                    '/',
                    '/accounts',
                    '/accounts/:accountId',
                    '/accounts/:accountId/projects/:projectId',
                    '/accounts/:accountId/projects/:projectId/tracking',
                    '/accounts/:accountId/projects/:projectId/insights',
                    '/accounts/:accountId/projects/:projectId/reports/:reportId',
                    '/accounts/:accountId/projects/:projectId/reports/:reportId/*',
                    '/accounts/:accountId/projects/:projectId/integrations',
                    '/accounts/:accountId/spend-mapper',
                    '/accounts/:accountId/spend-mapper-v1',
                    '/accounts/:accountId/spend-mapper-v3',
                    '/accounts/:accountId/projects/:projectId/spend-mapper-v3',
                    '/accounts/:accountId/projects/:projectId/channel-mapper',
                    '/accounts/:accountId/projects/:projectId/external',
                    '/accounts/:accountId/external',
                ]}
            />
        </div>
    )
}

type AppModuleRouteType = {
    exact: boolean
    path: string
    restrictedRoles?: string
    component?: React.FunctionComponent
}

const appModuleRoutes: AppModuleRouteType[] = [
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/insights',
        component: InsightsComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/performance',
        component: PerformanceComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/tracking',
        component: TrackingComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/customer-journey',
        component: CustomerJourneyComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/tv',
        component: TvComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/integrations',
        restrictedRoles: ROLE.READER || ROLE.EDITOR,
        component: IntegrationsComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper',
        restrictedRoles: ROLE.READER,
        component: SpendMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v1',
        restrictedRoles: ROLE.READER,
        component: SpendMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v2',
        restrictedRoles: ROLE.READER,
        component: SpendMapperV2Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v3',
        restrictedRoles: ROLE.READER,
        component: SpendMapperV3Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/spend-mapper-v3',
        restrictedRoles: ROLE.READER,
        component: SpendMapperV3Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/channel-mapper',
        restrictedRoles: ROLE.READER,
        component: ChannelMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/channel-mapper',
        restrictedRoles: ROLE.READER,
        component: ChannelMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/external',
        component: ExternalComponent,
    },
]

export const AppModuleRoutes = ({ className }: { className?: string }) => {
    const app = useContext<IAppContext>(AppContext)

    return (
        <div className={className}>
            {appModuleRoutes.map((route, index) => {
                const { component, exact, restrictedRoles } = route
                const path = restrictedRoles === app.role ? '/' : route.path
                return <Route key={index} path={path} exact={exact} component={component} />
            })}
        </div>
    )
}

// Handle redirects based on account, project, and report selection
export const handleRedirect = async (accountId: string, projectId: string, reportId: string) => {
    const params = useParams()
    const app = useContext<IAppContext>(AppContext)
    const { user, logout, getAccessTokenSilently: auth } = useAuth0()
    const history = useHistory()

    const { pathname } = window.location
    const parts = pathname.split('/')
    const last = parts[parts.length - 1]

    // If they change projects
    if (projectId != params.projectId) {
        const token = await auth()
        const reports = app.selectedAccount?.find((project: any) => project.id == projectId)?.reports
        const report = reports.length == 0 ? -1 : 0
        const reportId = reports[report] ? reports[report].id : PLACEHOLDER_UUID_FOR_URL

        switch (last) {
            case 'performance':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`)
                break
            case 'tv':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`)
                break
            case 'customer-journey':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`)
                break
            case 'tracking':
                history.push(`/accounts/${accountId}/projects/${projectId}/tracking`)
                break
            case 'insights':
                history.push(`/accounts/${accountId}/projects/${projectId}/insights`)
                break
            case 'channel-mapper':
                history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`)
                break
            case 'spend-mapper':
                history.push(`/accounts/${accountId}/spend-mapper`)
                break
            case 'spend-mapper-v2':
                history.push(`/accounts/${accountId}/spend-mapper-v2`)
                break
            case 'spend-mapper-v3':
                history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`)
                break
        }
    }

    // If they change reports
    if (reportId != params.reportId) {
        switch (last) {
            case 'performance':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`)
                break
            case 'tv':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`)
                break
            case 'customer-journey':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`)
                break
            case 'tracking':
                history.push(`/accounts/${accountId}/projects/${projectId}/tracking`)
                break
            case 'insights':
                history.push(`/accounts/${accountId}/projects/${projectId}/insights`)
                break
            case 'channel-mapper':
                history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`)
                break
            case 'spend-mapper':
                history.push(`/accounts/${accountId}/spend-mapper`)
                break
            case 'spend-mapper-v2':
                history.push(`/accounts/${accountId}/spend-mapper-v2`)
                break
            case 'spend-mapper-v3':
                history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`)
                break
        }
    }
}
