import React, { useEffect, useState, useContext, FunctionComponent, ReactElement } from 'react'
import { View, Spinners, SPINNERS } from '@adtriba/ui'

interface ILoaderComponent {
    loading: boolean
    children?: any
}

export const LoaderComponent = (props: ILoaderComponent) => {
    if (!props.loading) return null
    return (
        <View
            width="100%"
            height="100%"
            class="p-fixed top-0 left-0"
            row
            flex="none"
            style={{ backgroundColor: 'rgba(255,255,255,0.75', zIndex: 2, top: 60 }}
        >
            <Spinners type={SPINNERS.CIRCLE} />
        </View>
    )
}
