import {
    AlertContext,
    Button,
    ButtonText,
    Filter,
    Icon,
    If,
    Label,
    Modal,
    Notification,
    NotificationIcon,
    NotificationText,
    View,
} from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import { COLOR, ERROR, POSITION } from '../../../../constants'
import {
    filterGroupsAreEmpty,
    formatIncomingRulesets,
    formatOutgoingRulesets,
    hasEmptyRules,
    logError,
    waitForState,
} from '../../../../helpers/util'
import { SpendMapperService } from '../../services/spend-mapper-v3.service'
import { Validations } from './spend-mapper-v3-validations'
import './spend-mapper-v3.component.sass'

export const Ruleset = ({ visible, source, channel, onClose, onMap }) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const alert = useContext(AlertContext)
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const comparisons = [
        'EQUALS',
        'NOT_EQUALS',
        'NOT_CONTAINS',
        'CONTAINS',
        'CONTAINS_IGNORE_CASE',
        'NOT_CONTAINS_IGNORE_CASE',
        'REGEX',
        'NOT_REGEX',
    ]
    const [groups, setGroups] = useState([])
    const [options, setOptions] = useState([])
    const [validations, setValidations] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [warn, setWarn] = useState(false)
    const groupsRef = useRef(null)
    const [isNew, setIsNew] = useState(false)
    const sort = (a: string, b: string) => a.localeCompare(b)

    const getSourceRulesetOptions = async () => {
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const options = []

            // Filter values for the source mapping modal
            // These values populate the dropdown options for the
            // filter component options (right hand side drop down)
            const { datasource_platform_names, sub_platform_names } = await SpendMapperService.getFilterValues(
                token,
                accountId,
                projectId,
                {
                    project_id: projectId,
                }
            )

            // If there are data sources
            if (datasource_platform_names.length) {
                options.push({
                    label: 'Data Source',
                    value: 'datasource_platform_name',
                    disableComparison: null,
                    valuesFor: ['EQUALS', 'NOT_EQUALS'],
                    values: datasource_platform_names.sort(sort).map((name: string) => ({ label: name, value: name })),
                })
            }

            // If there are sub platforms
            if (sub_platform_names.length) {
                options.push({
                    label: 'Sub Platform',
                    value: 'sub_platform_name',
                    disableComparison: null,
                    valuesFor: ['EQUALS', 'NOT_EQUALS'],
                    values: sub_platform_names.sort(sort).map((name: string) => ({ label: name, value: name })),
                })
            }

            // Always present
            options.push({
                label: 'Campaign',
                value: 'campaign',
                disableComparison: null,
                valuesFor: [],
                values: null,
            })

            // Set the options with the values passed from the API
            setOptions(options)
        } catch (e) {
            logError(e)
            if (e == 204) {
                setGroups([{ logic: null, rows: [] }])
                setIsNew(true)
            } else {
                setError(ERROR.API.GENERAL)
            }
        }
    }

    const getSourceRulesetRules = async (validate: boolean) => {
        setError(null)

        try {
            const token = await getAccessTokenSilently()

            // Get the rulesets for this source
            // This shouldn't return a 204 when empty
            const result = await SpendMapperService.getRulesets(token, accountId, projectId, {
                project_id: projectId,
                channel,
                source,
            })

            const groups = formatIncomingRulesets(result.rules, options)

            setIsNew(false)
            setGroups(groups)

            if (validate) validateSourceRulesetGroups({ confirm: false, warn: false, groups, options })
        } catch (e) {
            logError(e)
            if (e == 204) {
                setGroups([{ logic: null, rows: [] }])
                setIsNew(true)
            } else {
                setError(ERROR.API.GENERAL)
            }
        }
    }

    const validateSourceRulesetGroups = async ({ confirm, warn, groups, options }) => {
        setError(null)

        // This checks to see if dropdowns are selected
        let emptyValues = false

        groups.map((group: any) => {
            if (!group.rows) return

            group.rows.map(({ option, comparison, value }) => {
                if (!options) return
                if (!options[option]) return

                const selectedOption = options[option]
                const selectedOptionHasDropddown =
                    !!selectedOption.values && selectedOption.valuesFor.includes(comparison)
                const valueIsString = typeof value == 'string'
                if (selectedOptionHasDropddown && !value && valueIsString) emptyValues = true
            })
        })

        // Turn this into a non blocking operation for now
        if (emptyValues) return setError('Please select a valid option.')

        try {
            const token = await getAccessTokenSilently()
            const rules = formatOutgoingRulesets(groups, options)
            const result = await SpendMapperService.postRulesetsValidate(
                token,
                accountId,
                projectId,
                {
                    project_id: projectId,
                    channel,
                    source,
                },
                rules
            )
            const valid = result.length == 0

            // Only show the confirm & warn dialogs
            // if either have validation errors (!valid)
            setValidations(result || [])
            setConfirm(confirm ? !valid : false)
            setWarn(warn ? !valid : false)

            // If there are no validations
            // and if they are confirming a save
            // Then go straight to update & don't show modal
            if (valid && confirm) updateSourceRuleset(groupsRef.current, options)
        } catch (e) {
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const updateSourceRuleset = async (groups, options) => {
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const rulesetHasEmptyRules = hasEmptyRules(groups)
            const rulesetFilterGroupsAreEmpty = filterGroupsAreEmpty(groups)
            const rules = formatOutgoingRulesets(groups, options)

            // If the groups are all empty & it's not new
            // Then delete the mapping
            if (rulesetFilterGroupsAreEmpty && !isNew) {
                setLoading(true)
                await SpendMapperService.deleteRulesets(token, accountId, projectId, {
                    project_id: projectId,
                    channel,
                    source,
                })
            } else {
                // Otherwise - if it has any empty rulesets
                // then block the user, other:
                // if there are valid rulesets,
                // then save regardless of newness
                if (rulesetHasEmptyRules) {
                    setError('Rulesets cannot be empty')
                    setWarn(false)
                    setConfirm(false)
                    return
                } else {
                    setLoading(true)
                    await SpendMapperService.putRulesets(
                        token,
                        accountId,
                        projectId,
                        {
                            project_id: projectId,
                            channel,
                            source,
                        },
                        rules
                    )
                }
            }

            // Update the state
            setGroups(groups)
            setLoading(false)
            setWarn(false)
            setConfirm(false)

            // Call the parent method & close the modal
            onMap()
        } catch (e) {
            setLoading(false)
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    // When it's visible, then get the options
    useEffect(() => {
        if (visible) {
            getSourceRulesetOptions()
        } else {
            setOptions([])
            setGroups([{ logic: null, rows: [] }])
        }
    }, [visible])

    // When there are options
    // then get the actual rules
    useEffect(() => {
        if (options.length > 0) {
            getSourceRulesetRules(true)
            // The options need to be updated first before the rules
            // For now this little hack is the best way
            // But need to find a better solution
            waitForState(() => getSourceRulesetRules(false))
        }
    }, [options])

    // Only display this if it's not null
    if (!visible) return null

    return (
        <>
            {/* Warning dialog */}
            <Validations
                campaign={false}
                source={true}
                button="Okay"
                visible={warn}
                validations={validations}
                onConfirm={() => setWarn(false)}
                onDismiss={() => setWarn(false)}
                title="This ruleset conflicts with these source rulesets"
            />

            {/* Confirmation dialog */}
            <Validations
                campaign={false}
                source={true}
                button="Save"
                visible={confirm}
                validations={validations}
                onConfirm={() => updateSourceRuleset(groupsRef.current, options)}
                onDismiss={() => setConfirm(false)}
                title="Are you sure you want to overwrite the following source rulesets?"
            />

            <Modal
                disableBackgroundDismiss
                width="900px"
                height="auto"
                position={POSITION.CENTER}
                borderRadius={20}
                onDismiss={onClose}
                footer={null}
                header={
                    <>
                        <Label dark icon="map" class="mr-900">
                            Source
                        </Label>
                        <a href="https://help.adtriba.com/en/collections/2825246-adtriba-core" target="_blank">
                            <Icon icon="info" color={COLOR.COLOR_GRAY_500} size={20} />
                        </a>
                    </>
                }
                icon={null}
                title={source}
                showClose={true}
            >
                <LoaderComponent loading={loading} />

                <View class="p-100">
                    <NotificationComponent notification={notification} error={error} />
                </View>

                <If if={validations.length != 0}>
                    <View class="p-100">
                        <Notification warning class="mb-100">
                            <NotificationText>This rule overlaps with other rulesets.</NotificationText>
                            <NotificationIcon
                                icon="info"
                                onClick={() =>
                                    validateSourceRulesetGroups({ confirm: false, warn: true, groups, options })
                                }
                            />
                        </Notification>
                    </View>
                </If>

                <Filter
                    maxHeight={window.innerHeight - 200}
                    minHeight={window.innerHeight - 200}
                    small={false}
                    footer={
                        <Button class="mr-auto" onClick={onClose}>
                            <ButtonText>Close</ButtonText>
                        </Button>
                    }
                    footerContainerStyles={{ padding: 10 }}
                    groupContainerRulesStyles={{ padding: 10 }}
                    groupContainerToolbarStyles={{ padding: 10, paddingTop: 0 }}
                    preSelect
                    showGroupHeader
                    noLogic
                    showLogic
                    groups={groups}
                    comparisons={comparisons}
                    options={options}
                    onUpdate={(groups) => {
                        setLoading(false)
                        setError(null)
                        setNotification(null)
                    }}
                    onChange={(groups: any) => {
                        // Cache the new groups
                        groupsRef.current = groups
                        // Ask the user to confirm their choice
                        // which will use the cache from above
                        validateSourceRulesetGroups({ confirm: true, warn: false, groups, options })
                    }}
                />
            </Modal>
        </>
    )
}
