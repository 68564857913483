import { useAuth0 } from '@auth0/auth0-react'
import posthog from 'posthog-js'
import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import process from 'process'
import { AppContext } from '../../contexts/app.context'
import { logError } from '../../helpers/util'
import useVerifyEmail from '../../hooks/useVerifyUserEmail'
import { IAppContext } from '../../types/IAppContext'
import './app.mod.sass'
import { AccountService } from './services/account.service'
import { UserService } from './services/user.service'

export const useAppModuleUtils = () => {
    const { user, logout, getAccessTokenSilently: auth } = useAuth0()
    const params = useParams()
    const verified = user.email_verified
    const app = useContext<IAppContext>(AppContext)
    const history = useHistory()

    // State variables for managing location keys, error, and loading
    const [locationKeys, setLocationKeys] = useState<any>([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState<boolean>(false)
    const { error: verificationError, loading: loadingVerification, verifyEmail } = useVerifyEmail()

    const fetchAccountsList = async () => {
        setLoading(true)
        setError(null)

        try {
            const token = await auth()
            const accounts = await UserService.get(token)
            const accountsList = accounts.sort((a: any, b: any) => a.name.localeCompare(b.name))

            if (!accounts.length) {
                throw new Error('No accounts found')
            }

            app.setApp({
                ...app,
                accountsList,
            })
        } catch (e) {
            logError(e)
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    // Set account ID in the global context
    const setSelectedAccountId = async (accountId: string) => {
        app.setApp({
            ...app,
            selectedAccountId: accountId,
        })
    }

    // Fetch all the selected account's details,
    // and store them in the global context
    const getAccountDetails = async (accountId: string) => {
        const token = await auth()

        // Fetch the account details
        const selectedAccount = (await AccountService.getAccountStructure(
            token,
            accountId
        )) as typeof app.selectedAccount

        const selectedProjectId =
            (await selectedAccount.find((project) => project.id == params.projectId)) ?? selectedAccount[0]?.id ?? null

        const selectedReportId = selectedProjectId
            ? selectedAccount
                  .find((project) => project.id == selectedProjectId)
                  ?.reports.sort((a: any, b: any) => a.name.localeCompare(b.name))[0]?.id ?? null
            : null

        app.setApp({
            ...app,
            selectedAccountId: accountId,
            selectedAccount,
            selectedProjectId,
            selectedReportId,
        })
    }

    const historyNavigation = () => {
        return history.listen((location: any) => {
            console.log('location', location)
            if (history.action === 'PUSH') setLocationKeys([location.key])

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    // Forward event
                } else {
                    // Back event
                    setLocationKeys((keys) => [location.key, ...keys])
                    window.location.reload()
                }
            }
        })
    }

    const initPosthog = () => {
        if (!user) return
        if (!user.email) return

        if (process.env.REACT_APP_NODE_ENV == 'production') {
            posthog.identify(user.email, {
                email: user.email,
                name: user.name,
                environment: process.env.REACT_APP_NODE_ENV,
            })
        }
    }

    return {
        fetchAccountsList,
        setSelectedAccountId,
        getAccountDetails,
        locationKeys,
        setLocationKeys,
        error,
        loading,
        verified,
        verificationError,
        loadingVerification,
        verifyEmail,
        logout,
        user,
        historyNavigation,
        history,
        initPosthog,
    }
}
