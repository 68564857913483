import {
    AccountSelect,
    Avatar,
    Button,
    ButtonIcon,
    ButtonText,
    Core,
    DatePicker,
    Flexer,
    Heading,
    Icon,
    If,
    Label,
    Menu,
    MenuItem,
    MenuItems,
    Popup,
    Select,
    SelectOption,
    TOOLTIP,
    Text,
    Tooltip,
    View,
} from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { UpdateContext } from '../../../../app'
import { UpdateDialog } from '../../../../components/updatemodal/updatemodal.component'
import { ADMIN, DATE_PRESETS, POSITION } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { logError, waitForState } from '../../../../helpers/util'
import './nav.component.sass'
import classNames from 'classnames'

interface INavComponent {
    children?: any
}

export const NavComponent: FunctionComponent = (props: INavComponent): ReactElement => {
    // Destructure authentication routing, and context values
    const { logout, user } = useAuth0()
    const { accountId, reportId } = useParams()
    const params = useParams()
    const history = useHistory()
    const app = useContext(AppContext)

    // UI-related state
    const updateAvailable = useContext<boolean>(UpdateContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    // Refs
    const updateModalref = useRef(null)

    // Local storage related state
    const updated = JSON.parse(localStorage.getItem('updated'))

    // Derived state
    const hasReports = app?.selectedAccount.find((project: any) => project.id == app?.selectedProjectId)?.reports.length
    const isOwner = app?.accountsList.find((account: any) => account.id === accountId)?.is_owner

    // Path-related variables
    const { pathname } = window.location
    const parts = pathname.split('/')
    const slug = parts[parts.length - 1]

    // Sorting function
    const sortByName = (a: any, b: any) => (a.name ? a.name.localeCompare(b.name) : false)

    const handleClickUpdateButton = () => {
        localStorage.setItem('updated', 'true')
        const currentUrl = window.location.href
        window.location.reload()
        window.location.href = currentUrl
    }

    // Handle account select menu
    const handleAccountSelect = async (account: any) => {
        if (account?.id == app.selectedAccountId) return setVisible(false)
        const selectedAccountId = app?.accountsList.find((a: any) => a.id == account.id).id

        // initilaize the selected account
        // app.setApp({
        //     ...app,
        //     selectedAccount: [],
        //     selectedAccountId: null,
        //     selectedProjectId: null,
        //     selectedReportId: '0',
        // })

        // waitForState(() => history.push(`/accounts/${selectedAccountId}/projects/${app.selectedProjectId}/insights`))
        try {
            app.setApp({
                ...app,
                selectedAccount: [],
                selectedAccountId,
                selectedProjectId: null,
                selectedReportId: '1',
            })

            // redirect to insights
            waitForState(
                () => history.push(`/accounts/${selectedAccountId}/projects/${app.selectedProjectId}/insights`),
                0
            )

            // Close the switcher
            setVisible(false)
        } catch (error) {
            logError('error', error)
        }
    }

    const handleRedirect = async (accountId, projectId, reportId) => {
        // If they change projects
        if (projectId != params.projectId) {
            switch (slug) {
                case 'performance':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`)
                    break
                case 'tv':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`)
                    break
                case 'customer-journey':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`)
                    break
                case 'tracking':
                    history.push(`/accounts/${accountId}/projects/${projectId}/tracking`)
                    break
                case 'insights':
                    history.push(`/accounts/${accountId}/projects/${projectId}/insights`)
                    break
                case 'channel-mapper':
                    history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`)
                    break
                case 'spend-mapper':
                    history.push(`/accounts/${accountId}/spend-mapper`)
                    break
                case 'spend-mapper-v2':
                    history.push(`/accounts/${accountId}/spend-mapper-v2`)
                    break
                case 'spend-mapper-v3':
                    history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`)
                    break
            }
        }

        // If they change reports
        if (reportId != params.reportId) {
            switch (slug) {
                case 'performance':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`)
                    break
                case 'tv':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`)
                    break
                case 'customer-journey':
                    history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`)
                    break
                case 'tracking':
                    history.push(`/accounts/${accountId}/projects/${projectId}/tracking`)
                    break
                case 'insights':
                    history.push(`/accounts/${accountId}/projects/${projectId}/insights`)
                    break
                case 'channel-mapper':
                    history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`)
                    break
                case 'spend-mapper':
                    history.push(`/accounts/${accountId}/spend-mapper`)
                    break
                case 'spend-mapper-v2':
                    history.push(`/accounts/${accountId}/spend-mapper-v2`)
                    break
                case 'spend-mapper-v3':
                    history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`)
                    break
            }
        }
    }

    const handleProjectSelect = async (selectedProjectIndex: string) => {
        setLoading(true)
        const selectedProject = app.selectedAccount[selectedProjectIndex]
        const selectedProjectId = selectedProject.id
        const selectedReportId = !selectedProject.reports.length ? null : selectedProject.reports.sort(sortByName)[0].id

        app.setApp({
            ...app,
            selectedProjectId,
            selectedReportId,
        })

        waitForState(() => handleRedirect(app.selectedAccountId, selectedProjectId, selectedReportId), 200)
        setLoading(false)
    }

    const handleReportSelect = async (selectedReportIndex: string) => {
        if (loading) return
        setLoading(true)
        const reportsArray = app.selectedAccount?.find((project: any) => project.id == app.selectedProjectId)?.reports
        const selectedReportId = reportsArray.length ? reportsArray.sort(sortByName)[selectedReportIndex].id : null

        app.setApp({
            ...app,
            selectedReportId,
        })

        waitForState(() => handleRedirect(app.selectedAccountId, app.selectedProjectId, selectedReportId), 200)
        setLoading(false)
    }

    const handleDateChange = ({ dateStart, dateEnd }) => {
        if (app.fromDate == dateStart && app.toDate == dateEnd) return
        waitForState(
            app.setApp({
                ...app,
                fromDate: dateStart,
                toDate: dateEnd.endOf('day'),
            }),
            200
        )
    }

    // When we have a selected Project ID in context,
    // we auto-select the first report for that.
    useEffect(() => {
        if (!app.selectedAccount.length) return
        if (!app.selectedAccountId) return
        if (!app.selectedProjectId) return
        if (!app.selectedProjectId) return

        handleReportSelect('0')
    }, [app.selectedProjectId])

    // If all the accounts are stored in context,
    // we auto-select the first account.
    useEffect(() => {
        if (!app.accountsList.length) return
        const accountToSelect = app.accountsList.find((account: any) => account.id == accountId) ?? app.accountsList[0]
        handleAccountSelect(accountToSelect)
    }, [app.accountsList])

    // Hanlde toggling the update modal
    useEffect(() => {
        if (updateAvailable) {
            updateModalref.current.showModal()
        }
    }, [updateAvailable])

    // Return disabled state inline CSS, based on condition
    const disableByCondition = (condition: boolean) => {
        return { pointerEvents: condition ? 'none' : 'auto', opacity: condition ? 0.5 : 1 }
    }

    return (
        <div
            className={classNames('nav-component', {
                // blur: !loading && !app.accountsList.length,
            })}
        >
            <View row>
                <View width={60} height={60} class="bdr-right bdr-cl-gray-200">
                    <Popup
                        position={POSITION.LEFT}
                        width={600}
                        containerWidth={600}
                        content={
                            <View width={600}>
                                <AccountSelect
                                    accounts={app.accountsList}
                                    onSelect={handleAccountSelect}
                                    footer={
                                        <View>
                                            <View class="p-500" row justify="flex-end">
                                                <Text
                                                    small
                                                    class="cl-gray-500 buttonize"
                                                    onClick={() => window.open('https://adtriba.com')}
                                                >
                                                    Help & Support
                                                </Text>
                                                <Flexer />
                                                <Button
                                                    small
                                                    light
                                                    onClick={() =>
                                                        process.env.REACT_APP_NODE_ENV == 'development'
                                                            ? window.open('https://accounts-dev.adtriba.app')
                                                            : window.open('https://accounts.adtriba.app')
                                                    }
                                                >
                                                    <ButtonIcon icon="settings" />
                                                    <ButtonText>Manage Accounts</ButtonText>
                                                </Button>
                                            </View>
                                        </View>
                                    }
                                />
                            </View>
                        }
                        visible={visible}
                        onDismiss={() => setVisible(false)}
                    >
                        <View row width={60} height={60} class="buttonize" onClick={() => setVisible(true)}>
                            <Core size={0.5} />
                        </View>
                    </Popup>
                </View>

                <If if={!!app.accountsList.find((account: any) => account.id == app.selectedAccountId)}>
                    <View class="pl-900 pr-900">
                        <Heading small class="cl-white p-200 bdr-r-500 bg-cl-electric pl-400 pr-400">
                            {app.accountsList.find((account: any) => account.id == app.selectedAccountId)?.name}
                        </Heading>
                    </View>
                </If>

                <View class="ml-200">
                    {!slug.includes('spend-mapper') ? (
                        <DatePicker
                            presets={DATE_PRESETS}
                            placeholder="Select a date"
                            onDateSelect={handleDateChange}
                            position={POSITION.LEFT}
                            width="fit-content"
                            popupWidth={700}
                            dateMax={null}
                            dateStart={app.fromDate}
                            dateEnd={app.toDate}
                            historic
                        />
                    ) : (
                        <div
                            className="ml-200 pr-400 fw-600"
                            style={{
                                opacity: 0.4,
                            }}
                        >
                            Historical Date
                        </div>
                    )}
                </View>

                <View style={disableByCondition((!hasReports && slug === 'performances') || loading)}>
                    <Select
                        width={200}
                        placeholder="Project"
                        selected={app.selectedAccount.findIndex((project: any) => project.id == app.selectedProjectId)}
                        onSelect={handleProjectSelect}
                    >
                        {app.selectedAccount.sort(sortByName).map((project: any) => {
                            const projectName =
                                slug == 'performance' && !project?.reports.length
                                    ? `${project.name} (no reports)`
                                    : project.name
                            return (
                                <SelectOption
                                    key={project.id}
                                    disabled={slug == 'performance' && !project?.reports.length}
                                >
                                    {projectName}
                                </SelectOption>
                            )
                        })}
                    </Select>
                </View>

                <View class={!reportId ? 'hide' : ''} style={disableByCondition(!hasReports || loading)}>
                    <Select
                        width={200}
                        placeholder="Report"
                        selected={
                            hasReports
                                ? app.selectedAccount
                                      .find((project: any) => project.id == app.selectedProjectId)

                                      ?.reports.sort(sortByName)
                                      .findIndex((report: any) => report.id == app.selectedReportId)
                                : -1
                        }
                        onSelect={handleReportSelect}
                    >
                        {app.selectedAccount
                            .find((project: any) => project.id == app.selectedProjectId)
                            ?.reports.sort(sortByName)
                            .map((report: any, index: number) => (
                                <SelectOption key={index}>{report.name}</SelectOption>
                            ))}
                    </Select>
                </View>

                <Flexer />

                <If if={!updated}>
                    <>
                        <Tooltip text={'Updates available. Click to update.'} position={TOOLTIP.POSITION.BOTTOM}>
                            <a
                                onClick={() => updateModalref.current.showModal()}
                                className={'update-button'}
                                data-update-spinner="true"
                            >
                                <Icon icon="reload" size={20} color="#1cc7d0 " />
                            </a>
                        </Tooltip>
                    </>
                </If>

                <UpdateDialog ref={updateModalref} handleUpdate={handleClickUpdateButton} />

                {!!app.role && (
                    <div className="d-flex align-items-center justify-content-center gap-4">
                        <Label aqua class="mr-500">
                            {app.role}
                        </Label>
                    </div>
                )}

                <View class="nav-component__menu">
                    <Menu
                        position={POSITION.RIGHT}
                        width={300}
                        menu={
                            <MenuItems>
                                <MenuItem onClick={null}>
                                    <View width={300}>
                                        <View column align="flex-end">
                                            <Heading small>{user.email}</Heading>
                                        </View>
                                    </View>
                                </MenuItem>

                                {(isOwner || app?.role === 'ADMIN') && (
                                    <MenuItem
                                        onClick={() => {
                                            window.open(
                                                process.env.REACT_APP_NODE_ENV == 'development'
                                                    ? 'https://accounts-dev.adtriba.app'
                                                    : 'https://accounts.adtriba.app'
                                            )
                                        }}
                                    >
                                        <View width={300}>
                                            <View column align="flex-end">
                                                <Text small class="buttonize">
                                                    Manage Accounts
                                                </Text>
                                            </View>
                                        </View>
                                    </MenuItem>
                                )}
                                <MenuItem onClick={logout}>
                                    <View width={300}>
                                        <View column align="flex-end">
                                            <Text small class="buttonize">
                                                Logout
                                            </Text>
                                        </View>
                                    </View>
                                </MenuItem>
                            </MenuItems>
                        }
                    >
                        <Avatar image={user.picture} small />
                    </Menu>
                </View>

                <View width={20} />
            </View>
        </div>
    )
}
