import { Button, ButtonIcon, ButtonText, If, Panel, View } from '@adtriba/ui'
import classNames from 'classnames'
import React, { FunctionComponent, ReactElement, useContext, useEffect } from 'react'
import { BrowserRouter as Router, useParams } from 'react-router-dom'
import { IAppContext } from 'types/IAppContext'
import VerificationErrorDialog from '../../components/dialogs/dialog.verification-error'
import { AppContext } from '../../contexts/app.context'
import './app.mod.sass'
import { AppModuleRoutes, NavRoutes } from './app.routes'
import { NavComponent } from './components/nav/nav.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { useAppModuleUtils } from './useAppModuleUtils'
import NoAccountsDialog from '../../components/dialogs/dialog.no-accounts'

interface IAppModule {
    children?: any
}

export const AppModule: FunctionComponent = (props: IAppModule): ReactElement => {
    const params = useParams()
    const app = useContext<IAppContext>(AppContext)

    const {
        fetchAccountsList,
        setSelectedAccountId,
        getAccountDetails,
        locationKeys,
        error,
        loading,
        verified,
        verifyEmail,
        logout,
        user,
        historyNavigation,
        initPosthog,
    } = useAppModuleUtils()

    // Render logout UI
    const renderLogout = () => {
        return (
            <>
                <strong>Not permitted</strong>
                <button onClick={() => logout()}>Logout</button>
            </>
        )
    }

    // Handling location changes and reloading on back navigation
    useEffect(() => {
        historyNavigation()
    }, [locationKeys])

    // Identify user using PostHog in the production environment
    useEffect(() => {
        initPosthog()
    }, [user])

    // When we have set all the accounts list in the context,
    // select the first account fromn the list.
    useEffect(() => {
        if (user && !user.email_verified) return
        if (!app.accountsList.length) {
            fetchAccountsList()
        }
    }, [app.accountsList])

    // When we have set all the account ID in the context
    useEffect(() => {
        if (error) return
        if (!app.accountsList.length) return
        if (app.selectedAccountId) return
        if (!params.accountId) return

        setSelectedAccountId(app.accountsList[0].id)
    }, [app.accountsList])

    // When we have the selected account ID in the context,
    // fetch all the account details and store them in the context
    useEffect(() => {
        if (error) return
        if (!app.selectedAccountId) return
        if (!app.accountsList.length) return
        if (app.selectedAccount.length) return

        getAccountDetails(app.selectedAccountId)
    }, [app.selectedAccountId])

    if (!user.email) return renderLogout()
    return (
        <>
            <VerificationErrorDialog show={!verified} user={user} verifyEmail={verifyEmail} logout={logout} />
            <NoAccountsDialog show={!loading && error && !app.accountsList.length} logout={logout} />
            <Router>
                <div className="app-module">
                    <NavRoutes component={NavComponent} />
                    <NavRoutes component={ToolbarComponent} className="sidebar" />
                    <div className="app-module__container">
                        <AppModuleRoutes className="app-module__padding" />
                    </div>
                </div>
            </Router>
        </>
    )
}
