import {
    Button,
    ButtonIcon,
    ButtonText,
    Check,
    Confirm,
    Flexer,
    Heading,
    Icon,
    If,
    Input,
    Modal,
    Notification,
    NotificationText,
    Panel,
    ScrollView,
    Select,
    SelectOption,
    showToast,
    Table,
    Text,
    Tooltip,
    View,
} from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import arrayMove from 'array-move'
import EventEmitter from 'eventemitter3'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import sha1 from 'tiny-hashes/sha1'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    CHANNEL_MAPPER_HELP,
    CHANNEL_MAPPER_V1,
    COLOR,
    DEFAULT_TABLE,
    ERROR,
    PERFORMANCE_LEVELS,
    POSITION,
    PUB_SUB,
    TABLE,
    TOOLTIP,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { classNames, formatRulesForSending, logError, waitForState } from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { ChannelMapperV3Service } from '../../services/channel-mapper-v3.service'
import './channel-mapper.component.sass'
import Rule from './rule'

const eventEmitter = new EventEmitter()

declare global {
    interface Window {
        DRAGGED_ITEM: any
        DRAGGED_HEIGHT: number
        DRAGGED_INDEX: number
    }
}

const Block = (props: any) => {
    return <div style={{ height: 10, width: 30 + Math.random() * 10, background: COLOR.COLOR_GRAY_300 }} />
}

const Preview = (props: any) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const [notification, setNotification] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { accountId, projectId, rules } = props
    const [level, setLevel] = useState(PERFORMANCE_LEVELS.CHANNEL)
    const [performanceTableData, setPerformanceTableData] = useState<any>(DEFAULT_TABLE)
    const [performanceData, setPerformanceData] = useState([])
    const [channelLevel, setChannelLevel] = useState(null)
    const [sourceLevel, setSourceLevel] = useState(null)

    const fetchPerformanceTableData = async () => {
        setLoading(true)
        setError(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const channelLevelHash = channelLevel ? channelLevel.hash : null
            const sourceLevelHash = sourceLevel ? sourceLevel.hash : null
            const mappings = formatRulesForSending(rules)
            const result = await ChannelMapperV3Service.preview(
                token,
                accountId,
                {
                    project_id: projectId,
                    rules: mappings,
                },
                {
                    channel_level_hash: channelLevelHash,
                    source_level_hash: sourceLevelHash,
                }
            )
            const resultTable = result.map((r: string) => ({
                label: r,
                hash: sha1(r),
            }))

            const getHeading = (level: string) => {
                switch (level) {
                    case PERFORMANCE_LEVELS.CHANNEL:
                        return 'Channels'
                    case PERFORMANCE_LEVELS.SOURCE:
                        return channelLevel.label
                    case PERFORMANCE_LEVELS.CAMPAIGN:
                        return sourceLevel.label
                    default:
                        return ''
                }
            }

            const getTableHeading = (level: string) => {
                switch (level) {
                    case PERFORMANCE_LEVELS.CHANNEL:
                        return 'Channel'
                    case PERFORMANCE_LEVELS.SOURCE:
                        return 'Source'
                    case PERFORMANCE_LEVELS.CAMPAIGN:
                        return 'Campaign'
                    default:
                        return ''
                }
            }

            // Format the data for the table
            const tableData = {
                lastDataSet: level == PERFORMANCE_LEVELS.CAMPAIGN,
                heading: getHeading(level),
                headings: [
                    getTableHeading(level),
                    'Conversions',
                    'Revenue',
                    'Spend',
                    'ROAS',
                    'CPA',
                    'Conversion Delta',
                    '',
                ],
                align: [
                    null,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    null,
                ],
                formats: [null, null, null, null, null, null, null, TABLE.HIDDEN],
                data: [
                    ...resultTable.map((data: any, index: number) => [
                        data.label,
                        <Block />,
                        <Block />,
                        <Block />,
                        <Block />,
                        <Block />,
                        <Block />,
                        data.hash,
                    ]),
                ],
            }

            // Because the table is connected to everything else in the UI
            // We can't manage the data on a table-level
            // So here we organise the table's data pages to have the right orders
            switch (level) {
                case PERFORMANCE_LEVELS.CHANNEL:
                    setPerformanceTableData([tableData])
                    break
                case PERFORMANCE_LEVELS.SOURCE:
                    setPerformanceTableData([performanceTableData[0], tableData])
                    break
                case PERFORMANCE_LEVELS.CAMPAIGN:
                    setPerformanceTableData([performanceTableData[0], performanceTableData[1], tableData])
                    break
            }

            // Now set the data here because it's what we use to get the hash
            // So always keep this updated
            setPerformanceData([...resultTable])
            setLoading(false)
        } catch (e) {
            logError('fetchComparisonTableData', e)
            setError(ERROR.API.GENERAL)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (rules.length == 0) return

        fetchPerformanceTableData()
    }, [accountId, projectId, rules, level, channelLevel, sourceLevel])

    return (
        <Modal
            width="900px"
            icon="zap"
            title="Channel Mapper Preview"
            height="600px"
            showClose
            position={POSITION.CENTER}
            borderRadius={20}
            onDismiss={props.onDismiss}
            footer={null}
        >
            <View width="100%" height="100%">
                <ScrollView>
                    <View class="p-900">
                        <LoaderComponent loading={loading} />
                        <NotificationComponent notification={notification} error={error} />

                        <Table
                            pageSize={12}
                            data={performanceTableData}
                            onCleanup={(data) => {
                                switch (level) {
                                    case PERFORMANCE_LEVELS.SOURCE:
                                        setChannelLevel(null)
                                        setLevel(PERFORMANCE_LEVELS.CHANNEL)
                                        break
                                    case PERFORMANCE_LEVELS.CAMPAIGN:
                                        setSourceLevel(null)
                                        setLevel(PERFORMANCE_LEVELS.SOURCE)
                                        break
                                }
                            }}
                            onLoad={(dataIndex: number, row: any) => {
                                const hash = row[row.length - 1]
                                const index = performanceData.findIndex((data: any) => data.hash == hash)
                                const data = performanceData[index]

                                switch (level) {
                                    case PERFORMANCE_LEVELS.CHANNEL:
                                        setChannelLevel(data)
                                        setLevel(PERFORMANCE_LEVELS.SOURCE)
                                        break
                                    case PERFORMANCE_LEVELS.SOURCE:
                                        setSourceLevel(data)
                                        setLevel(PERFORMANCE_LEVELS.CAMPAIGN)
                                        break
                                }
                            }}
                        />
                    </View>
                </ScrollView>
            </View>
        </Modal>
    )
}

const Projects = (props: any) => {
    const app = useContext(AppContext)
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState('')
    const [confirm, setConfirm] = useState(false)

    const handleCheck = (project: any, check: boolean) => {
        if (check) {
            setSelected([...selected, project.id])
        } else {
            setSelected(selected.filter((projectId: string) => project.id != projectId))
        }
    }

    return (
        <>
            <Modal
                width="500px"
                icon="copy"
                height="80%"
                position={POSITION.CENTER}
                borderRadius={20}
                onDismiss={props.onDismiss}
                footer={
                    <View row justify="flex-end">
                        <Button light onClick={props.onDismiss}>
                            <ButtonText>Cancel</ButtonText>
                        </Button>
                        <Flexer />
                        <Button onClick={() => setConfirm(true)}>
                            <ButtonText>Okay</ButtonText>
                        </Button>
                    </View>
                }
            >
                <View width="100%" height="100%">
                    <ScrollView>
                        <View class="p-900">
                            <View class="mb-900">
                                <Input
                                    type="text"
                                    clear
                                    placeholder="Filter"
                                    value={filter}
                                    onChange={(value) => setFilter(value)}
                                    onBlur={(value) => setFilter(value)}
                                />
                            </View>

                            <Text class="cl-gray-500 t-uppercase mb-900 fw-700" small>
                                Please choose the projects you would like to copy the existing ruleset to
                            </Text>

                            {app.selectedAccount
                                .filter((project: any) => project.name.toLowerCase().includes(filter.toLowerCase()))
                                .filter((project: any) => project.id != props.projectId)
                                .map((project: any, index: number) => {
                                    const checked = selected.includes(project.id)

                                    return (
                                        <View class="mb-500" key={index}>
                                            <View row class="mb-200" justify="flex-start" key={index}>
                                                <Check
                                                    onChange={() => handleCheck(project, !checked)}
                                                    checked={checked}
                                                    disabled={false}
                                                />
                                                <Text class="cl-gray-700 t-uppercase ml-300 fw-700" small>
                                                    {project.name}
                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })}
                        </View>
                    </ScrollView>
                </View>
            </Modal>

            <Confirm
                visible={confirm}
                title="Are you sure?"
                description="This action will overwrite all rules in the selected projects."
                onConfirm={() => {
                    setConfirm(false)
                    props.onOkay(selected)
                }}
                onDismiss={() => {
                    setConfirm(false)
                }}
            />
        </>
    )
}

const Draggable = (props: any) => {
    const [over, setOver] = useState(false)
    const counter = useRef(0)

    const handleDragStart = (e) => {
        const { clientY, clientX } = e
        const draggedEl = e.target
        const { x, y, top, right, bottom, left, width, height } = draggedEl.getBoundingClientRect()
        const draggedElCopy = draggedEl.cloneNode(true)
        const div = document.createElement('div')
        const xOffset = clientY - left
        const yOffset = clientY - top

        window.DRAGGED_ITEM = draggedEl
        window.DRAGGED_HEIGHT = height
        window.DRAGGED_INDEX = props.index

        // Hide it for now
        draggedEl.style.opacity = 0.4

        // Set the container dimensions
        div.style.width = width + 'px'
        div.style.height = height + 'px'
        div.id = `dragged-${window.DRAGGED_ITEM.id}`

        // Create the element& append the cloned tag
        div.appendChild(draggedElCopy)

        // Append our new DIV to the doc, so we can use it
        document.body.appendChild(div)

        // Set the drag element
        e.dataTransfer.setDragImage(div, xOffset, yOffset)

        // Send this to bottom on the event loop
        // So that the draggable element is already set
        // But so we can hide the one added to the page
        setTimeout(() => (div.style.visibility = 'hidden'), 0)
    }

    const handleDrop = (e) => {
        props.onDropped()
        setOver(false)
        counter.current = 0
    }

    const handleDragEnd = (e) => {
        const draggedEl = e.target
        draggedEl.style.opacity = 1
        setOver(false)
        counter.current = 0
    }

    const handleDrag = (e) => {
        if (e.clientY < 100) window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleDragOver = (e) => {
        e.preventDefault()

        const draggedEl = e.currentTarget

        // Ignore our own dragOver
        if (window.DRAGGED_ITEM.id == draggedEl.id) return

        // const { clientY, clientX } = e
        // const { x, y, top, right, bottom, left, width, height } = draggedEl.getBoundingClientRect()
        // setOver(clientY > top && clientY < top + height / 2)
        // setUnder(clientY > top + height / 2 && clientY < top + height)
        setOver(true)
    }

    const handleDragEnter = (e) => {
        e.preventDefault()
        counter.current = counter.current + 1
    }

    const handleDragLeave = (e) => {
        counter.current = counter.current - 1

        if (counter.current === 0) {
            setOver(false)
        }
    }

    // If it's disabled, then simply return the
    // children (disabled the drag and drop)
    if (props.disabled) return props.children

    return (
        <div
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrag={handleDrag}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            draggable
            id={`draggable-${props.index}`}
            className="channel-mapper-component__draggable"
        >
            <View>{props.children}</View>
            <If if={over}>
                <View
                    row
                    justify="flex-start"
                    class="channel-mapper-component__draggable-spacer"
                    height={window.DRAGGED_HEIGHT}
                />
            </If>
        </div>
    )
}

export const InputRow = (props: any) => {
    const [level, setLevel] = useState(-1)
    const [operator, setOperator] = useState(-1)
    const [value, setValue] = useState('')
    const [scopedValue, setScopedValue] = useState('')

    const handleOperatorUpdate = (index) => {
        setLevel(index)

        props.onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[level],
            operator: CHANNEL_MAPPER_V1.OPERATORS[index],
            value,
        })
    }

    const handleLevelUpdate = (index) => {
        setLevel(index)

        props.onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[index],
            operator: CHANNEL_MAPPER_V1.OPERATORS[operator],
            value,
        })
    }

    const handleValueUpdate = (value) => {
        if (!value) return
        if (props.input.value == value) return

        setValue(value)
        setScopedValue(value)

        props.onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[level],
            operator: CHANNEL_MAPPER_V1.OPERATORS[operator],
            value,
        })
    }

    const handleDelete = () => {
        if (confirm('Are you sure?')) props.onDelete()
    }

    useEffect(() => {
        const { operator, value, level } = props.input

        setOperator(CHANNEL_MAPPER_V1.OPERATORS.findIndex((o: string) => o == operator))
        setLevel(CHANNEL_MAPPER_V1.LEVELS.findIndex((l: string) => l == level))
        setValue(value)
        setScopedValue(value)
    }, [props.input])

    return (
        <View row width="100%" class="mb-100">
            <View flex={1} class="mr-100">
                <Select
                    backgroundColor={COLOR.COLOR_GRAY_100}
                    small
                    width="100%"
                    placeholder="None selected"
                    selected={level}
                    onSelect={handleLevelUpdate}
                >
                    {CHANNEL_MAPPER_V1.LEVELS.map((type: any, index: number) => (
                        <SelectOption key={index}>{type}</SelectOption>
                    ))}
                </Select>
            </View>

            <View flex={1} class="mr-100">
                <Select
                    backgroundColor={COLOR.COLOR_GRAY_100}
                    small
                    width="100%"
                    placeholder="None selected"
                    selected={operator}
                    onSelect={handleOperatorUpdate}
                >
                    {CHANNEL_MAPPER_V1.OPERATORS.map((operator: any, index: number) => (
                        <SelectOption key={index}>
                            {operator.split('_').join(' ').replace('ignore case', '(ignore case)')}
                        </SelectOption>
                    ))}
                </Select>
            </View>

            <View flex={2}>
                <Input
                    small
                    type="text"
                    placeholder="Name"
                    value={scopedValue}
                    onChange={(value: string) => setScopedValue(value)}
                    onBlur={handleValueUpdate}
                />
            </View>

            <Button small onClick={handleDelete} class="ml-500">
                <ButtonIcon icon="minus" />
            </Button>
            <Button small onClick={props.onAdd} class="ml-100">
                <ButtonIcon icon="plus" />
            </Button>
        </View>
    )
}

interface IChannelMapperComponent {
    children?: any
}

export const ChannelMapperComponent: FunctionComponent = (props: IChannelMapperComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const [channel, setChannel] = useState<any>({ rules: [] })
    const [project, setProject] = useState(-1)
    const [projects, setProjects] = useState(false)
    const [preview, setPreview] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [validation, setValidation] = useState(false)
    const [hasMapping, setHasMapping] = useState(false)
    const [canMap, setCanMap] = useState(false)
    const [filter, setFilter] = useState('')
    const disablePositionEditing = filter != ''
    const [validations, setValidations] = useState([])
    const [orderingTimestamp, setOrderingTimestamp] = useState(new Date().getTime())

    const addRuleIndex = (channel) => {
        return {
            ...channel,
            rules: channel.rules.map((rule: any, index: number) => ({
                ...rule,
                index,
                originalPosition: index,
                displayName: rule.display_name,
            })),
        }
    }

    const validateRules = (rules: any[]) => {
        let validation = true

        // Validate any null or blank values
        rules.map((rule: any) => {
            if (rule.level === undefined || rule.displayName === null) validation = false

            rule.conditions.map((input: any) => {
                if (
                    input.level === undefined ||
                    input.operator === null ||
                    input.value === undefined ||
                    input.value === null
                )
                    validation = false
            })
        })

        // If it failed then return false
        if (!validation) {
            return false
        } else {
            return true
        }
    }

    const verifyChannelMapperRules = async (
        channelRules: any[],
        successCallback?: () => void,
        validationConfirm: boolean = true
    ) => {
        if (validateRules(channelRules)) {
            setLoading(true)
            setError(null)
            setValidations([])
            if (validationConfirm) setValidation(false)

            try {
                const rules = formatRulesForSending(channelRules)
                const token = await getAccessTokenSilently()
                const result = await ChannelMapperV3Service.verify(token, accountId, {
                    project_id: projectId,
                    rules,
                })

                if (result == 0) {
                    if (validationConfirm) setValidation(false)
                    setValidations([])
                    successCallback()
                } else {
                    if (validationConfirm) setValidation(true)
                    setValidations(result)
                }

                setLoading(false)
            } catch (e) {
                logError(e)
                setError(ERROR.API.GENERAL)
                setLoading(false)
            }
        } else {
            if (validationConfirm) setValidation(true)
        }
    }

    const handleAddBottom = async () => {
        if (disablePositionEditing) return showToast('Disabled when filtering')

        setValidations([])
        setHasMapping(true)
        setCanMap(true)
        setError(null)
        setChannel({
            ...channel,
            rules: [
                ...channel.rules,
                {
                    displayName: '',
                    display_name: '',
                    conditions: [
                        { level: CHANNEL_MAPPER_V1.LEVELS[0], operator: CHANNEL_MAPPER_V1.OPERATORS[0], value: '' },
                    ],
                    level: 'channel',
                    position: channel.rules.length,
                    originalPosition: channel.rules.length,
                },
            ],
        })
    }

    const handleAdd = async (index: number) => {
        if (disablePositionEditing) return showToast('Disabled when filtering')

        const newIndex = index + 1
        const newRule = {
            displayName: '',
            display_name: '',
            conditions: [{ level: CHANNEL_MAPPER_V1.LEVELS[0], operator: CHANNEL_MAPPER_V1.OPERATORS[0], value: '' }],
            level: 'channel',
        }

        setValidations([])
        setChannel({
            ...channel,
            rules: channel.rules.insert(newIndex, newRule),
        })
    }

    const handleDuplicate = async (index: number) => {
        const newIndex = index + 1
        const newRule = channel.rules[index]
        const rules = [...channel.rules].insert(newIndex, newRule)

        setValidations([])
        setChannel({ ...channel, rules })

        waitForState(() => {
            eventEmitter.emit(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ONE, { position: newIndex })
        }, 500)
    }

    const handleUpdate = async (rule1: any, index1: number) => {
        setChannel({
            ...channel,
            rules: channel.rules.map((rule2: any, index2: number) => {
                if (index1 == index2) {
                    return {
                        ...rule2,
                        ...rule1,
                    }
                } else {
                    return rule2
                }
            }),
        })
    }

    const handleDelete = async (index1: number) => {
        waitForState(() => {
            setValidations([])
            setChannel({
                ...channel,
                rules: [...channel.rules.filter((_: any, index2: number) => index1 != index2)],
            })
        })
    }

    const handleRuleOrderUpdate = (fromIndex, toIndex) => {
        if (disablePositionEditing) return showToast('Disabled when filtering')

        // This forces a render on all validation rules
        // so that the rule ordinal references will update correctly
        setOrderingTimestamp(new Date().getTime())
        setChannel({
            ...channel,
            rules: arrayMove(channel.rules, fromIndex, fromIndex > toIndex ? toIndex + 1 : toIndex),
        })
    }

    const fetchChannelMapper = async () => {
        setLoading(true)
        setError(null)
        setNotification(null)
        setChannel({ rules: [] })
        setHasMapping(false)
        setCanMap(false)

        try {
            const token = await getAccessTokenSilently()
            const result = await ChannelMapperV3Service.get(token, accountId, projectId)
            const channel = addRuleIndex(result)

            setLoading(false)

            // Add original position & index to the rule so we can keep validations mapped correctly
            // And also handle positional ordinals
            setChannel(channel)
            setHasMapping(true)
            setCanMap(true)

            // Immediately run a validation for the user
            verifyChannelMapperRules(channel.rules, () => {}, false)
        } catch (e) {
            logError('fetchChannelMapper')
            if (e == 400) {
                setError(null)
                setHasMapping(false)
                setCanMap(true)
            } else if (e == 404) {
                setHasMapping(false)
                setCanMap(true)
            } else {
                setNotification(null)
                setError(ERROR.API.GENERAL)
                setCanMap(false)
            }

            setLoading(false)
        }
    }

    const fromProjectId = app.selectedAccount.sort((a: any, b: any) => a.name - b.name)?.[project]?.id

    const fetchChannelMapperCopy = async () => {
        setLoading(true)
        setError(null)
        setNotification(null)
        setHasMapping(false)
        setCanMap(false)

        try {
            const token = await getAccessTokenSilently()
            const result = await ChannelMapperV3Service.getCopy(token, accountId, projectId, {
                from_project_id: fromProjectId,
            })
            setLoading(false)
            setChannel(addRuleIndex(result))
            setHasMapping(true)
            setCanMap(true)
        } catch (e) {
            logError('fetchChannelMapperCopy')
            if (e == 400) {
                setError(null)
                setHasMapping(false)
                setCanMap(true)
            } else if (e == 404) {
                setHasMapping(false)
                setCanMap(true)
            } else {
                setNotification(null)
                setError(ERROR.API.GENERAL)
                setCanMap(false)
            }

            setLoading(false)
        }
    }

    const handleChannelMapperCopyToAll = async (projectIds: string[]) => {
        setLoading(true)
        setError(null)

        try {
            const { rules } = channel
            const mappings = projectIds.map((projectId: string) => ({
                project_id: projectId,
                rules: formatRulesForSending(rules),
            }))
            const token = await getAccessTokenSilently()
            const result = await ChannelMapperV3Service.put(token, accountId, mappings)
            setLoading(false)
        } catch (e) {
            logError(e)
            setError(ERROR.API.GENERAL)
            setLoading(false)
        }
    }

    const handleChannelMapperUpdate = async () => {
        setLoading(true)
        setError(null)

        if (!validateRules(channel.rules)) return setLoading(false)

        try {
            const token = await getAccessTokenSilently()
            const rules = formatRulesForSending(channel.rules)
            let result = null

            // If there are no rules, then delete the mapping
            // Otherwise update
            if (rules.length == 0) {
                result = await ChannelMapperV3Service.delete(token, accountId, projectId)
            } else {
                result = await ChannelMapperV3Service.put(token, accountId, [
                    {
                        project_id: projectId,
                        rules,
                    },
                ])
            }
            setLoading(false)
            setHasMapping(rules.length != 0)
            setCanMap(true)
        } catch (e) {
            logError(e)
            setError(ERROR.API.GENERAL)
            setLoading(false)
        }
    }

    const handleNewChannelMapper = () => {
        setHasMapping(true)
        setCanMap(true)
        setError(null)
        setChannel({
            rules: [
                {
                    displayName: '',
                    display_name: '',
                    conditions: [
                        { level: CHANNEL_MAPPER_V1.LEVELS[0], operator: CHANNEL_MAPPER_V1.OPERATORS[0], value: '' },
                    ],
                    level: 'channel',
                    position: 0,
                    originalPosition: 0,
                },
            ],
        })
    }

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (project == -1) return

        fetchChannelMapperCopy()
    }, [project])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return

        fetchChannelMapper()
    }, [accountId, projectId])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return

        if (!StorageService.getStorage(CHANNEL_MAPPER_HELP)) {
            StorageService.setStorage(CHANNEL_MAPPER_HELP, 'true')
            // Set up modal true here (in future)
        }
    }, [accountId, projectId])

    return (
        <>
            <LoaderComponent loading={loading} />
            <NotificationComponent notification={notification} error={error} />

            <Confirm
                visible={validation}
                title="Invalid rules"
                description="We've found some problems with your rules, are you sure you want to continue?"
                onDismiss={() => setValidation(false)}
                onConfirm={() => {
                    setValidation(false)
                    handleChannelMapperUpdate()
                }}
            />

            <Confirm
                visible={confirm}
                title="Are you sure?"
                description="Are you sure you want to save?"
                onDismiss={() => setConfirm(false)}
                onConfirm={() => {
                    setConfirm(false)
                    verifyChannelMapperRules(channel.rules, () => {
                        handleChannelMapperUpdate()
                    })
                }}
            />

            <If if={preview}>
                <Preview
                    accountId={accountId}
                    projectId={projectId}
                    rules={channel.rules}
                    onDismiss={() => setPreview(false)}
                />
            </If>

            <If if={projects}>
                <Projects
                    projectId={projectId}
                    validateRules={validateRules}
                    formatRulesForSending={formatRulesForSending}
                    rules={channel.rules}
                    onDismiss={() => setProjects(false)}
                    onOkay={(projectIds) => {
                        setProjects(false)
                        handleChannelMapperCopyToAll(projectIds)
                    }}
                />
            </If>

            <View row justify="flex-end" class="mb-900">
                <Heading large>Channel Mapper</Heading>
                <Flexer />
            </View>

            {/* If there are channel rules */}
            <If if={hasMapping}>
                <Notification info>
                    <NotificationText>
                        Define rules that map your channels, sources, and campaigns to appropriate display names.
                        Re-order channel mappings by dragging & dropping.
                    </NotificationText>
                </Notification>

                <If if={validations.length != 0}>
                    <View class="mt-100">
                        <Notification danger class="mb-100">
                            <NotificationText>
                                We found some problems with your rules & have highlighted them below.
                            </NotificationText>
                        </Notification>
                    </View>
                </If>

                <Panel width="100%" class="mb-900 mt-900">
                    <View>
                        <Input
                            type="text"
                            clear
                            label="Filter channel rules"
                            placeholder="Enter text"
                            value={filter}
                            onChange={(value) => setFilter(value)}
                            onBlur={(value) => null}
                        />
                    </View>
                </Panel>

                <View row width="100%" justify="flex-end" class="mb-900">
                    <Text
                        class="fw-600 cl-electric buttonize mr-900"
                        small
                        onClick={() => eventEmitter.emit(PUB_SUB.CHANNEL_MAPPER_V1.COLLAPSE_ALL, true)}
                    >
                        Collapse all channels
                    </Text>

                    <Text
                        class="fw-600 cl-electric buttonize"
                        small
                        onClick={() => eventEmitter.emit(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ALL, true)}
                    >
                        Expand all channels
                    </Text>
                </View>

                {channel.rules.map((rule: any, index: number) => {
                    if (!rule.display_name.toLowerCase().includes(filter.toLowerCase())) return null

                    return (
                        <Draggable
                            disabled={disablePositionEditing}
                            onDropped={() => handleRuleOrderUpdate(window.DRAGGED_INDEX, index)}
                            index={index}
                            key={rule + index}
                        >
                            <Rule
                                validations={validations}
                                rule={rule}
                                index={rule.index}
                                originalPosition={rule.originalPosition}
                                orderingTimestamp={orderingTimestamp}
                                position={index}
                                onDelete={() => handleDelete(index)}
                                onAdd={() => handleAdd(index)}
                                onDuplicate={() => handleDuplicate(index)}
                                onUpdate={(rule: any) => handleUpdate(rule, index)}
                                onUp={() => handleRuleOrderUpdate(index, index - 2)}
                                onDown={() => handleRuleOrderUpdate(index, index + 1)}
                                getOriginalPositionActualPositionIndex={(originalPosition: number) =>
                                    channel.rules.findIndex((r: any) => r.originalPosition == originalPosition)
                                }
                            />
                        </Draggable>
                    )
                })}

                <View row justify="flex-end" class="mt-900">
                    <Button onClick={() => setProjects(true)} class="mr-500">
                        <ButtonIcon icon="copy" />
                        <ButtonText>Copy to projects</ButtonText>
                    </Button>
                    <Button onClick={handleAddBottom} class="mr-500">
                        <ButtonIcon icon="save" />
                        <ButtonText>Add rule</ButtonText>
                    </Button>
                    <Button onClick={() => setPreview(true)} class="mr-500">
                        <ButtonIcon icon="zap" />
                        <ButtonText>Preview</ButtonText>
                    </Button>
                    <Button onClick={() => setConfirm(true)}>
                        <ButtonIcon icon="save" />
                        <ButtonText>Save</ButtonText>
                    </Button>
                </View>
            </If>

            {/* If there are NO channel rules */}
            <If if={!hasMapping}>
                <Panel width="100%">
                    <View flex="none" row>
                        <View flex={1} class="ml-100 mr-100">
                            <Select
                                width="100%"
                                placeholder="Import from selected project"
                                selected={project}
                                onSelect={(index: number) => setProject(index)}
                            >
                                {app.selectedAccount.map((project: any, index: number) => (
                                    <SelectOption key={index}>{project.name}</SelectOption>
                                ))}
                            </Select>
                        </View>
                        {/*
                        Recently removed, keeping for reference
                        <Button onClick={fetchChannelMapperCopy}>
                            <ButtonIcon icon="check" />
                            <ButtonText>Import from selected project</ButtonText>
                        </Button>

                        */}
                        <Text class="ml-500 mr-500">or</Text>
                        <Button onClick={handleNewChannelMapper}>
                            <ButtonIcon icon="plus" />
                            <ButtonText>Create new mapping</ButtonText>
                        </Button>
                    </View>
                </Panel>
            </If>
        </>
    )
}
