import {
    Button,
    ButtonIcon,
    Carousel,
    Filter,
    Flexer,
    Heading,
    If,
    Journey,
    Label,
    Metric,
    Notification,
    NotificationText,
    Option,
    Options,
    Panel,
    Select,
    SelectOption,
    Slider,
    Text,
    Toggle,
    View,
} from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import queryString from 'query-string'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { NoReportComponent } from '../../../../components/no-report/no-report.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    ATTRIBUTION_MODELS,
    COLOR,
    COUNTS,
    ERROR,
    FILTER,
    INSIGHTS,
    JOURNEY_MULTIPLIER,
    LEVELS,
    NEW_RETURNING,
    NUMERAL,
    STORAGE,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import {
    findIndexHash,
    formatDateForAPI,
    getCacheKey,
    getCachedFilterRules,
    getFilterOption,
    getFilterOptionHash,
    getPropertyNameAtIndex,
    isValidReport,
    logError,
    sanitizeLocale,
    sanitizeNumber,
    setLocale,
    validateFilterRows,
} from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { CustomerJourneyService, FilterOption } from '../../services/customer-journey-v2.service'
import { QuickInsightsComponent } from '../insights/insights.component'
import './customer-journey.component.sass'

interface ICustomerJourneyComponent {
    children?: any
}

export const CustomerJourneyComponent: FunctionComponent = (props: ICustomerJourneyComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorNoReport, setErrorNoReport] = useState(null)
    const [notification, setNotification] = useState(null)
    const [metrics, setMetrics] = useState<any>({})
    const [level, setLevel] = useState<any>(LEVELS.CHANNEL)
    const [model, setModel] = useState<any>(ATTRIBUTION_MODELS.REGULAR)
    const [customerType, setCustomerType] = useState<any>(NEW_RETURNING.ALL)
    const [slider, setSlider] = useState([0, 1])
    const [journeys, setJourneys] = useState<any>([])
    const { accountId, projectId, reportId } = useParams()
    const { search } = useLocation()
    const { any_hash, influencer_hash, introducer_hash, closer_hash } = queryString.parse(search)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(10)
    const [insightsModal, setInsightsModal] = useState(false)
    const [loadingFilters, setLoadingFilters] = useState(null)
    const [loadingTotals, setLoadingTotals] = useState(null)
    const [loadingJourneys, setLoadingJourneys] = useState(null)
    const [advanced, setAdvanced] = useState(false)
    const defaultFilterRules = [{ option: 0, comparison: FILTER.COMPARISONS[0], value: '', logic: '' }]
    const [filter, setFilter] = useState(defaultFilterRules)
    const [filterAnies, setFilterAnies] = useState([])
    const [filterIntroducers, setFilterIntroducers] = useState([])
    const [filterInfluencers, setFilterInfluencers] = useState([])
    const [filterClosers, setFilterClosers] = useState([])
    const [aniesSelected, setAniesSelected] = useState([])
    const [introducersSelected, setIntroducersSelected] = useState([])
    const [influencersSelected, setInfluencersSelected] = useState([])
    const [closersSelected, setClosersSelected] = useState([])
    const [ummSelected, setUmmSelected] = useState(0)
    const [aniesSelectedCache, setAniesSelectedCache] = useState([])
    const [introducersSelectedCache, setIntroducersSelectedCache] = useState([])
    const [influencersSelectedCache, setInfluencersSelectedCache] = useState([])
    const [closersSelectedCache, setClosersSelectedCache] = useState([])
    const [ummSelectedCache, setUmmSelectedCache] = useState(0)
    const filterOptions = [
        { label: 'Any', value: 'any' },
        { label: 'Introducer', value: 'introducer' },
        { label: 'Influencer', value: 'influencer' },
        { label: 'Closer', value: 'closer' },
    ]

    const handleToggleAdvanced = () => {
        const defaultFilterGroup = [{ logic: '', rows: [] }]
        if (advanced) {
            if (filter.length == 1) {
                if (filter[0].value != '') handleFilterChange(defaultFilterGroup)
            } else if (filter.length > 1) {
                handleFilterChange(defaultFilterGroup)
            }
        }
        setAdvanced(!advanced)
    }

    const resetSelectFilters = () => {
        // Don't reset anything here if it's on the initial state
        // Only causes a unnecessary render
        if (
            aniesSelected.length == 0 &&
            influencersSelected.length == 0 &&
            introducersSelected.length == 0 &&
            closersSelected.length == 0 &&
            aniesSelectedCache.length == 0 &&
            influencersSelectedCache.length == 0 &&
            introducersSelectedCache.length == 0 &&
            closersSelectedCache.length == 0
        )
            return

        setAniesSelected([])
        setInfluencersSelected([])
        setIntroducersSelected([])
        setClosersSelected([])
        setAniesSelectedCache([])
        setInfluencersSelectedCache([])
        setIntroducersSelectedCache([])
        setClosersSelectedCache([])
    }

    const resetFilters = () => {
        resetSelectFilters()
        setFilter(defaultFilterRules)
    }

    const handleError = (e) => {
        if (e == 204 || e == 400) {
            setLoadingTotals(false)
            setLoadingJourneys(false)
            setNotification(null)
            setError(null)
            setErrorNoReport(ERROR.API.NO_REPORT_DATA)
        } else {
            setLoadingTotals(false)
            setLoadingJourneys(false)
            setNotification(null)
            setError(ERROR.API.GENERAL)
            setErrorNoReport(null)
        }
    }

    const handleFilterUpdate = () => {
        setAniesSelected(aniesSelectedCache)
        setInfluencersSelected(influencersSelectedCache)
        setIntroducersSelected(introducersSelectedCache)
        setClosersSelected(closersSelectedCache)
        setUmmSelected(ummSelectedCache)
        setPage(0)
    }

    const handleFilterChange = (groups: any) => {
        const rules = groups[0].rows

        if (rules.length == 0) {
            StorageService.deleteStorage(getCacheKey(reportId, STORAGE.CUSTOMER_JOURNEY))
            resetSelectFilters()
            setFilter([])
            setPage(0)
            setUmmSelected(ummSelectedCache)
        } else {
            if (validateFilterRows(rules)) {
                StorageService.setStorage(getCacheKey(reportId, STORAGE.CUSTOMER_JOURNEY), JSON.stringify(rules))
                resetSelectFilters()
                setFilter(rules)
                setPage(0)
                setUmmSelected(ummSelectedCache)
            }
        }
    }

    const getUmmValue = () => {
        switch (ummSelected) {
            case 0:
                return 'include'
            case 1:
                return 'exclude'
            case 2:
                return 'only'
            default:
                return 'include'
        }
    }

    const getFilters = (): FilterOption[] => {
        let options: FilterOption[] = []
        const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.CUSTOMER_JOURNEY)

        // Always used cache values as source of truth
        if (cachedFilterRules) {
            if (cachedFilterRules.length > 0) {
                options = cachedFilterRules.map((filterRow: any) => ({
                    field: filterOptions[filterRow.option].value,
                    operator: filterRow.comparison,
                    value: filterRow.value,
                }))
            }
        } else {
            const anies = getFilterOptionHash(aniesSelected, filterAnies)
            const introducers = getFilterOptionHash(introducersSelected, filterIntroducers)
            const influencers = getFilterOptionHash(influencersSelected, filterInfluencers)
            const closers = getFilterOptionHash(closersSelected, filterClosers)

            if (anies != '') options.push(getFilterOption('any', anies))
            if (introducers != '') options.push(getFilterOption('introducer', introducers))
            if (influencers != '') options.push(getFilterOption('influencer', influencers))
            if (closers != '') options.push(getFilterOption('closer', closers))
        }

        return options
    }

    const createSelectData = (data: any) => {
        let filters = []

        switch (level) {
            case LEVELS.CHANNEL:
                filters = data
                break

            case LEVELS.SOURCE:
                data.map((d1: any) => {
                    // Channel
                    filters.push({
                        hash: '',
                        label: d1.key,
                        divider: true,
                        superDivider: false,
                    })

                    d1.values.map((d2: any) => {
                        // Source
                        filters.push({
                            hash: d2.hash,
                            label: d2.source,
                            divider: false,
                            superDivider: false,
                        })
                    })
                })
                break

            case LEVELS.CAMPAIGN:
                data.map((d1: any) => {
                    // Channel
                    filters.push({
                        hash: '',
                        label: d1.key,
                        divider: true,
                        superDivider: true,
                    })

                    d1.values.map((d2: any) => {
                        // Source
                        filters.push({
                            hash: '',
                            label: d2.key,
                            divider: true,
                            superDivider: false,
                        })

                        d2.values.map((d3: any) => {
                            // Campaign
                            filters.push({
                                hash: d3.hash,
                                label: `${d3.campaign}`,
                                divider: false,
                                superDivider: false,
                            })
                        })
                    })
                })
                break
        }

        return filters
    }

    const isEnd = () => {
        const max = metrics.journeys
        const end = page * count + count
        if (end > max) return true
        return false
    }

    const handleJourneysBack = () => {
        if (page - 1 == -1) return
        setPage(page - 1)
    }

    const handleJourneysNext = () => {
        if (isEnd()) return
        setPage(page + 1)
    }

    const fetchFilters = async () => {
        setLoadingFilters(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        resetFilters()

        try {
            const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.CUSTOMER_JOURNEY)
            const token = await getAccessTokenSilently()
            const getLevel = () => {
                switch (level) {
                    case LEVELS.CHANNEL:
                        return '/channel'
                    case LEVELS.SOURCE:
                        return '/source'
                    case LEVELS.CAMPAIGN:
                        return '/campaign'
                    default:
                        return ''
                }
            }
            const result = await CustomerJourneyService.getFilters(token, accountId, projectId, reportId, getLevel(), {
                attribution_model: model.toLowerCase(),
            })
            const filters = createSelectData(result)

            if (cachedFilterRules) {
                setFilter(cachedFilterRules)
                setAdvanced(true)
            }

            setSlider([0, 1])
            setPage(0)

            setFilterAnies(filters)
            setFilterIntroducers(filters)
            setFilterInfluencers(filters)
            setFilterClosers(filters)

            setLoadingFilters(false)
        } catch (e) {
            logError('fetchFilters', e)
            handleError(e)
        }
    }

    const fetchJourneys = async () => {
        setLoadingJourneys(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await CustomerJourneyService.getData(
                token,
                accountId,
                projectId,
                reportId,
                {
                    attribution_model: model.toLowerCase(),
                    count,
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                    journey_length_max: getSliderValues().max == 20 ? 1000 : getSliderValues().max,
                    journey_length_min: getSliderValues().min,
                    level: level.toLowerCase(),
                    offset: count * page,
                    umm_filter: getUmmValue(),
                },
                getFilters()
            )

            const formatTouchpoints = (touchpoint: any) => {
                const labels = touchpoint.label.split('~#~')
                const primaryLabel = labels[0]
                const secondaryLabels = labels.slice(1)

                if (secondaryLabels.length == 0) {
                    return [primaryLabel, sanitizeNumber(touchpoint.attribution) / 100, touchpoint.count || 1]
                } else {
                    return [
                        primaryLabel,
                        sanitizeNumber(touchpoint.attribution) / 100,
                        touchpoint.count || 1,
                        secondaryLabels,
                    ]
                }
            }
            const sortByPosition = (a, b) => a.position - b.position

            const convertTotalsToFormattedString = (journey: {
                totals_umm_tooltip: Record<string, number>
            }): React.ReactNode => {
                const totalsUmmTooltip = journey.totals_umm_tooltip

                const formattedString = Object.entries(totalsUmmTooltip)
                    .map(([key, value]) => `${key}: ${value}<br />`)
                    .join('')

                return <span dangerouslySetInnerHTML={{ __html: formattedString }} />
            }

            setJourneys(
                result.map((journey: any) => {
                    const totalsUMMTooltip =
                        Object.keys(journey.totals_umm_tooltip).length > 0
                            ? convertTotalsToFormattedString(journey)
                            : null

                    return {
                        position: journey.position,
                        attributes: [
                            ['Conversions', sanitizeNumber(journey.totals.conversions), NUMERAL.FORMATS.NUMERIC],
                            ['Journey Length', sanitizeNumber(journey.totals.journeys), NUMERAL.FORMATS.NUMERIC],
                            ['Revenue', sanitizeNumber(journey.totals.revenue), NUMERAL.FORMATS.CURRENCY],
                            [
                                'UMM Attribution',
                                sanitizeNumber(journey.totals.umm_attribution) / 100,
                                NUMERAL.FORMATS.PERCENT,
                                totalsUMMTooltip,
                            ],
                        ],
                        closer: journey.touchpoints.closer.map(formatTouchpoints).sort(sortByPosition),
                        influencer: journey.touchpoints.influencer.map(formatTouchpoints).sort(sortByPosition),
                        introducer: journey.touchpoints.introducer.map(formatTouchpoints).sort(sortByPosition),
                    }
                })
            )
            setLoadingJourneys(false)
        } catch (e) {
            logError('fetchJourneys', e)
            handleError(e)
        }
    }

    const fetchTotals = async () => {
        setLoadingTotals(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await CustomerJourneyService.getTotals(
                token,
                accountId,
                projectId,
                reportId,
                {
                    attribution_model: model.toLowerCase(),
                    date_start: formatDateForAPI(app.fromDate),
                    date_end: formatDateForAPI(app.toDate),
                    journey_length_max: getSliderValues().max == 20 ? 1000 : getSliderValues().max,
                    journey_length_min: getSliderValues().min,
                    level: level.toLowerCase(),
                    umm_filter: getUmmValue(),
                },
                getFilters()
            )

            setMetrics({
                conversions: sanitizeNumber(result.conversions),
                journeys: sanitizeNumber(result.journeys),
                revenue: sanitizeNumber(result.revenue),
            })
            setLoadingTotals(false)
        } catch (e) {
            logError('fetchTotals', e)
            handleError(e)
        }
    }

    const getSliderValues = () => {
        const min = Math.ceil(slider[0] * JOURNEY_MULTIPLIER)
        const max = Math.ceil(slider[1] * JOURNEY_MULTIPLIER)
        return {
            min: min == 0 ? 1 : min,
            max,
        }
    }

    const handleSliderChange = (values) => {
        setSlider([...values])
        setPage(0)
    }

    const getSliderTextValues: any = () => {
        const min = getSliderValues().min
        const max = getSliderValues().max

        return {
            min,
            max: max == JOURNEY_MULTIPLIER ? max + '+' : max,
        }
    }

    const getTooltipValue = (val: number) => {
        const value: number = Math.ceil(val * JOURNEY_MULTIPLIER)
        return value == 20 ? '20+' : value == 0 ? 1 : value
    }

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchFilters()
    }, [level, model, customerType, accountId, projectId, reportId])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        const filterAnyIndex = findIndexHash(filterAnies, any_hash)
        const filterInfluencerIndex = findIndexHash(filterInfluencers, influencer_hash)
        const filterIntroducerIndex = findIndexHash(filterIntroducers, introducer_hash)
        const filterCloserIndex = findIndexHash(filterClosers, closer_hash)

        if (filterAnyIndex != null) {
            setAniesSelected([filterAnyIndex])
            setAniesSelectedCache([filterAnyIndex])
        }

        if (filterInfluencerIndex != null) {
            setInfluencersSelected([filterInfluencerIndex])
            setInfluencersSelectedCache([filterInfluencerIndex])
        }

        if (filterIntroducerIndex != null) {
            setIntroducersSelected([filterIntroducerIndex])
            setIntroducersSelectedCache([filterIntroducerIndex])
        }

        if (filterCloserIndex != null) {
            setClosersSelected([filterCloserIndex])
            setClosersSelectedCache([filterCloserIndex])
        }
    }, [
        accountId,
        projectId,
        reportId,
        filterAnies,
        filterInfluencers,
        filterIntroducers,
        filterClosers,
        closer_hash,
        any_hash,
        influencer_hash,
        introducer_hash,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchJourneys()
    }, [
        page,
        slider,
        count,
        level,
        model,
        customerType,
        accountId,
        projectId,
        reportId,
        app.fromDate,
        app.toDate,
        filter,
        aniesSelected,
        influencersSelected,
        introducersSelected,
        closersSelected,
        ummSelected,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId) return

        fetchTotals()
    }, [
        slider,
        level,
        model,
        customerType,
        accountId,
        projectId,
        reportId,
        app.fromDate,
        app.toDate,
        filter,
        aniesSelected,
        influencersSelected,
        introducersSelected,
        closersSelected,
        ummSelected,
    ])

    useEffect(() => {
        const currency = app.selectedAccount.find((project: any) => project.id == projectId)?.currency ?? 'EUR'
        setLocale(sanitizeLocale(currency))
    }, [app])

    const start = page * count
    const end = page * count + count
    const max = metrics.journeys

    return (
        <>
            <If if={insightsModal}>
                <QuickInsightsComponent
                    accountId={accountId}
                    projectId={projectId}
                    reportId={reportId}
                    onDismiss={() => setInsightsModal(false)}
                    type={INSIGHTS.TYPES.CUSTOMER_JOURNEY}
                />
            </If>

            <NotificationComponent notification={notification} error={error} />
            <NoReportComponent visible={errorNoReport} />

            <View class={!!errorNoReport ? 'blur' : null}>
                <If if={!isValidReport(accountId, projectId, reportId)}>
                    <Notification danger>
                        <NotificationText>Please select a valid report</NotificationText>
                    </Notification>
                </If>

                <View row justify="flex-start">
                    <Heading large class="mr-900">
                        Customer Journey report
                    </Heading>
                    <Flexer />
                    <Text class="mr-300 cl-gray-600">Toggle advanced filter</Text>
                    <Toggle onChange={handleToggleAdvanced} on={advanced} disabled={false} />
                    <Options
                        class="mr-500 ml-900"
                        onOptionSelected={(index: number) => {
                            resetFilters()
                            setModel(getPropertyNameAtIndex(ATTRIBUTION_MODELS, index))
                        }}
                    >
                        {Object.keys(ATTRIBUTION_MODELS).map((property: string, index: number) => (
                            <Option key={index} selected={property == model}>
                                {property.toSentenceCase()}
                            </Option>
                        ))}
                    </Options>
                    <Options
                        class="mr-500 ml-900"
                        onOptionSelected={(index: number) => {
                            resetFilters()
                            setLevel(getPropertyNameAtIndex(LEVELS, index))
                        }}
                    >
                        {Object.keys(LEVELS).map((property: string, index: number) => (
                            <Option key={index} selected={property == level}>
                                {property.toSentenceCase()}
                            </Option>
                        ))}
                    </Options>

                    {/*
                    Disabled for now, however will be implemented.

                    <Text class="ml-600 cl-gray-600">Customer type</Text>
                    <Options
                        class="mr-500 ml-300"
                        onOptionSelected={(index: number) => {
                            resetFilters()
                            setCustomerType(getPropertyNameAtIndex(NEW_RETURNING, index))
                        }}
                    >
                        {Object.keys(NEW_RETURNING).map((property: string, index: number) => (
                            <Option key={index} selected={property == customerType}>
                                {property.toSentenceCase()}
                            </Option>
                        ))}
                    </Options>
                    */}
                    <Button circle onClick={() => setInsightsModal(true)} class="ml-900">
                        <ButtonIcon icon="zap" />
                    </Button>
                </View>

                <If if={advanced}>
                    <Panel class="mb-900 mt-900" width="100%">
                        <View width="100%">
                            <If if={filter.length == 0}>
                                <Text class="mt-900">
                                    You do not have any filter rules applied. Click on the "Add a new filter row" button
                                    to add a rule. Remember to select "save".
                                </Text>
                            </If>
                            <Filter
                                preSelect
                                noLogic
                                noAddGroups
                                noDeleteGroups
                                buttonText="Save"
                                buttonIcon="reload"
                                comparisons={FILTER.COMPARISONS}
                                groups={[{ logic: '', rows: filter }]}
                                options={filterOptions}
                                onChange={handleFilterChange}
                                footer={
                                    <View flex={1} row justify="flex-start">
                                        <Text class="mr-500">UMM:</Text>
                                        <Select
                                            width={500}
                                            placeholder="Not selected"
                                            selected={ummSelectedCache}
                                            onSelect={(index: number) => setUmmSelectedCache(index)}
                                        >
                                            <SelectOption>Include UMM</SelectOption>
                                            <SelectOption>Exclude UMM</SelectOption>
                                            <SelectOption>Only UMM</SelectOption>
                                        </Select>
                                    </View>
                                }
                            />
                        </View>
                    </Panel>
                </If>

                <If if={!advanced}>
                    <View row class="mb-900 mt-900">
                        <View flex={1} class="pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by any"
                                    selected={aniesSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setAniesSelectedCache(indexes)}
                                >
                                    {filterAnies.map((filterOption: any, index: number) => (
                                        <SelectOption
                                            key={index}
                                            divider={filterOption.divider}
                                            class={
                                                filterOption.superDivider
                                                    ? 'customer-journey-component__super-divider'
                                                    : ''
                                            }
                                        >
                                            {filterOption.label}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by introducer"
                                    selected={introducersSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setIntroducersSelectedCache(indexes)}
                                >
                                    {filterIntroducers.map((filterOption: any, index: number) => (
                                        <SelectOption
                                            key={index}
                                            divider={filterOption.divider}
                                            class={
                                                filterOption.superDivider
                                                    ? 'customer-journey-component__super-divider'
                                                    : ''
                                            }
                                        >
                                            {filterOption.label}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by influencer"
                                    selected={influencersSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setInfluencersSelectedCache(indexes)}
                                >
                                    {filterInfluencers.map((filterOption: any, index: number) => (
                                        <SelectOption
                                            key={index}
                                            divider={filterOption.divider}
                                            class={
                                                filterOption.superDivider
                                                    ? 'customer-journey-component__super-divider'
                                                    : ''
                                            }
                                        >
                                            {filterOption.label}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500 pr-500">
                            <View flex={1}>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label="Filter by closer"
                                    selected={closersSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setClosersSelectedCache(indexes)}
                                >
                                    {filterClosers.map((filterOption, index: number) => (
                                        <SelectOption
                                            key={index}
                                            divider={filterOption.divider}
                                            class={
                                                filterOption.superDivider
                                                    ? 'customer-journey-component__super-divider'
                                                    : ''
                                            }
                                        >
                                            {filterOption.label}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-500">
                            <View flex={1}>
                                <Select
                                    width="100%"
                                    placeholder="Not selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    label="UMM"
                                    selected={ummSelectedCache}
                                    onSelect={(index: number) => setUmmSelectedCache(index)}
                                >
                                    <SelectOption>Include UMM</SelectOption>
                                    <SelectOption>Exclude UMM</SelectOption>
                                    <SelectOption>Only UMM</SelectOption>
                                </Select>
                            </View>
                        </View>

                        <View class="ml-800 p-relative" style={{ top: 12 }} column flex="none">
                            <Button circle onClick={handleFilterUpdate} small>
                                <ButtonIcon icon="reload" />
                            </Button>
                        </View>
                    </View>
                </If>

                <View row class="mb-900" justify="space-between">
                    <Metric
                        loading={loadingTotals}
                        width="33%"
                        label="Revenue"
                        icon="arrow-up"
                        color="#1CC7D0"
                        amount={metrics.revenue}
                        format={NUMERAL.FORMATS.CURRENCY}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="33%"
                        label="Conversions"
                        icon="thumbs-up"
                        color="#4885ef"
                        amount={metrics.conversions}
                        format={NUMERAL.FORMATS.NUMERIC}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="33%"
                        label="Unique Journeys"
                        icon="thumbs-up"
                        color="#8E43E7"
                        amount={metrics.journeys}
                        format={NUMERAL.FORMATS.NUMERIC}
                        showHelp
                    />
                </View>

                <Heading large class="mr-900">
                    Customer Journeys
                </Heading>

                <Carousel
                    loading={loadingJourneys}
                    title="Unique customer journeys"
                    total={metrics.journeys || 0}
                    start={start + 1}
                    end={end > max ? max : end}
                    header={
                        <>
                            <View row class="bg-cl-white p-300 bdr-r-300 mr-500 pr-900 pl-500">
                                <Text>Journey length</Text>
                                <View width={90}>
                                    <Label class="mr-500 ml-500 pt-none pb-none">
                                        {getSliderTextValues().min} - {getSliderTextValues().max}
                                    </Label>
                                </View>
                                <View flex={1}>
                                    <Slider
                                        tooltipValue={getTooltipValue}
                                        format={NUMERAL.FORMATS.PERCENT}
                                        color="#1FABD8"
                                        values={slider}
                                        max={1}
                                        min={0}
                                        anchor={0}
                                        delay={250}
                                        onChange={handleSliderChange}
                                    />
                                </View>
                            </View>
                            <Options
                                onOptionSelected={(index: number) => {
                                    setCount(COUNTS[index])
                                    setPage(0)
                                }}
                                class="mr-500"
                            >
                                {COUNTS.map((c: number, index: number) => (
                                    <Option key={index} selected={count == c}>
                                        {c}
                                    </Option>
                                ))}
                            </Options>
                        </>
                    }
                    footer={null}
                    onBack={handleJourneysBack}
                    onNext={handleJourneysNext}
                >
                    {journeys.map((journey: any, index: number) => (
                        <Journey
                            key={index}
                            number={journey.position}
                            width={250}
                            attributes={journey.attributes}
                            closer={journey.closer}
                            influencer={journey.influencer}
                            introducer={journey.introducer}
                        />
                    ))}
                </Carousel>

                <If if={journeys.length == 0}>
                    <Text large class="cl-gray-500">
                        There are no journeys
                    </Text>
                </If>
            </View>
        </>
    )
}
