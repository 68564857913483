import {
    Button,
    ButtonIcon,
    ButtonText,
    Check,
    Confirm,
    Flexer,
    Heading,
    Icon,
    If,
    Input,
    Label,
    Option,
    Options,
    Panel,
    Popover,
    Select,
    SelectOption,
    TagList,
    Text,
    Tooltip,
    View,
    showToast,
} from '@adtriba/ui'
import { ITagListItem } from '@adtriba/ui/lib/tag/tag'
import { useAuth0 } from '@auth0/auth0-react'
import EventEmitter from 'eventemitter3'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import { COLOR, COUNTS, ERROR, POPOVER, PUB_SUB, SPEND_MAPPER, SPEND_MAPPER_V1, TOOLTIP } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { classNames, logError, waitForState } from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { SpendMapperService } from '../../services/spend-mapper.service'
import './spend-mapper.component.sass'

const eventEmitter = new EventEmitter()

const OriginCampaign = React.memo(
    (props: any) => {
        const { getAccessTokenSilently } = useAuth0()
        const { accountId } = useParams()
        const [selected, setSelected] = useState(-1)
        const { originCampaign, adtribaCampaigns } = props
        const [loadingRecomendations, setLoadingRecomendations] = useState(false)
        const [recommendedAdtribaCampaigns, setRecommendedAdtribaCampaigns] = useState<any>([])
        const [mappingId, setMappingId] = useState(null)
        const classes = classNames({
            'mb-500': true,
            'bdr': props.checked,
            'bdr-cl-electric': props.checked,
            'p-300': true,
            'bdr-r-500': true,
            'spend-mapper-component': true,
        })

        const saveMappingsSubscriber = (data) => {
            if (!props.checked) return
            handlePostOrDeleteMappings(selected)
        }

        const resetSelectionSubscriber = (data) => {
            if (!props.checked) return
            setSelected(-1)
            handleGetCampaignRecommendations()
        }

        const applyRecommendationsSubscriber = (data) => {
            if (!props.checked) return
            if (recommendedAdtribaCampaigns.length == 0) return
            if (!recommendedAdtribaCampaigns[0]) return
            applyRecommendations(recommendedAdtribaCampaigns[0].id)
        }

        const applyRecommendations = (adtribaCampaignId: string) => {
            const selectedAdtribaCampaignIndex = adtribaCampaigns.findIndex(
                (adtribaCampaign: any) => adtribaCampaign.id == adtribaCampaignId
            )
            setSelected(selectedAdtribaCampaignIndex)
        }

        const handlePostOrDeleteMappings = async (adtribaCampaignIndex: number) => {
            try {
                const token = await getAccessTokenSilently()

                // If there is none selected - then remove the mapping
                // This might be redundant if they click save with nothing selected
                // So make sure there actually is a mappingId
                if (selected == -1 && !!mappingId) {
                    await SpendMapperService.deleteMappings(token, accountId, mappingId)
                    setMappingId(null)
                    showToast('Successfully deleted!')
                }

                // Only do this if there is a valid adtriba campaign selected
                if (selected != -1) {
                    const id = mappingId ? mappingId : originCampaign.campaign_mapping_id
                    const result = await SpendMapperService.postMappings(token, accountId, {
                        id,
                        origin_campaign_id: originCampaign.id,
                        adtriba_campaign_id: adtribaCampaigns[adtribaCampaignIndex].id,
                    })

                    // Update the our campaign mappingId
                    setMappingId(result.id)

                    // Notify the user after async
                    showToast('Successfully updated!')
                }
            } catch (e) {
                logError(e)
            }
        }

        const handleGetCampaignRecommendations = async () => {
            setLoadingRecomendations(true)

            try {
                const token = await getAccessTokenSilently()
                const result = await SpendMapperService.getCampaignRecommendations(token, accountId, {
                    origin_campaign_ids: [originCampaign.id],
                })

                // The API returns values that hold the ID for an adtriba campaign & origin campaign
                // it return recommendation for origin campaigns that are not this one
                // so here it's necessary to remove the ones that are not for this origin campaign
                setLoadingRecomendations(false)
                setRecommendedAdtribaCampaigns(
                    result
                        // Only use THIS origin campaign recommendations
                        .filter(
                            (recommendedAdtribaCampaign: any) =>
                                recommendedAdtribaCampaign.origin_campaign_id == originCampaign.id
                        )
                        // Find the corresponding rich object adtriba campaign
                        .map((recommendedAdtribaCampaign: any) => {
                            return adtribaCampaigns.find(
                                (adtribaCampaign: any) =>
                                    adtribaCampaign.id == recommendedAdtribaCampaign.adtriba_campaign_id
                            )
                        })
                )
            } catch (e) {
                setLoadingRecomendations(false)
                logError(e)
            }
        }

        useEffect(() => {
            eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, saveMappingsSubscriber)
            eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, resetSelectionSubscriber)
            eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS, applyRecommendationsSubscriber)

            return () => {
                eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, saveMappingsSubscriber)
                eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, resetSelectionSubscriber)
                eventEmitter.removeListener(
                    PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS,
                    applyRecommendationsSubscriber
                )
            }
        })

        useEffect(() => {
            if (!originCampaign.id) return

            setMappingId(originCampaign.campaign_mapping_id)
        }, [originCampaign])

        useEffect(() => {
            if (!originCampaign.id) return
            if (adtribaCampaigns.length == 0) return

            setSelected(
                adtribaCampaigns.findIndex(
                    (adtribaCampaign: any) => originCampaign.adtriba_campaign_mapping_id == adtribaCampaign.id
                )
            )
        }, [adtribaCampaigns, originCampaign])

        useEffect(() => {
            if (!originCampaign.id) return
            if (adtribaCampaigns.length == 0) return
            if (selected != -1) return

            handleGetCampaignRecommendations()
        }, [adtribaCampaigns, originCampaign, selected])

        return (
            <>
                <View row class={classes}>
                    <View row justify="flex-start">
                        <Check
                            onChange={() => props.onCheck(!props.checked)}
                            checked={props.checked}
                            disabled={false}
                        />

                        <Tooltip position={TOOLTIP.POSITION.RIGHT} text={originCampaign.adtriba_campaign}>
                            <View column align="flex-start" flex="none" class="ml-400">
                                <Heading small>{originCampaign.adtriba_campaign}</Heading>
                                <Text>
                                    {originCampaign.connection_name} → {originCampaign.adtriba_source}
                                </Text>
                            </View>
                        </Tooltip>
                    </View>

                    <View column flex={2}>
                        <View row width="100%">
                            <View row class="mr-200">
                                <Select
                                    showClear
                                    filterable
                                    optionsContainerStyles={{ minHeight: '30rem' }}
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    width="100%"
                                    placeholder="..."
                                    selected={selected}
                                    onSelect={(index: number) => setSelected(index)}
                                >
                                    {adtribaCampaigns.map((adtribaCampaign: any, index: number) => {
                                        const {
                                            campaign,
                                            project_name,
                                            channel,
                                            source,
                                            formatted_revenue,
                                        } = adtribaCampaign
                                        const selectOptionText = `${campaign} [${project_name} → ${channel} → ${source}] (${formatted_revenue})`

                                        return <SelectOption key={index}>{selectOptionText}</SelectOption>
                                    })}
                                </Select>
                            </View>
                            <Button onClick={() => handlePostOrDeleteMappings(selected)}>
                                <ButtonIcon icon="save" />
                                <ButtonText>Save</ButtonText>
                            </Button>
                        </View>

                        <If if={loadingRecomendations}>
                            <View row justify="flex-start" width="100%" class="mt-300">
                                <Text small>Fetching recommendations...</Text>
                            </View>
                        </If>

                        {recommendedAdtribaCampaigns
                            // Filter out invalid adtriba campaigns
                            .filter((recommendedAdtribaCampaign: any) => !!recommendedAdtribaCampaign)
                            // Filter our the selected one (if there is a selection)
                            .filter((recommendedAdtribaCampaign: any) =>
                                adtribaCampaigns[selected]
                                    ? adtribaCampaigns[selected].id != recommendedAdtribaCampaign.id
                                    : true
                            )
                            // Display to the UI
                            .map((recommendedAdtribaCampaign: any, index: number) => {
                                const {
                                    id,
                                    campaign,
                                    project_name,
                                    channel,
                                    source,
                                    formatted_revenue,
                                } = recommendedAdtribaCampaign
                                const recommendationText = `${campaign} [${project_name} → ${channel} → ${source}] (${formatted_revenue})`

                                return (
                                    <View row justify="flex-start" width="100%" class="mt-300" key={index}>
                                        <Text class="ml-300">{recommendationText}</Text>
                                        <Flexer />
                                        <Button light small onClick={() => applyRecommendations(id)}>
                                            <ButtonText>Apply</ButtonText>
                                        </Button>
                                    </View>
                                )
                            })}
                    </View>
                </View>
            </>
        )
    },
    (prevProps, nextProps) => {
        if (prevProps.checked != nextProps.checked) return false
        if (prevProps.originCampaign.id != nextProps.originCampaign.id) return false
        if (prevProps.adtribaCampaigns.length == 0) return false
        if (prevProps.adtribaCampaigns.length != nextProps.adtribaCampaigns.length) return false
        if (prevProps.adtribaCampaigns[0].id != nextProps.adtribaCampaigns[0].id) return false

        return true
    }
)

interface ISpendMapperComponent {
    children?: any
}

export const SpendMapperComponent: FunctionComponent = (props: ISpendMapperComponent): ReactElement => {
    const { getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorOrigin, setErrorOrigin] = useState(null)
    const [errorAdtriba, setErrorAdtriba] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId } = useParams()
    const [confirm, setConfirm] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [count, setCount] = useState(COUNTS[0])
    const [mappingType, setMappingType] = useState(3)
    const [orderBy, setOrderBy] = useState(2)
    const [contains, setContains] = useState('')
    const [selectedChannels, setSelectedChannels] = useState<ITagListItem[]>([])
    const [allChannels, setAllChannels] = useState<ITagListItem[]>([])
    const [originCampaigns, setOriginCampaigns] = useState<any>([])
    const [originCampaignsChecked, setOriginCampaignsChecked] = useState<any>([])
    const [adtribaCampaigns, setAdtribaCampaigns] = useState<any>([])
    const showingHiddenMappingType = SPEND_MAPPER_V1.MAPPING_TYPES[mappingType].value == 'hidden'
    const allIsChecked =
        originCampaignsChecked.filter((originCampaignCheck: any) => originCampaignCheck).length ==
        originCampaigns.length
    const anyIsChecked = originCampaignsChecked.filter((originCampaignCheck: any) => originCampaignCheck).length > 0

    const handleGetOriginCampaigns = async () => {
        setLoading(true)
        setErrorOrigin(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getOriginCampaigns(token, accountId, {
                contains, // drops
                count, // Keep
                mapping_type: SPEND_MAPPER_V1.MAPPING_TYPES[mappingType].value, // drops
                order_by: SPEND_MAPPER_V1.ORDERS[orderBy].value, // Keep as query
                page: currentPage, // Keep as query
            })
            const { content, page_count, max_page, total_size, page } = result

            setLoading(false)
            setTotalRecords(total_size)
            setCurrentPage(page)
            setPageCount(page_count)
            setMaxPage(max_page)
            setOriginCampaigns(content.sort((a, b) => a.adtriba_campaign.localeCompare(b.adtriba_campaign)))
            setOriginCampaignsChecked(content.map((_: any) => false))
        } catch (e) {
            setOriginCampaigns([])
            setLoading(false)
            setErrorOrigin(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handleGetAdtribaCampaigns = async () => {
        setLoading(true)
        setErrorAdtriba(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getAdtribaCampaigns(token, accountId, {
                exclude_channels: selectedChannels.map((channel: any) => channel.key),
            })

            setLoading(false)
            setAdtribaCampaigns(result)
            setNotification(result.length == 0 ? 'There are no adtriba campaigns' : null)
        } catch (e) {
            setLoading(false)
            setErrorAdtriba(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handleGetAdtribaCampaignsChannel = async () => {
        setLoading(true)
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getAdtribaCampaignsChannel(token, accountId)

            setLoading(false)
            setAllChannels(
                result.map((channel: any) => ({
                    key: channel.channel_hash,
                    label: channel.channel_label,
                }))
            )
        } catch (e) {
            setLoading(false)
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handlePostOriginCampaignStatus = async (hidden: boolean) => {
        try {
            const token = await getAccessTokenSilently()
            const checkedOriginCampaignIds = originCampaigns
                .filter((originCampaign: any, index: number) => originCampaignsChecked[index])
                .map((originCampaign: any) => originCampaign.id)

            // One big API call to mark all selected as hidden
            await Promise.all(
                checkedOriginCampaignIds.map((checkedOriginCampaignId: string) => {
                    return SpendMapperService.postOriginCampaignStatus(token, accountId, {
                        campaign_id: checkedOriginCampaignId,
                        hidden,
                    })
                })
            )

            // Refetch all origin campaigns
            // This will also uncheck eveerything
            handleGetOriginCampaigns()
        } catch (e) {
            logError(e)
        }
    }

    const handleNext = () => {
        if (currentPage < maxPage) setCurrentPage(currentPage + 1)
    }

    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1)
    }

    const handleReload = () => {
        handleGetOriginCampaigns()
    }

    const handleSelectAll = () => {
        setOriginCampaignsChecked([...originCampaignsChecked.map((_: any) => !allIsChecked)])
    }

    const handleHideMappings = async () => {
        handlePostOriginCampaignStatus(!showingHiddenMappingType)
    }

    const handleSaveChangedMappings = async () => {
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, true)
        deselectAll()
    }

    const handleApplyRecommendationsSelection = async () => {
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS, true)
        deselectAll()
    }

    const handleResetSelection = async () => {
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, true)
        deselectAll()
    }

    const handleOriginCampaignCheck = (index1: number, checked: boolean) => {
        setOriginCampaignsChecked([
            ...originCampaignsChecked.map((originCampaignsCheck: boolean, index2: number) =>
                index1 == index2 ? checked : originCampaignsCheck
            ),
        ])
    }

    const deselectAll = () => {
        // Send this to the bottom of the call stack
        // With some delay to make sure it executes last
        waitForState(() => setOriginCampaignsChecked([...originCampaigns.map((_: any) => false)]))
    }

    // Get origin campaigns
    useEffect(() => {
        if (!accountId) return
        handleGetOriginCampaigns()
    }, [accountId, currentPage, count])

    // Get adtriba camapaigns
    useEffect(() => {
        if (!accountId) return
        handleGetAdtribaCampaigns()
    }, [accountId, selectedChannels])

    // Get channels
    useEffect(() => {
        if (!accountId) return
        handleGetAdtribaCampaignsChannel()
    }, [accountId])

    useEffect(() => {
        if (!StorageService.getStorage(SPEND_MAPPER.HELP)) {
            // Set any modal state only once
            // StorageService.setStorage(SPEND_MAPPER_HELP, 'true')
        }
    }, [])

    // Calculate the mins/maxes
    let min = currentPage * pageCount
    let max = currentPage * pageCount + pageCount

    // If the max is higher than total record count
    // Then rather use the totalRecords
    if (max > totalRecords) max = totalRecords

    // Catch 0 records, otherwise start from 1 (more readable)
    if (totalRecords == 0) min = 0
    if (totalRecords > 0) min = min + 1

    return (
        <>
            <Confirm
                visible={confirm}
                title="Are you sure?"
                description="Are you sure you want to apply selected actions?"
                onConfirm={handleSaveChangedMappings}
                onDismiss={() => setConfirm(false)}
            />
            <LoaderComponent loading={loading} />
            <NotificationComponent notification={notification} error={error || errorOrigin || errorAdtriba} />
            <View row justify="flex-end" class="mb-900">
                <Heading large>Spend Mapper</Heading>
                <Label blue class="ml-300">
                    v1
                </Label>
                <Flexer />
            </View>

            <View row class="mt-500 mb-500">
                <View row flex={1} class="p-200 pl-none">
                    <View row>
                        <Select
                            label="Mapping types"
                            width="100%"
                            placeholder="None selected"
                            selected={mappingType}
                            onSelect={(index: number) => {
                                setMappingType(index)
                                setCurrentPage(0)
                            }}
                        >
                            {SPEND_MAPPER_V1.MAPPING_TYPES.map((mapping: any, index: number) => (
                                <SelectOption key={index}>{mapping.label}</SelectOption>
                            ))}
                        </Select>
                    </View>
                </View>
                <View row flex={1} class="p-200 pr-none">
                    <View row>
                        <Select
                            width="100%"
                            label="Order by"
                            placeholder="None selected"
                            selected={orderBy}
                            onSelect={(index: number) => {
                                setOrderBy(index)
                                setCurrentPage(0)
                            }}
                        >
                            {SPEND_MAPPER_V1.ORDERS.map((order: any, index: number) => (
                                <SelectOption key={index}>{order.label}</SelectOption>
                            ))}
                        </Select>
                    </View>
                </View>
                <View row flex={1} class="p-200 pr-none">
                    <View row>
                        <Input
                            type="text"
                            placeholder="Contains"
                            label="Contains"
                            value={contains}
                            onChange={(value: string) => setContains(value)}
                        />
                    </View>
                </View>

                <Button style={{ top: 10 }} class="ml-500 p-relative" onClick={() => handleGetOriginCampaigns()}>
                    <ButtonIcon icon="reload" />
                </Button>
            </View>
            <View row justify="flex-start" class="mt-500 mb-500">
                <Heading xSmall class="uppercase">
                    EXCLUDE CHANNELS
                </Heading>
            </View>
            <View row justify="flex-start" class="mt-500 mb-900">
                <TagList
                    max={allChannels.length}
                    dark
                    selected={[]}
                    tags={selectedChannels}
                    allTags={allChannels}
                    onAdd={(channel: ITagListItem) => setSelectedChannels([...selectedChannels, channel])}
                    onDelete={(index1: number) =>
                        setSelectedChannels(
                            selectedChannels.filter((_: ITagListItem, index2: number) => index1 != index2)
                        )
                    }
                    onSelect={() => {}}
                />
            </View>
            <View row justify="flex-start" class="mt-900">
                <Check onChange={handleSelectAll} checked={allIsChecked} disabled={false} />
                <Text class="pl-500">Select all</Text>
                <Flexer />

                <Button light small class="mr-900" onClick={handleReload}>
                    <ButtonIcon icon="reload" />
                </Button>

                <Options
                    onOptionSelected={(index: number) => {
                        setCount(COUNTS[index])
                        setCurrentPage(0)
                    }}
                    class="mr-500"
                >
                    {COUNTS.map((c: number, index: number) => (
                        <Option key={index} selected={count == c}>
                            {c}
                        </Option>
                    ))}
                </Options>

                <Text class="pr-900">
                    {min} - {max} / {totalRecords}
                </Text>
                <View class="buttonize" width={20} height={20} onClick={handlePrevious}>
                    <Icon icon="chevron-left" size={20} color="#CFD4D9" />
                </View>
                <View class="buttonize ml-300" width={20} height={20} onClick={handleNext}>
                    <Icon icon="chevron-right" size={20} color="#CFD4D9" />
                </View>
            </View>
            <Panel width="100%" class="mt-900">
                <View row>
                    <View row justify="flex-start">
                        <Heading class="mr-500">Ad platform campaigns</Heading>
                        <Popover
                            position={POPOVER.POSITION.TOP}
                            render={({ dismiss }) => (
                                <View class="p-900">
                                    <Heading small>What is this?</Heading>
                                    <Text style={{ marginBottom: 10 }}>All origin campaigns</Text>
                                </View>
                            )}
                        >
                            <View width={20} height={20} class="buttonize">
                                <Icon icon="info" color={COLOR.COLOR_GRAY_400} size={20} />
                            </View>
                        </Popover>
                    </View>
                    <View row justify="flex-start" flex={2}>
                        <Heading class="mr-500">Adtriba campaigns</Heading>
                        <Popover
                            position={POPOVER.POSITION.TOP}
                            render={({ dismiss }) => (
                                <View class="p-900">
                                    <Heading small>What is this?</Heading>
                                    <Text style={{ marginBottom: 10 }}>
                                        Available Adtriba campaigns & suggested mappings
                                    </Text>
                                </View>
                            )}
                        >
                            <View width={20} height={20} class="buttonize">
                                <Icon icon="info" color={COLOR.COLOR_GRAY_400} size={20} />
                            </View>
                        </Popover>
                    </View>
                </View>
            </Panel>
            <Panel width="100%" class="mt-100">
                {originCampaigns.map((originCampaign: any, index: number) => {
                    return (
                        <OriginCampaign
                            key={index}
                            checked={originCampaignsChecked[index]}
                            originCampaign={originCampaign}
                            adtribaCampaigns={adtribaCampaigns}
                            onCheck={(checked: boolean) => handleOriginCampaignCheck(index, checked)}
                        />
                    )
                })}
            </Panel>
            <View height={100} />
            <View class="p-fixed bg-cl-aqua" style={{ bottom: 0, left: 0, zIndex: 1000 }} width="100%">
                <View row justify="flex-start" class="p-500">
                    <Button onClick={handleApplyRecommendationsSelection}>
                        <ButtonIcon icon="check" />
                        <ButtonText>Apply recommendations</ButtonText>
                    </Button>
                    <Button onClick={handleResetSelection}>
                        <ButtonIcon icon="check" />
                        <ButtonText>Reset recommendations</ButtonText>
                    </Button>
                    <Flexer />
                    <If if={anyIsChecked}>
                        <Button onClick={handleHideMappings}>
                            <ButtonIcon icon="check" />
                            <ButtonText>{showingHiddenMappingType ? 'Unhide selected' : 'Hide selected'}</ButtonText>
                        </Button>
                        <Button onClick={() => setConfirm(true)}>
                            <ButtonIcon icon="check" />
                            <ButtonText>Save mappings</ButtonText>
                        </Button>
                    </If>
                </View>
            </View>
        </>
    )
}
