import { Button } from '@adtriba/ui'
import React, { forwardRef, RefObject, useContext } from 'react'
import { DialogComponent } from '../dialogs/dialog.component'
import { classNames } from '../../helpers/util'
import './updatemodal.component.sass'

interface IUpdateModal {
    handleUpdate: () => void
}

export const UpdateDialog = forwardRef(({ handleUpdate }: IUpdateModal, ref: RefObject<HTMLDialogElement>) => {
    const handleClose = () => ref.current.close()

    return (
        <DialogComponent
            ref={ref}
            className={classNames({
                'update-modal': true,
            })}
        >
            <div className="update-modal-wrapper">
                <div className="modal-content">
                    <p>A new version is available.</p>
                    <p>Please save your work, and click to update and reload Adtriba Core.</p>
                </div>
                <div className="modal-buttons">
                    <span data-update-button="true">
                        <Button
                            onClick={handleUpdate}
                            class={classNames({
                                'modal-button': true,
                                'modal-button__primary': true,
                            })}
                        >
                            Update
                        </Button>
                    </span>
                    <span data-update-later="true">
                        <Button
                            onClick={handleClose}
                            class={classNames({
                                'modal-button': true,
                                'modal-button__light': true,
                            })}
                            light
                        >
                            Later
                        </Button>
                    </span>
                </div>
            </div>
        </DialogComponent>
    )
})
