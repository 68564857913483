import './helpers/extensions'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { Integration } from '@sentry/types'
import { App } from './app'
import './styles.sass'
import numeral from 'numeral'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import posthog from 'posthog-js'

// Populate our list of locales
import './locales.ts'

// Add support for "ago" & "from now"
dayjs.extend(relativeTime)

// Set up Sentry
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new TracingIntegrations.BrowserTracing() as Integration],
    tracesSampleRate: 1,
})

// Init Posthog for PRODUCTION
if (process.env.REACT_APP_NODE_ENV == 'production') {
    posthog.init('phc_FrZ58wJusdfDenSDtlctie4IGNEsmlDQ9KrVJOXJgVR', { api_host: 'https://app.posthog.com' })
}

// And now mount our app
ReactDOM.render(React.createElement(App, { currentScript: document.currentScript }), document.getElementById('root'))
