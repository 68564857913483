import { Icon, View } from '@adtriba/ui'
import { useAuth0 } from '@auth0/auth0-react'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IconComponent } from '../../../../components/icon/icon.component'
import { COLORS, PRODUCT, ROLE } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { classNames, getEnabledRule, logError, routeIsActive, waitForState } from '../../../../helpers/util'
import { IRoute } from '../../../../types/IRoute'
import { AppRoutes } from '../../app.routes'
import { ExternalComponent } from '../external/external.component'
import './toolbar.component.sass'

interface IToolbarComponent {
    children?: any
}

export const ToolbarComponent: FunctionComponent = (props: IToolbarComponent): ReactElement => {
    const { getAccessTokenSilently } = useAuth0()
    const app: any = useContext(AppContext)
    const [routes, setRoutes] = useState<IRoute[]>([])
    const history = useHistory()
    const { accountId } = useParams()
    const [role, setRole] = useState(null)
    const [rules, setRules] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingButton, setLoadingButton] = useState(null)
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
    const [sidebarDisabled, setSidebarDisabled] = useState<boolean>(true)

    // Always use the UUID's from the Context object
    // Project & report ID's might not always be available
    // The placeholder serves as filler to ensure proper URL structure
    // Within each component we will check before making API calls
    // So that we don't have to fill the URL with something like "none"
    const handleRedirect = async (route: IRoute, enabled: boolean) => {
        try {
            const { required } = route
            const projectId = app.selectedProjectId
            const reportId = app.selectedReportId

            // Don't navigate it's not enabled
            if (!enabled) return

            // If the report is required, but we have none
            // Only start the loading if there is going to be an async call
            if (required.reportId && !reportId) {
                setLoading(true)
                setLoadingButton(route.path)
            }

            // Stop the loading
            setLoading(false)
            setLoadingButton(null)

            // Navigate there
            waitForState(() => {
                history.push(
                    route.path
                        .replace(':accountId', app.selectedAccountId)
                        .replace(':projectId', projectId)
                        .replace(':reportId', app.selectedReportId)
                )
            }, 200)
        } catch (e) {
            setLoading(false)
            setLoadingButton(null)

            logError('toolbar handleredirect', e)
        }
    }

    // This is only used to set the role
    // And then to set iframes from the project API
    useEffect(() => {
        if (!app.accountsList.length) return

        const account = app.accountsList.find((account: any) => account.id === app.selectedAccountId)
        const projectId = app.selectedProjectId

        if (!app.selectedAccountId) return
        if (!app.selectedProjectId) return

        // Get our role
        const role = account?.products.length
            ? account?.products
                  .find((product: any) => product.product == PRODUCT.CORE)
                  .roles.find((project: any) => project.entity_id === projectId)?.role
            : null

        // Update our role state
        setRole(account.is_owner ? ROLE.ADMIN : role)

        // Update our app state
        waitForState(() => {
            app.setApp({ ...app, role: account.is_owner ? ROLE.ADMIN : role })
        }, 200)

        // Reader can not see additional routes
        // if (role == ROLE.READER) return
        if (!app.selectedProjectId) return
        if (!app.selectedAccount) return

        // Save our rules at project level
        const project = app.selectedAccount.find((project: any) => project.id === app.selectedProjectId)

        if (project) {
            setRules(project.menu_items || [])
        } else {
            setRules([])
        }

        // (Otherwise all good)
        if (!project.iframes || project.iframes.length == 0) {
            setRoutes([])
        } else {
            setRoutes(
                project.iframes.map((iframe: any) => {
                    return {
                        path: `/accounts/${accountId}/projects/${project.id}/external?url=${iframe.url}`,
                        icon: iframe.icon,
                        label: iframe.label,
                        component: ExternalComponent,
                    }
                })
            )
        }
    }, [accountId, app.selectedProjectId])

    const handleMouseEnter = () => {
        setSidebarDisabled(false)
        setSidebarOpen(true)
    }

    const handleMouseLeave = () => {
        setSidebarDisabled(true)
        setSidebarOpen(false)
    }

    return (
        <div
            className={classNames({
                ['toolbar-component']: true,
                ['sidebar-open']: sidebarOpen,
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseEnter}
            data-disabled={sidebarDisabled}
        >
            <View
                class="toolbar-component__container"
                displayFlex
                direction="column"
                justify="space-between"
                flex={1}
                align="initial"
            >
                <View>
                    {AppRoutes.generate(role, {
                        spendmapperv3: true,
                    }).map((route: any, index: number) => {
                        if (route.hidden) return null

                        const buttonIsLoading = loading && loadingButton == route.path
                        const pathPart = window.location.pathname.split('/')
                        const lastPart = pathPart[pathPart.length - 1] ? pathPart[pathPart.length - 1] : ''
                        const isActive = lastPart == (route.end || buttonIsLoading)
                        const color = isActive ? COLORS.TOOLBAR.ACTIVE : COLORS.TOOLBAR.INACTIVE
                        const enabled = rules.length == 0 ? route.enabled : getEnabledRule(route.rule, rules)

                        return (
                            <View key={route.path} class="toolbar-menu" displayFlex align="center">
                                <div
                                    className={classNames({
                                        ['toolbar-menu__item']: true,
                                        ['buttonize']: true,
                                        ['active']: isActive,
                                    })}
                                    onClick={() => {
                                        handleRedirect(route, enabled)
                                    }}
                                    data-toolbar-button={route.rule}
                                    data-disabled={!!!enabled}
                                >
                                    <View
                                        class="menu-item__inner-wrapper"
                                        displayFlex
                                        align="center"
                                        justify="center"
                                        flex="initial"
                                    >
                                        <Icon
                                            icon={route.icon}
                                            size={24}
                                            color={color}
                                            style={{ opacity: enabled ? 1 : 0.3, zIndex: 0, position: 'relative' }}
                                        />
                                        <p>{route.label}</p>
                                    </View>
                                </div>
                            </View>
                        )
                    })}
                    {routes.map((route: any) => {
                        const color = routeIsActive(route.path) ? COLORS.TOOLBAR.ACTIVE : COLORS.TOOLBAR.INACTIVE

                        return (
                            <View key={route.path} class="toolbar-menu" displayFlex align="center" flex="initial">
                                <View
                                    class={classNames({
                                        ['toolbar-menu__item']: true,
                                        ['buttonize']: true,
                                    })}
                                    onClick={() => {
                                        history.push(route.path)
                                    }}
                                    data-toolbar-button={route.label}
                                >
                                    <View
                                        class="menu-item__inner-wrapper"
                                        displayFlex
                                        align="center"
                                        justify="center"
                                        flex="initial"
                                    >
                                        <Icon icon={route.icon} size={24} color={color} />
                                        <p>{route.label !== '' ? route.label : 'Looker Dashboard'}</p>
                                    </View>
                                </View>
                            </View>
                        )
                    })}
                </View>
                <View class="toolbar-menu" displayFlex align="center" flex="initial">
                    <a href="https://www.adtriba.com" target="_blank" className="toolbar-menu__item p-relative">
                        <View
                            class="menu-item__inner-wrapper"
                            displayFlex
                            align="center"
                            justify="center"
                            flex="initial"
                        >
                            <IconComponent icon="info" size={24} color={COLORS.TOOLBAR.INACTIVE} />
                            <p>Help & support</p>
                        </View>
                    </a>
                </View>
            </View>
        </div>
    )
}
