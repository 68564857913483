import process from 'process'
import { createURLQueryString, returnGoogleAdsApiIntegrationParser, returnParser } from '../../../helpers/util'

export class IntegrationsService {
    static async getGoogleRedirectAuthToken(token: string, accountId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integration/create-google-redirect-setup-auth`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getIntegrations(token: string, accountId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async deleteIntegrations(token: string, accountId: string, integrationId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/${integrationId}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getIntegrationsGoogle(token: string, accountId: string, queryStringParameters: any): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/google?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnGoogleAdsApiIntegrationParser(result)
    }

    static async deleteIntegrationsGoogle(token: string, accountId: string, tokenId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/token/${tokenId}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async putIntegrationsUpdate(
        token: string,
        accountId: string,
        payload: any,
        queryStringParameters: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/update?${queryString}`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload),
        })
        return await returnParser(result)
    }

    static async postIntegrationsUpdate(
        token: string,
        accountId: string,
        payload: any,
        queryStringParameters: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/update?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload),
        })
        return await returnParser(result)
    }

    static async getIntegrationsFacebook(token: string, accountId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/facebook`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async deleteIntegrationsFacebook(token: string, accountId: string, integrationId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/facebook?integration_id=${integrationId}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async putIntegrationsFacebook(token: string, accountId: string, payload: any): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/integrations/facebook`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload),
        })
        return await returnParser(result)
    }
}
