import { AlertProvider, Alerts, Toast } from '@adtriba/ui'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ADTRIBA_FIRST_LOGIN } from './constants'
import { AppProvider } from './contexts/app.context'
import Auth0ProviderWithHistory from './helpers/auth0-provider-with-history'
import ProtectedRoute from './helpers/protected-route'
import { extractHash, logError } from './helpers/util'
import { AppModule } from './modules/app/app.module'

export const UpdateContext = createContext<boolean>(true)

export const App = ({ currentScript }) => {
    const timeoutRef = useRef(null)
    const [updateAvailable, setUpdateAvailable] = useState<boolean | null>(null)

    const handleVersionCheck = async () => {
        try {
            const currentVersion = extractHash(currentScript.src.replace(window.location.origin, ''))
            const result = await fetch('/manifest.json', {
                cache: 'no-store',
            })
            const manifest = await result.json()
            const newVersion = extractHash(manifest['index.js'])
            const updated = localStorage.getItem('updated')

            switch (true) {
                case currentVersion !== newVersion:
                    setUpdateAvailable(true)
                    localStorage.setItem('updated', 'false')
                    break
                case updated === 'false':
                    setUpdateAvailable(true)
                    break
                case updated === 'true':
                    setUpdateAvailable(false)
                    break
                default:
                    setUpdateAvailable(false)
                    localStorage.setItem('updated', 'true')
            }
        } catch (error) {
            logError(error)
        }
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('code')) {
            window[ADTRIBA_FIRST_LOGIN] = true
        }
    }, [])

    // useEffect(() => {
    //     timeoutRef.current = setInterval(() => handleVersionCheck(), 3000)

    //     return () => {
    //         clearInterval(timeoutRef.current)
    //     }
    // }, [])

    return (
        <>
            <Router>
                <AlertProvider>
                    <Auth0ProviderWithHistory>
                        <AppProvider>
                            <UpdateContext.Provider value={updateAvailable}>
                                <Switch>
                                    <ProtectedRoute path="/" component={AppModule} />
                                </Switch>
                            </UpdateContext.Provider>
                            <Toast />
                            <Alerts />
                        </AppProvider>
                    </Auth0ProviderWithHistory>
                </AlertProvider>
            </Router>
        </>
    )
}

export default App
