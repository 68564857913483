import { Button, ButtonIcon, Icon, If, Input, Panel, Select, SelectOption, Text, View } from '@adtriba/ui'
import EventEmitter from 'events'
import React, { useEffect, useMemo, useState } from 'react'
import { CHANNEL_MAPPER_V1, COLOR, PUB_SUB } from '../../../../constants'
import { classNames } from '../../../../helpers/util'
import { InputRow } from './channel-mapper.component'
import './channel-mapper.component.sass'

const eventEmitter = new EventEmitter()

interface RuleProps {
    validations: any[]
    rule: {
        index: number
        originalPosition: number
        conditions: any[]
        displayName: string
        level: string
    }
    index: number
    originalPosition: number
    orderingTimestamp: number
    position: number
    onDelete: () => void
    onAdd: () => void
    onDuplicate: () => void
    onUpdate: (rule: any) => void
    onUp: () => void
    onDown: () => void
    getOriginalPositionActualPositionIndex: (originalPosition: number) => number
}

const Rule = ({
    validations,
    rule,
    index,
    originalPosition,
    orderingTimestamp,
    position,
    onDelete,
    onAdd,
    onDuplicate,
    onUpdate,
    onUp,
    onDown,
    getOriginalPositionActualPositionIndex,
}: RuleProps) => {
    const [conditions, setConditions] = useState([])
    const [level, setLevel] = useState(-1)
    const [name, setName] = useState('')
    const [scopedName, setScopedName] = useState('')
    const [showInputs, setShowInputs] = useState(false)

    // This gets all validation message for this rule
    // It then removes the position from the affecting_rules array
    // So we don't have to do this at render time - also rename it for nicer reading
    // Also strip out the self reference with validation.affecting_rules
    const ruleValidations = useMemo(() => {
        return validations
            .filter((validation: any) => validation.affecting_rules.includes(originalPosition))
            .map((validation: any) => {
                return {
                    ...validation,
                    affecting_rules: validation.affecting_rules.filter((rule: number) => rule != originalPosition),
                }
            })
            .map((validation: any) => {
                switch (validation.code) {
                    case 'conflicting_rules':
                        return (
                            'This rule conflicts with ' +
                            validation.affecting_rules
                                .sort((a: number, b: number) => a - b)
                                .map((r: number) => `#${getOriginalPositionActualPositionIndex(r) + 1}`)
                                .join(', ')
                        )
                    case 'no_matching_data':
                        return 'No matching campaign data found for this rule'
                    case 'cyclic_matching':
                        return 'This rule matches against the result of an existing mapping'
                    default:
                        return 'Invalid rule'
                }
            })
    }, [validations, position, index, orderingTimestamp])

    const validation = ruleValidations.length > 0
    const classes = classNames({
        'mb-100': true,
    })
    const textClasses = classNames({
        'pr-500': true,
        'cl-gray-500': !validation,
        'cl-red': validation,
    })

    const handlePubSubMessageExpandAll = () => {
        setShowInputs(true)
    }

    const handlePubSubMessageCollapseAll = () => {
        setShowInputs(false)
    }

    const handlePubSubMessageExpandOne = ({ position }) => {
        if (position == position) setShowInputs(true)
    }

    const handleInputAdd = async (index: number) => {
        const newInput = { level: CHANNEL_MAPPER_V1.LEVELS[0], operator: CHANNEL_MAPPER_V1.OPERATORS[0], value: '' }
        const newConditions = [...conditions]
        newConditions.splice(index + 1, 0, newInput) // Correct insertion
        setConditions(newConditions)
    }

    const handleDelete = () => {
        if (confirm('Are you sure?')) onDelete()
    }

    const handleLevelUpdate = (index: number) => {
        setLevel(index)

        onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[index],
            displayName: name,
            conditions,
        })
    }

    const handleInputUpdate = (input1: any, index1: number) => {
        const updatedInputs = conditions.map((input2: any, index2: number) => {
            if (index1 == index2) {
                return input1
            } else {
                return input2
            }
        })

        setConditions(updatedInputs)

        onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[level],
            displayName: name,
            conditions: updatedInputs,
        })
    }

    const handleInputDelete = (index1) => {
        const updatedInputs = conditions.filter((_: any, index2: number) => index1 != index2)

        setConditions(updatedInputs)

        onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[level],
            displayName: name,
            conditions: updatedInputs,
        })
    }

    const handleNameUpdate = (name: string) => {
        if (name === undefined || name === null) return
        if (rule.displayName == name) return

        setName(name)
        setScopedName(name)

        onUpdate({
            level: CHANNEL_MAPPER_V1.LEVELS[level],
            displayName: name,
            conditions,
        })
    }

    useEffect(() => {
        eventEmitter.on(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ALL, handlePubSubMessageExpandAll)
        eventEmitter.on(PUB_SUB.CHANNEL_MAPPER_V1.COLLAPSE_ALL, handlePubSubMessageCollapseAll)
        eventEmitter.on(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ONE, handlePubSubMessageExpandOne)

        return () => {
            eventEmitter.removeListener(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ALL, handlePubSubMessageExpandAll)
            eventEmitter.removeListener(PUB_SUB.CHANNEL_MAPPER_V1.COLLAPSE_ALL, handlePubSubMessageCollapseAll)
            eventEmitter.removeListener(PUB_SUB.CHANNEL_MAPPER_V1.EXPAND_ONE, handlePubSubMessageExpandOne)
        }
    })

    // This is for when the rule is updated from via props.
    // This happens when the rule is updated from the parent component.
    // Also used to set the initial state of the rule
    useEffect(() => {
        setConditions(rule.conditions)
        setLevel(CHANNEL_MAPPER_V1.LEVELS.findIndex((l: string) => l == rule.level))
        setName(rule.displayName)
        setScopedName(rule.displayName)
    }, [rule])

    // Triggered when the rule is added to the list
    useEffect(() => {
        if (rule.conditions?.length > 0) return
        if (rule.level == '') return
        if (rule.displayName == '') return

        const { displayName, conditions, level } = rule

        setConditions(conditions)
        setLevel(CHANNEL_MAPPER_V1.LEVELS.findIndex((l: string) => l == level))
        setName(displayName)
        setScopedName(displayName)
    }, [index])

    return (
        <Panel width="100%" class={classes}>
            <View align="flex-start">
                <If if={validation}>
                    <View class="mb-300">
                        {ruleValidations.map((validation: string, index: number) => (
                            <Text class="cl-red" small key={index}>
                                {validation}
                            </Text>
                        ))}
                    </View>
                </If>

                <View flex={1} row>
                    <View column flex="none" class="mr-500">
                        <View onClick={onUp} class="buttonize" style={{ cursor: 'pointer' }}>
                            <Icon icon="chevron-up" color={COLOR.COLOR_GRAY_400} size={15} />
                        </View>
                        <View onClick={onDown} class="buttonize" style={{ cursor: 'pointer' }}>
                            <Icon icon="chevron-down" color={COLOR.COLOR_GRAY_400} size={15} />
                        </View>
                    </View>

                    <Text class={textClasses} small>
                        #{position + 1}
                    </Text>

                    <View row flex="none">
                        <Button onClick={() => setShowInputs(!showInputs)} class="mr-500" light>
                            <ButtonIcon icon={!!showInputs ? 'chevron-down' : 'chevron-right'} />
                        </Button>
                    </View>

                    <View flex={1} class="ml-100">
                        <Select width="100%" placeholder="None selected" selected={level} onSelect={handleLevelUpdate}>
                            {CHANNEL_MAPPER_V1.LEVELS.map((type: any, index: number) => (
                                <SelectOption key={index}>{type}</SelectOption>
                            ))}
                        </Select>
                    </View>

                    <View flex={4} class="ml-900">
                        <Input
                            autoFocus={false}
                            type="text"
                            placeholder="Name"
                            value={scopedName}
                            onChange={(value: string) => setScopedName(value)}
                            onBlur={handleNameUpdate}
                        />
                    </View>

                    <View row flex="none" class="ml-900">
                        <Button onClick={handleDelete} class="ml-500" light>
                            <ButtonIcon icon="minus" />
                        </Button>

                        <Button onClick={onAdd} class="ml-100" light>
                            <ButtonIcon icon="plus" />
                        </Button>

                        <Button onClick={onDuplicate} class="ml-100" light>
                            <ButtonIcon icon="copy" />
                        </Button>
                    </View>
                </View>
                {!!showInputs && (
                    <View column class="mt-500">
                        {conditions.map((input: any, index: number) => {
                            return (
                                <InputRow
                                    key={input + index}
                                    input={input}
                                    onAdd={() => handleInputAdd(index)}
                                    onUpdate={(input: any) => handleInputUpdate(input, index)}
                                    onDelete={() => {
                                        if (conditions.length == 1) {
                                            alert('You must have at least 1 input for a rule.')
                                        } else {
                                            handleInputDelete(index)
                                        }
                                    }}
                                />
                            )
                        })}
                    </View>
                )}
            </View>
        </Panel>
    )
}

export default Rule
