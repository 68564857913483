import { useAuth0 } from '@auth0/auth0-react'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import './external.component.sass'

interface IExternalComponent {
    children?: any
}

export const ExternalComponent: FunctionComponent = (props: IExternalComponent): ReactElement => {
    const queryString = window.location.search.split('=')
    const { user, getAccessTokenSilently } = useAuth0()

    if (queryString.length != 2) return null

    return (
        <div className="external-component">
            <iframe src={queryString[1]} />
        </div>
    )
}
