import { Button, ButtonIcon, ButtonText, Flexer, Label, Tooltip, View } from '@adtriba/ui'
import React, { useState } from 'react'
import { POSITION } from '../../../../constants'
import { Ruleset } from './spend-mapper-v3-ruleset'
import './spend-mapper-v3.component.sass'

export const SourceRuleset = (props: any) => {
    const { mapped } = props
    const [modal, setModal] = useState(false)

    return (
        <>
            <Ruleset
                visible={modal}
                channel={props.channel}
                source={props.source}
                onMap={() => {
                    setModal(false)
                    props.onMap()
                }}
                onClose={() => setModal(false)}
            />
            <View row justify="flex-start">
                {mapped && (
                    <Label solidElectric class="mr-500" icon="check" style={{ width: 50 }}>
                        Mapped
                    </Label>
                )}

                {!mapped && (
                    <Tooltip text="This source needs to be mapped first." position={POSITION.TOP}>
                        <Label red class="mr-500" style={{ width: 80 }}>
                            Mapping Required
                        </Label>
                    </Tooltip>
                )}
                <Flexer />
                <Button xSmall onClick={() => setModal(true)} light>
                    <ButtonIcon icon="pen" />
                    <ButtonText>EDIT</ButtonText>
                </Button>
            </View>
        </>
    )
}
