import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { UserService } from '../modules/app/services/user.service'

const useVerifyEmail = () => {
    const { getAccessTokenSilently: auth, logout } = useAuth0()
    const [error, setError] = useState<any>({})
    const [loading, setLoading] = useState(false)

    const verifyUserEmail = async (
        auth: any,
        onSuccess: (result: any) => any,
        onError?: (error: any) => any,
        onLoading?: (loading: boolean) => any
    ): Promise<any> => {
        try {
            const token = await auth()
            onLoading(true)
            const result = await UserService.postVerificationEmail(token)
            onLoading(false)
            return onSuccess(result)
        } catch (error: any) {
            console.error(error)
            return onError(error)
        } finally {
            setLoading(false)
        }
    }

    const handleLogout = () => {
        logout()
    }

    const verifyEmail = async () => {
        verifyUserEmail(auth, handleLogout, setError, setLoading)
    }

    return {
        error,
        loading,
        verifyEmail,
    }
}

export default useVerifyEmail
