import { returnParser } from '../../../helpers/util'

export class ReportService {
    static async get(token: string, accountId: string, projectId: string): Promise<any> {
        const result: any = await fetch(
            `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects/${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            }
        )
        return await returnParser(result)
    }
}
