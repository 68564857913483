import React, { useEffect, useState, useContext, FunctionComponent, ReactElement } from 'react'
import { If, Notification, NotificationText } from '@adtriba/ui'

interface INotificationComponent {
    notification?: string
    error?: string
    children?: any
}

export const NotificationComponent = (props: INotificationComponent): ReactElement => {
    return (
        <>
            <If if={!!props.error}>
                <Notification danger class="mb-500">
                    <NotificationText>{props.error}</NotificationText>
                </Notification>
            </If>
            <If if={!!props.notification}>
                <Notification info class="mb-500">
                    <NotificationText>{props.notification}</NotificationText>
                </Notification>
            </If>
        </>
    )
}
