import React, { FunctionComponent, ReactElement } from 'react'
import { COLOR, Icon, Text, View } from '@adtriba/ui'

interface ILimitedComponent {
    children?: any
    style?: any
    onClick: any
}

export const LimitedComponent = (props: ILimitedComponent) => {
    return (
        <View class="buttonize p-absolute top-0 right-0" style={{ zIndex: 10 }} onClick={props.onClick} row>
            <Text small class="cl-gray-400">
                What's this?&nbsp;
            </Text>
            <Icon icon="info" size={14} color={COLOR.COLOR_GRAY_400} />
        </View>
    )
}
