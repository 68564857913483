import { ADTRIBA_SM3_CACHE } from '../../../constants'
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type GetCampaignTable = {
    channel: string
    source: string
    project_id: string
    mapping_state: string
}

export type GetAdPlatform = {
    channel: string
    source: string
    project_id: string
}

export type GetSourceTable = {
    channel: string
    project_id: string
    mapping_state: string
}

export type GetUnmappedSourceTable = {
    project_id: string
}

export type GetUnmappedSpend = {
    project_id: string
}

export type GetChannelTable = {
    project_id: string
}

export type GetFilterValues = {
    project_id: string
}

export type GetRulesets = {
    channel: string
    source: string
    project_id: string
}

export type PutRulesets = {
    channel: string
    source: string
    project_id: string
}

export type DeleteRulesets = {
    channel: string
    source: string
    project_id: string
}

export type GetDataCheck = {
    project_id: string
}

export type PutCampaignMappings = {}

export class SpendMapperService {
    static async postCampaignMappingsValidate(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: PutCampaignMappings,
        campaigns: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/mappings/validate?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(campaigns),
        })
        return await returnParser(result)
    }

    static async putCampaignMappings(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: PutCampaignMappings,
        campaigns: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/mappings?${queryString}`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(campaigns),
        })
        return await returnParser(result)
    }

    static async getDataCheck(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetDataCheck
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/data/check?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getCampaignTable(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetCampaignTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaign-table?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getCampaignsAdPlatform(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetAdPlatform
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/ad-platform?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getCampaignsUnmappedSpend(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters?: GetUnmappedSpend
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/unmapped-spend${
            queryString && '?' + queryString
        }`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getChannelTable(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetChannelTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/channel-table?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getFilterValues(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetFilterValues
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/filter-values?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getRulesets(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetRulesets
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async putRulesets(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: PutRulesets,
        rules: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`
        const result: any = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(rules),
        })
        return await returnParser(result)
    }

    static async postRulesetsValidate(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: PutRulesets,
        rules: any
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets/validate?${queryString}`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(rules),
        })
        return await returnParser(result)
    }

    static async deleteRulesets(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: DeleteRulesets
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getSourceTable(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetSourceTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/source-table?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getUnmappedSourceTable(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetUnmappedSourceTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/unmapped-spend/table?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }
}

export const SpendMapperCacheService = {
    init: () => (window[ADTRIBA_SM3_CACHE] = { sources: [], campaigns: [] }),
    checkSource: (source: string) => window[ADTRIBA_SM3_CACHE].sources.includes(source),
    cacheSource: (source: string) => {
        const cache = Array.from(window[ADTRIBA_SM3_CACHE].sources)
        cache.push(source)
        window[ADTRIBA_SM3_CACHE].sources = cache
    },
    checkCampaign: (campaign: string) => window[ADTRIBA_SM3_CACHE].campaigns.includes(campaign),
    cacheCampaigns: (raw: [[]]) => {
        // Always use the existing set
        const cache = new Set<string>(window[ADTRIBA_SM3_CACHE].campaigns)

        // Go through the comma delimited list
        raw.map((campaign: string[]) => campaign.map((id: string) => cache.add(id)))

        // Resave the cache
        window[ADTRIBA_SM3_CACHE].campaigns = Array.from(cache)
    },
}
