 
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type GetOriginCampaigns = {
    contains?: string
    count: number
    mapping_type: string
    order_by: string
    page: number
}

export type GetAdtribaCampaigns = {
    exclude_channels: string[]
}

export type GetCampaignRecommendations = {
    origin_campaign_ids: string[]
}

export type PostMappings = {
    adtriba_campaign_id: string
    id: string
    origin_campaign_id: string
}

export type PostOriginCampaignStatus = {
    campaign_id: string
    hidden: boolean
}

export class SpendMapperService {
    static async getOriginCampaigns(
        token: string,
        accountId: string,
        queryStringParameters: GetOriginCampaigns
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend_mapper/origin_campaigns?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getAdtribaCampaigns(
        token: string,
        accountId: string,
        queryStringParameters: GetAdtribaCampaigns
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/adtriba_campaigns?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getAdtribaCampaignsChannel(token: string, accountId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/adtriba_campaigns/filter/channel`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async getCampaignRecommendations(
        token: string,
        accountId: string,
        queryStringParameters: GetCampaignRecommendations
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/campaign_recommendations?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async postMappings(token: string, accountId: string, mappings: PostMappings): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/mappings`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(mappings),
        })
        return await returnParser(result)
    }

    static async deleteMappings(token: string, accountId: string, mappingId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/mappings/${mappingId}`
        const result: any = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async postOriginCampaignStatus(
        token: string,
        accountId: string,
        status: PostOriginCampaignStatus
    ): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/spend-mapper/origin_campaigns/status`
        const result: any = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(status),
        })
        return await returnParser(result)
    }
}
