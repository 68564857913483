import { Button, ButtonIcon, ButtonText, Panel, View } from '@adtriba/ui'
import React, { useEffect, useRef } from 'react'
import { DialogComponent } from './dialog.component'

interface VerificationErrorDialogProps {
    show: boolean
    verifyEmail: () => void
    logout: () => void
    user: any
}

const VerificationErrorDialog = ({ show, verifyEmail, logout, user }: VerificationErrorDialogProps) => {
    const ref = useRef<HTMLDialogElement>(null)

    useEffect(() => {
        if (show) {
            ref.current?.showModal()
        } else {
            ref.current?.close()
        }
    }, [show])

    return (
        <DialogComponent ref={ref}>
            <View class="p-fixed top-0 left-0" width="100%" height="100%" row style={{ zIndex: 10 }}>
                <Panel
                    width={700}
                    innerClass="d-flex flex-column gap-6 pb-0"
                    style={{ padding: '1.5rem', boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.05)' }}
                >
                    <div
                        className="gap-4 pb-4 d-flex flex-column"
                        style={{
                            paddingBottom: '0',
                        }}
                    >
                        <h2
                            style={{
                                fontWeight: 600,
                            }}
                        >
                            Please verify your email address
                        </h2>
                        <div className="gap-4 d-flex align-items-center">
                            <img src={'/favicon.png'} height={20} alt="Adtriba Accounts" />
                            <h3
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                Welcome to Adtriba Core
                            </h3>
                        </div>
                        <p>
                            We've noticed that your email address,{' '}
                            <b
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                {user.email}
                            </b>
                            , is not verified.
                        </p>
                        <p>
                            You've already received an email to verify your email address. Please check your inbox.
                            Don't find the email? Please check your spam folder to make sure it didn't end up there.
                        </p>
                        <p>
                            By clicking the button below, you will receive a new confirmation email. Follow the
                            instructions in the email, in order to complete the process.
                        </p>
                        <p>
                            If you run into any trouble verifying your email address, please contact:
                            <br />
                            <a href="mailto:support@adtriba.com">
                                <b
                                    style={{
                                        fontWeight: 600,
                                        color: '#1CC7D0',
                                    }}
                                >
                                    support@adtriba.com
                                </b>
                            </a>
                        </p>
                        <p>By clicking any of the buttons, you will be logged out.</p>
                    </div>
                    <div className="gap-4 d-flex column">
                        <Button onClick={() => verifyEmail()}>
                            <ButtonText>Send confirmation email</ButtonText>
                            <ButtonIcon icon="reply" />
                        </Button>
                        <Button onClick={() => logout()}>
                            <ButtonText>Logout</ButtonText>
                        </Button>
                    </div>
                </Panel>
            </View>
        </DialogComponent>
    )
}

export default VerificationErrorDialog
