import { returnParser } from '../../../helpers/util'

export class ProjectService {
    static async get(token: string, accountId: string): Promise<any> {
        const result: any = await fetch(
            `${process.env.REACT_APP_API_HOST_V2}/accounts/${accountId}/projects?status=enabled`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            }
        )
        return await returnParser(result)
    }
}
