import {
    Button,
    ButtonIcon,
    Delta,
    Filter,
    Flexer,
    Heading,
    Icon,
    If,
    Label,
    LineChartDual,
    Metric,
    Option,
    Options,
    Panel,
    Popover,
    Select,
    SelectOption,
    Table,
    Text,
    Toggle,
    Trend,
    View,
} from '@adtriba/ui'
import { ILineChartLine } from '@adtriba/ui/lib/line-chart/line-chart'
import { useAuth0 } from '@auth0/auth0-react'
import numeral from 'numeral'
import posthog from 'posthog-js'
import queryString from 'query-string'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { LimitedComponent } from '../../../../components/limited/limited.component'
import { NoReportComponent } from '../../../../components/no-report/no-report.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    CHART_TIME_PRESETS,
    COLOR,
    COLORS,
    COMPARISON_LEVELS,
    DATE_FORMAT_API,
    DEFAULT_TABLE,
    ERROR,
    FILTER,
    INSIGHTS,
    NEW_RETURNING,
    NUMERAL,
    PERFORMANCE_LEVELS,
    POPOVER,
    STORAGE,
    TABLE,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import {
    findIndexHash,
    getCacheKey,
    getCachedFilterRules,
    getChartTimePreset,
    getFilterOption,
    getFilterOptionHash,
    getPropertyNameAtIndex,
    getSymbolFromCurrency,
    logError,
    sanitizeLocale,
    sanitizeNumber,
    setLocale,
    validateFilterRows,
} from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { FilterOption, PerformanceService } from '../../services/performance-v2.service'
import { QuickInsightsComponent } from '../insights/insights.component'
import './performance.component.sass'

interface IPerformanceComponent {
    children?: any
}

export const PerformanceComponent: FunctionComponent = (props: IPerformanceComponent): ReactElement => {
    const { getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorNoReport, setErrorNoReport] = useState(null)
    const [notification, setNotification] = useState(null)
    const { accountId, projectId, reportId } = useParams()
    const { search } = useLocation()
    const { channel_hash, source_hash, campaign_hash } = queryString.parse(search)
    const [channelsSelected, setChannelsSelected] = useState([])
    const [sourcesSelected, setSourcesSelected] = useState([])
    const [campaignsSelected, setCampaignsSelected] = useState([])
    const [channelsSelectedCache, setChannelsSelectedCache] = useState([])
    const [sourcesSelectedCache, setSourcesSelectedCache] = useState([])
    const [campaignsSelectedCache, setCampaignsSelectedCache] = useState([])
    const [filterChannels, setFilterChannels] = useState([])
    const [filterSources, setFilterSources] = useState([])
    const [filterCampaigns, setFilterCampaigns] = useState([])
    const [customerType, setCustomerType] = useState<any>(NEW_RETURNING.ALL)
    const [metrics, setMetrics] = useState<any>({ total: {}, percent: {}, colors: {} })
    const [lastclickTableData, setLastclickTableData] = useState<any>(DEFAULT_TABLE)
    const [timeframe, setTimeframe] = useState<any>(CHART_TIME_PRESETS[0])
    const [linechartData, setLinechartData] = useState<ILineChartLine[]>([])
    const [insightsModal, setInsightsModal] = useState(false)
    const [level, setLevel] = useState(PERFORMANCE_LEVELS.CHANNEL)
    const [levelComparison, setLevelComparison] = useState(COMPARISON_LEVELS.CHANNEL)
    const [channelLevelComparison, setChannelLevelComparison] = useState(null)
    const [sourceLevelComparison, setSourceLevelComparison] = useState(null)
    const [channelLevel, setChannelLevel] = useState(null)
    const [sourceLevel, setSourceLevel] = useState(null)
    const [performanceData, setPerformanceData] = useState([])
    const [comparisonData, setComparisonData] = useState([])
    const [performanceTableData, setPerformanceTableData] = useState<any>(DEFAULT_TABLE)
    const [comparisonTableData, setComparisonTableData] = useState<any>(DEFAULT_TABLE)
    const [loadingFilters, setLoadingFilters] = useState(null)
    const [loadingComparisonTableData, setLoadingComparisonTableData] = useState(null)
    const [loadingPerformanceTableData, setLoadingPerformanceTableData] = useState(null)
    const [loadingTimeline, setLoadingTimeline] = useState(null)
    const [loadingTotals, setLoadingTotals] = useState(null)
    const [loadingLastClickValidation, setLoadingLastClickValidation] = useState(null)
    const [advanced, setAdvanced] = useState(false)
    const defaultFilterRules = [{ option: 0, comparison: FILTER.COMPARISONS[0], value: '', logic: '' }]
    const [filter, setFilter] = useState(defaultFilterRules)
    const filterOptions = [
        { label: 'Channel', value: 'channel' },
        { label: 'Source', value: 'source' },
        { label: 'Campaign', value: 'campaign' },
    ]
    const [limited, setLimited] = useState<any>({
        channels: false,
        sources: false,
        campaigns: false,
    })
    const [noReturningData, setNoReturningData] = useState(false)
    const accountHasCustomerTypeEnabled = [
        '5116ae2f-286d-4151-97a8-f297cda06c5c',
        'ec4b81d6-a4b3-41d1-9331-6b3bdf133c53',
        '73ffa578-03f9-4901-a1fa-7f455b3cbb52',
        'bdea9794-7d37-479c-86a9-02b92fd4cb87',
        '2873ec17-a399-422a-8ca4-699a6e147990',
        'c1f0279b-06d0-40ec-bd39-831bf682c1de',
        '6d185685-cc14-4411-98b6-a3e83b81d67e',
        '270b5bde-0654-45f6-b98c-5ba9984720ae',
        '7a26dcfe-f63f-4f01-8ef4-262ed27cd5a6',
        '2cf50c18-01b0-4eff-9702-3fbb5b9f4342'
    ].includes(accountId)
    const isOttoProject =
        accountId == 'd4dfd21e-c37b-493d-9c72-8b2bef8e16a5' && projectId == '64419b43-5b02-41e2-924e-aad55756f8de'
    const customerTypeDisabled = !(accountHasCustomerTypeEnabled || isOttoProject)
    const selectedProject = app.selectedAccount.find((project: any) => project.id == projectId)

    const getCustomerType = () => {
        switch (customerType) {
            case NEW_RETURNING.NEW:
                return 'new'
            case NEW_RETURNING.RETURNING:
                return 'returning'
            default:
                return null
        }
    }

    const getLimitedSelectLabel = (text: string, limited: boolean) => {
        if (limited) {
            return text + ' (limited)'
        } else {
            return text
        }
    }

    const handleLimitedClick = () => {
        window.open('https://help.adtriba.com/en/collections/2825246-adtriba-core')
    }

    const handleToggleAdvanced = () => {
        const defaultFilterGroup = [{ logic: '', rows: [] }]
        if (advanced) {
            if (filter.length == 1) {
                if (filter[0].value != '') handleFilterChange(defaultFilterGroup)
            } else if (filter.length > 1) {
                handleFilterChange(defaultFilterGroup)
            }
        }
        setAdvanced(!advanced)
    }

    const resetSelectFilters = () => {
        // Don't reset anything here if it's on the initial state
        // Only causes a unnecessary render
        if (
            channelsSelected.length == 0 &&
            sourcesSelected.length == 0 &&
            campaignsSelected.length == 0 &&
            channelsSelectedCache.length == 0 &&
            sourcesSelectedCache.length == 0 &&
            campaignsSelectedCache.length == 0
        )
            return

        setChannelsSelected([])
        setSourcesSelected([])
        setCampaignsSelected([])
        setChannelsSelectedCache([])
        setSourcesSelectedCache([])
        setCampaignsSelectedCache([])
    }

    const resetFilters = () => {
        resetSelectFilters()
        setFilter(defaultFilterRules)
    }

    const getFilters = (): FilterOption[] => {
        let options: FilterOption[] = []
        const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.PERFORMANCE)

        // Always used cache values as source of truth
        if (cachedFilterRules) {
            if (cachedFilterRules.length > 0) {
                options = cachedFilterRules.map((filterRow: any) => ({
                    field: filterOptions[filterRow.option].value,
                    operator: filterRow.comparison,
                    value: filterRow.value,
                }))
            }
        } else {
            const channels = getFilterOptionHash(channelsSelected, filterChannels)
            const sources = getFilterOptionHash(sourcesSelected, filterSources)
            const campaigns = getFilterOptionHash(campaignsSelected, filterCampaigns)

            if (channels != '') options.push(getFilterOption('channel', channels))
            if (sources != '') options.push(getFilterOption('source', sources))
            if (campaigns != '') options.push(getFilterOption('campaign', campaigns))
        }

        return options
    }

    const handleError = (e) => {
        if (e == 204 || e == 400) {
            setLoadingPerformanceTableData(false)
            setLoadingComparisonTableData(false)
            setLoadingFilters(false)
            setLoadingTimeline(false)
            setLoadingTotals(false)
            setLoadingLastClickValidation(false)
            setNotification(null)
            setError(null)
            setErrorNoReport(ERROR.API.NO_REPORT_DATA)
        } else {
            setLoadingPerformanceTableData(false)
            setLoadingComparisonTableData(false)
            setLoadingFilters(false)
            setLoadingTimeline(false)
            setLoadingTotals(false)
            setLoadingLastClickValidation(false)
            setNotification(null)
            setError(ERROR.API.GENERAL)
            setErrorNoReport(null)
        }
    }

    const handleFilterUpdate = () => {
        setChannelsSelected(channelsSelectedCache)
        setSourcesSelected(sourcesSelectedCache)
        setCampaignsSelected(campaignsSelectedCache)
    }

    const handleFilterChange = (groups: any) => {
        const rules = groups[0].rows

        if (rules.length == 0) {
            StorageService.deleteStorage(getCacheKey(reportId, STORAGE.PERFORMANCE))
            resetSelectFilters()
            setFilter([])
        } else {
            if (validateFilterRows(rules)) {
                StorageService.setStorage(getCacheKey(reportId, STORAGE.PERFORMANCE), JSON.stringify(rules))
                resetSelectFilters()
                setFilter(rules)
            }
        }
    }

    const fetchFilters = async () => {
        setLoadingFilters(true)
        setNotification(null)
        setError(null)
        setErrorNoReport(null)

        resetFilters()

        try {
            if (!reportId || reportId == 'null' || reportId == null) {
                setErrorNoReport(ERROR.API.NO_REPORT_FOR_PROJECT)
                throw new Error(ERROR.API.NO_REPORT_FOR_PROJECT)
            }
            const cachedFilterRules = getCachedFilterRules(reportId, STORAGE.PERFORMANCE)
            const token = await getAccessTokenSilently()
            const resultChannels = await PerformanceService.getFilter(
                token,
                accountId,
                projectId,
                reportId,
                PERFORMANCE_LEVELS.CHANNEL,
                getCustomerType()
            )
            const resultSources = await PerformanceService.getFilter(
                token,
                accountId,
                projectId,
                reportId,
                PERFORMANCE_LEVELS.SOURCE,
                getCustomerType()
            )
            const resultCampaigns = await PerformanceService.getFilter(
                token,
                accountId,
                projectId,
                reportId,
                PERFORMANCE_LEVELS.CAMPAIGN,
                getCustomerType()
            )

            if (cachedFilterRules) {
                setFilter(cachedFilterRules)
                setAdvanced(true)
            }

            setFilterChannels(resultChannels.filters.filter((f: any) => !!f.label))
            setFilterSources(resultSources.filters.filter((f: any) => !!f.label))
            setFilterCampaigns(resultCampaigns.filters.filter((f: any) => !!f.label))

            // Set if there are limited results for filters
            setLimited({
                channels: !!resultChannels.limited,
                sources: !!resultSources.limited,
                campaigns: !!resultCampaigns.limited,
            })

            setLoadingFilters(false)
        } catch (e) {
            logError('fetchFilters', e)
            handleError(e)
        }
    }

    const fetchTimeline = async () => {
        setLoadingTimeline(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const resultTimelineRoas = await PerformanceService.getTimeline(
                token,
                accountId,
                projectId,
                reportId,
                'roas',
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    customer_type: getCustomerType(),
                }
            )
            const resultTimelineSpend = await PerformanceService.getTimeline(
                token,
                accountId,
                projectId,
                reportId,
                'spend',
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    customer_type: getCustomerType(),
                }
            )

            setLoadingTimeline(false)
            setLinechartData([
                {
                    legend: 'ROAS',
                    color: COLOR.COLOR_ELECTRIC,
                    points: resultTimelineRoas.map((data: any) => [data.date, data.value]),
                },
                {
                    legend: 'Spend',
                    color: COLOR.COLOR_MAGENTA,
                    points: resultTimelineSpend.map((data: any) => [data.date, data.value]),
                },
            ])
        } catch (e) {
            logError('fetchTimeline', e)
            handleError(e)
        }
    }

    const fetchTotals = async () => {
        setLoadingTotals(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const { conversions, conversions_clv, cpas, revenue, roas, spend } = await PerformanceService.getTotals(
                token,
                accountId,
                projectId,
                reportId,
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    customer_type: getCustomerType(),
                }
            )

            setLoadingTotals(false)
            setMetrics({
                total: {
                    conversions: conversions.value,
                    revenue: revenue.value,
                    clv: conversions_clv.value,
                    spend: spend.value,
                    cpa: cpas.value,
                    roas: roas.value,
                },
                percent: {
                    conversions: conversions.delta / 100,
                    revenue: revenue.delta / 100,
                    clv: conversions_clv.delta / 100,
                    spend: spend.delta / 100,
                    cpa: cpas.delta / 100,
                    roas: roas.delta / 100,
                },
                colors: {
                    conversions: conversions.delta == 0 ? null : conversions.delta > 0 ? COLORS.GREEN : COLORS.RED,
                    revenue: revenue.delta == 0 ? null : revenue.delta > 0 ? COLORS.GREEN : COLORS.RED,
                    clv: conversions_clv.delta == 0 ? null : conversions_clv.delta > 0 ? COLORS.GREEN : COLORS.RED,
                    spend: spend.delta == 0 ? null : spend.delta > 0 ? COLORS.GREEN : COLORS.RED,
                    cpa: cpas.delta == 0 ? null : cpas.delta > 0 ? COLORS.RED : COLORS.GREEN,
                    roas: roas.delta == 0 ? null : roas.delta > 0 ? COLORS.GREEN : COLORS.RED,
                },
            })
        } catch (e) {
            logError('fetchTotals', e)
            handleError(e)
        }
    }

    const fetchLastClickValidation = async () => {
        setLoadingLastClickValidation(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const resultLastClickValidation = await PerformanceService.getLastClickValidation(
                token,
                accountId,
                projectId,
                reportId,
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    customer_type: getCustomerType(),
                }
            )

            setLoadingLastClickValidation(false)
            setLastclickTableData([
                {
                    lastDataSet: true,
                    heading: 'Last click validation',
                    headings: ['Channel', 'Conversion Last Click'],
                    align: [null, TABLE.ALIGN.RIGHT],
                    formats: [null, (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC)],
                    data: resultLastClickValidation.map((data: any, index: number) => [data.channel, data.conversions]),
                },
            ])
        } catch (e) {
            logError('fetchLastClickValidation', e)
            handleError(e)
        }
    }

    const fetchComparisonTableData = async () => {
        setLoadingComparisonTableData(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const channelLevelHash = channelLevelComparison ? channelLevelComparison.hash : null
            const sourceLevelHash = sourceLevelComparison ? sourceLevelComparison.hash : null
            const resultTable = await PerformanceService.getComparisonTable(
                token,
                accountId,
                projectId,
                reportId,
                levelComparison,
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    channel_level_hash: channelLevelHash,
                    source_level_hash: sourceLevelHash,
                    customer_type: getCustomerType(),
                }
            )

            const getHeading = (levelComparison: string) => {
                switch (levelComparison) {
                    case COMPARISON_LEVELS.CHANNEL:
                        return 'CLV vs. Regular MTA Comparison'
                    case COMPARISON_LEVELS.SOURCE:
                        return channelLevelComparison.label
                    case COMPARISON_LEVELS.CAMPAIGN:
                        return sourceLevelComparison.label
                    default:
                        return ''
                }
            }

            const getTableHeading = (levelComparison: string) => {
                switch (levelComparison) {
                    case COMPARISON_LEVELS.CHANNEL:
                        return 'Channel'
                    case COMPARISON_LEVELS.SOURCE:
                        return 'Source'
                    case COMPARISON_LEVELS.CAMPAIGN:
                        return 'Campaign'
                    default:
                        return ''
                }
            }

            const getComparisonTableHeadings = (levelComparison: string, accountId: string) => {
                switch (accountId) {
                    case 'abb4659a-3d04-11ee-91f0-eb4c1d749b4d':
                        return [
                            getTableHeading(levelComparison),
                            'Conversions CLV MTA',
                            'Conversions Regular MTA',
                            'Conversion Delta',
                            '',
                            'Delta',
                            '',
                        ]
                    default:
                        return [
                            getTableHeading(levelComparison),
                            'Conversions Regular MTA',
                            'Conversions CLV MTA',
                            'Conversion Delta',
                            '',
                            'Delta',
                            '',
                        ]
                }
            }

            const highestDelta = Math.max.apply(
                Math,
                resultTable.map((value: any) => value.delta || 0).map((value: any) => (value < 0 ? value * -1 : value))
            )

            const tableData = {
                lastDataSet: levelComparison == COMPARISON_LEVELS.CAMPAIGN,
                heading: getHeading(levelComparison),
                headings: getComparisonTableHeadings(levelComparison, accountId),
                align: [
                    null,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    TABLE.ALIGN.RIGHT,
                    null,
                ],
                formats: [
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    (val) => numeral(val).format(NUMERAL.FORMATS.PERCENT),
                    null,
                    null,
                    TABLE.HIDDEN,
                ],
                data: [
                    ...resultTable.map((data: any, index: number) => {
                        const deltaValue: number = Number(data.delta)
                        const isPositive: boolean = deltaValue >= 0
                        const deltaPositive: number = !isPositive ? 0 : deltaValue / highestDelta
                        const deltaNegative: number = isPositive ? 0 : deltaValue / (highestDelta * 1)

                        return [
                            data.label,
                            data.conversions_regular_mta,
                            data.conversions_clv_mta,
                            data.conversion_delta / 100,
                            <View class="mr-900" style={{ marginLeft: -15 }}>
                                <Trend
                                    up={data.delta > 0}
                                    down={data.delta < 0}
                                    small
                                    green={data.delta > 0}
                                    red={data.delta < 0}
                                />
                            </View>,
                            <View width={100} row justify="flex-end">
                                <Delta positive={deltaPositive} negative={deltaNegative} />
                            </View>,
                            data.hash,
                        ]
                    }),
                ],
            }

            // Because the table is connected to everything else in the UI
            // We can't manage the data on a table-level
            // So here we organise the table's data pages to have the right orders
            switch (levelComparison) {
                case COMPARISON_LEVELS.CHANNEL:
                    setComparisonTableData([tableData])
                    break
                case COMPARISON_LEVELS.SOURCE:
                    setComparisonTableData([comparisonTableData[0], tableData])
                    break
                case COMPARISON_LEVELS.CAMPAIGN:
                    setComparisonTableData([comparisonTableData[0], comparisonTableData[1], tableData])
                    break
            }

            // Now set the data here because it's what we use to get the hash
            // So always keep this updated
            setComparisonData([...resultTable])
            setLoadingComparisonTableData(false)
            setNoReturningData(customerType == NEW_RETURNING.RETURNING && resultTable.length == 0)
        } catch (e) {
            logError('fetchComparisonTableData', e)
            handleError(e)
        }
    }

    const fetchPerformanceTableData = async () => {
        setLoadingPerformanceTableData(true)
        setError(null)
        setErrorNoReport(null)
        setNotification(null)

        try {
            if (!reportId || reportId == 'null' || reportId == null) {
                setErrorNoReport(ERROR.API.NO_REPORT_FOR_PROJECT)
                throw new Error(ERROR.API.NO_REPORT_FOR_PROJECT)
            }
            const token = await getAccessTokenSilently()
            const dateEnd = app.toDate.format(DATE_FORMAT_API)
            const dateStart = app.fromDate.format(DATE_FORMAT_API)
            const channelLevelHash = channelLevel ? channelLevel.hash : null
            const sourceLevelHash = sourceLevel ? sourceLevel.hash : null
            const resultTable = await PerformanceService.getTable(
                token,
                accountId,
                projectId,
                reportId,
                level,
                getFilters(),
                {
                    date_end: dateEnd,
                    date_start: dateStart,
                    channel_level_hash: channelLevelHash,
                    source_level_hash: sourceLevelHash,
                    customer_type: getCustomerType(),
                }
            )

            // Get the average CPA for the table
            const averageCPA = resultTable.reduce((acc: number, data: any) => {
                if (data.cpa) {
                    return acc + data.cpa / resultTable.length
                } else {
                    return acc
                }
            }, 0)

            const reportHasRevenue = resultTable.reduce((acc: number, data: any): boolean => {
                if (data.revenue) {
                    return acc + data.revenue > 0
                } else {
                    return false
                }
            }, 0)

            const getHeading = (level: string) => {
                switch (level) {
                    case PERFORMANCE_LEVELS.CHANNEL:
                        return 'Channels'
                    case PERFORMANCE_LEVELS.SOURCE:
                        return channelLevel.label
                    case PERFORMANCE_LEVELS.CAMPAIGN:
                        return sourceLevel.label
                    default:
                        return ''
                }
            }

            const getTableHeading = (level: string) => {
                switch (level) {
                    case PERFORMANCE_LEVELS.CHANNEL:
                        return 'Channel'
                    case PERFORMANCE_LEVELS.SOURCE:
                        return 'Source'
                    case PERFORMANCE_LEVELS.CAMPAIGN:
                        return 'Campaign'
                    default:
                        return ''
                }
            }

            const formatROAS = (roasValue: number) => {
                if (roasValue === null) {
                    return ''
                }

                const red = reportHasRevenue && roasValue < 1
                const orange = roasValue >= 1 && roasValue < 1.5
                const green = roasValue >= 1.5
                const value = numeral(roasValue).format(NUMERAL.FORMATS.PERCENT)

                // Filter resultTable once to find the corresponding spend
                const matchingData = resultTable.find((data: any) => {
                    const roas = data.roas
                    return roas !== null && sanitizeNumber(roas) === sanitizeNumber(roasValue)
                })

                const currentSpend = matchingData && matchingData.spend

                return currentSpend ? (
                    <Label red={red} orange={orange} green={green}>
                        {value}
                    </Label>
                ) : (
                    <Label>{value}</Label>
                )
            }

            const formatCPA = (cpaValue: number) => {
                if (cpaValue === null) {
                    return ''
                }

                // Filter resultTable once to find the corresponding spend
                const matchingData = resultTable.find((data: any) => {
                    const cpa = data.cpa
                    return cpa !== null && sanitizeNumber(cpa) === sanitizeNumber(cpaValue)
                })

                const currentRoas = resultTable.indexOf(matchingData) !== -1 && matchingData.roas

                const red = reportHasRevenue ? currentRoas < 1 : cpaValue >= 2 * averageCPA
                const orange = reportHasRevenue
                    ? currentRoas >= 1 && currentRoas < 1.5
                    : cpaValue >= averageCPA && cpaValue < 2 * averageCPA
                const green = reportHasRevenue ? currentRoas >= 1.5 : cpaValue < averageCPA
                // const value = numeral(cpaValue).format(NUMERAL.FORMATS.CURRENCY)
                const value = `${getSymbolFromCurrency(selectedProject.currency ?? 'EUR')} ${numeral(cpaValue).format(
                    NUMERAL.FORMATS.CURRENCY_NO_LOCALE
                )}`

                const currentSpend = matchingData && matchingData.spend

                return currentSpend ? (
                    <Label red={red} orange={orange} green={green}>
                        {value}
                    </Label>
                ) : (
                    <Label>{value}</Label>
                )
            }

            // Format the data for the table
            const tableData = {
                lastDataSet: level == PERFORMANCE_LEVELS.CAMPAIGN,
                heading: getHeading(level),
                headings: [
                    getTableHeading(level),
                    'Conversions',
                    'Revenue',
                    'Spend',
                    'ROAS',
                    'CPA',
                    'Conversion Delta',
                    '',
                ],
                align: [
                    null,
                    TABLE.ALIGN.RIGHT, // Conversions
                    TABLE.ALIGN.RIGHT, // Revenue
                    TABLE.ALIGN.RIGHT, // Spend
                    TABLE.ALIGN.RIGHT, // ROAS
                    TABLE.ALIGN.RIGHT, // CPA
                    TABLE.ALIGN.RIGHT, // Conversion Delta
                    null,
                ],
                formats: [
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC), // Conversions
                    (val) =>
                        `${getSymbolFromCurrency(selectedProject?.currency ?? 'EUR')} ${numeral(val).format(
                            NUMERAL.FORMATS.CURRENCY_NO_LOCALE
                        )}`, // Revenue
                    (val) =>
                        `${getSymbolFromCurrency(selectedProject?.currency ?? 'EUR')} ${numeral(val).format(
                            NUMERAL.FORMATS.CURRENCY_NO_LOCALE
                        )}`, // Spend
                    (val) => formatROAS(val), // ROAS
                    (val) => formatCPA(val), // CPA
                    (val) => <>{numeral(val / 100).format(NUMERAL.FORMATS.PERCENT)}</>, // Conversion Delta
                    TABLE.HIDDEN, // Hash
                ],
                data: [
                    ...resultTable.map((data: any, index: number) => [
                        data.label,
                        sanitizeNumber(data.conversions),
                        sanitizeNumber(data.revenue),
                        sanitizeNumber(data.spend),
                        sanitizeNumber(data.roas),
                        sanitizeNumber(data.cpa),
                        sanitizeNumber(data.conversion_delta),
                        data.hash,
                    ]),
                ],
            }

            // Because the table is connected to everything else in the UI
            // We can't manage the data on a table-level
            // So here we organise the table's data pages to have the right orders
            switch (level) {
                case PERFORMANCE_LEVELS.CHANNEL:
                    setPerformanceTableData([tableData])
                    break
                case PERFORMANCE_LEVELS.SOURCE:
                    setPerformanceTableData([performanceTableData[0], tableData])
                    break
                case PERFORMANCE_LEVELS.CAMPAIGN:
                    setPerformanceTableData([performanceTableData[0], performanceTableData[1], tableData])
                    break
            }

            // Now set the data here because it's what we use to get the hash
            // So always keep this updated
            setPerformanceData([...resultTable])
            setLoadingPerformanceTableData(false)
        } catch (e) {
            logError('fetchComparisonTableData', e)
            handleError(e)
        }
    }

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId || reportId == 'null' || reportId == null) {
            setErrorNoReport(ERROR.API.NO_REPORT_FOR_PROJECT)
        }
        fetchFilters()
    }, [accountId, projectId, reportId, customerType])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        if (!reportId || reportId == 'null' || reportId == null) return

        const filterCampaignIndex = findIndexHash(filterCampaigns, campaign_hash)
        const filterSourceIndex = findIndexHash(filterSources, source_hash)
        const filterChannelIndex = findIndexHash(filterChannels, channel_hash)

        if (filterCampaignIndex != null) {
            setCampaignsSelected([filterCampaignIndex])
            setCampaignsSelectedCache([filterCampaignIndex])
        }

        if (filterSourceIndex != null) {
            setSourcesSelected([filterSourceIndex])
            setSourcesSelectedCache([filterSourceIndex])
        }

        if (filterChannelIndex != null) {
            setChannelsSelected([filterChannelIndex])
            setChannelsSelectedCache([filterChannelIndex])
        }
    }, [
        accountId,
        projectId,
        reportId,
        filterCampaigns,
        filterSources,
        filterChannels,
        channel_hash,
        source_hash,
        campaign_hash,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        // if (!reportId) return
        if (!reportId || reportId == 'null' || reportId == null) return

        fetchTimeline()
        fetchTotals()
        fetchLastClickValidation()
    }, [
        accountId,
        projectId,
        reportId,
        app.fromDate,
        app.toDate,
        channelsSelected,
        sourcesSelected,
        campaignsSelected,
        filter,
        customerType,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!projectId) return
        // if (!reportId) return
        if (!reportId || reportId == 'null' || reportId == null) return

        fetchComparisonTableData()
    }, [
        accountId,
        projectId,
        reportId,
        app.fromDate,
        app.toDate,
        channelsSelected,
        sourcesSelected,
        campaignsSelected,
        levelComparison,
        channelLevelComparison,
        sourceLevelComparison,
        filter,
        customerType,
    ])

    useEffect(() => {
        if (!accountId) return
        if (!app.selectedAccount) return
        if (!projectId) return
        // if (!reportId) return
        if (!reportId || reportId == 'null' || reportId == null) return

        fetchPerformanceTableData()
    }, [
        accountId,
        projectId,
        reportId,
        app.fromDate,
        app.toDate,
        channelsSelected,
        sourcesSelected,
        campaignsSelected,
        level,
        channelLevel,
        sourceLevel,
        filter,
        customerType,
    ])

    useEffect(() => {
        const currency = app.selectedAccount.find((project: any) => project.id == projectId)?.currency ?? 'EUR'
        setLocale(sanitizeLocale(currency))
    }, [app])

    useEffect(() => {
        setTimeframe(getChartTimePreset(app.fromDate, app.toDate))
    }, [app.fromDate, app.toDate])

    return (
        <>
            <If if={insightsModal}>
                <QuickInsightsComponent
                    accountId={accountId}
                    projectId={projectId}
                    reportId={reportId}
                    onDismiss={() => setInsightsModal(false)}
                    type={INSIGHTS.TYPES.PERFORMANCE}
                />
            </If>

            {/* <LoaderComponent loading={loading} /> */}
            <NotificationComponent notification={notification} error={error} />
            <NoReportComponent visible={errorNoReport} />

            <View class={!!errorNoReport ? 'blur' : null}>
                {/*
                Temporarily disabled

                <View onClick={() => window.open('https://adtriba.com')} class="buttonize">
                    <NotificationComponent
                        notification={
                            noReturningData
                                ? 'There does not appear to be returning customer data. Please adjust date range or contact support.'
                                : null
                        }
                    />
                </View>
                */}
                <View row justify="flex-start">
                    <Heading large class="mr-900">
                        Performance report
                    </Heading>
                    <Flexer />
                    <Text class="mr-300 cl-gray-600">Toggle advanced filter</Text>
                    <Toggle onChange={handleToggleAdvanced} on={advanced} disabled={false} />
                    <Text class="ml-600 cl-gray-600">Customer type</Text>
                    <View class="p-relative">
                        <If if={customerTypeDisabled}>
                            <View
                                style={{ zIndex: 10, opacity: 0.5, backgroundColor: '#F0F3F5' }}
                                class="w-100 h-100 p-absolute"
                            />
                        </If>
                        <Options
                            class="mr-500 ml-300"
                            onOptionSelected={(index: number) => {
                                resetFilters()
                                setCustomerType(getPropertyNameAtIndex(NEW_RETURNING, index))
                            }}
                        >
                            {Object.keys(NEW_RETURNING).map((property: string, index: number) => (
                                <Option key={index} selected={property == customerType}>
                                    {property.toSentenceCase()}
                                </Option>
                            ))}
                        </Options>
                    </View>
                    <Popover
                        width={350}
                        header={null}
                        icon={null}
                        title={null}
                        position={POPOVER.POSITION.LEFT}
                        render={({ dismiss }) => (
                            <View class="p-400">
                                <Text>
                                    Filter the report by new & returning customer types. For more information, please
                                    contact{' '}
                                    <a
                                        href="mailto:support@adtriba.com?subject=New and returning customers"
                                        target="_blank"
                                    >
                                        support
                                    </a>
                                    .
                                </Text>
                            </View>
                        )}
                    >
                        <View class="mt-200">
                            <Icon icon="info" size={15} color={COLOR.COLOR_GRAY_500} class="buttonize" />
                        </View>
                    </Popover>
                    <Button circle onClick={() => setInsightsModal(true)} class="ml-900">
                        <ButtonIcon icon="zap" />
                    </Button>
                </View>
                <If if={advanced}>
                    <Panel class="mb-900 mt-900" width="100%">
                        <View width="100%">
                            <If if={filter.length == 0}>
                                <Text class="mt-900">
                                    You do not have any filter rules applied. Click on the "Add a new filter row" button
                                    to add a rule. Remember to select "save".
                                </Text>
                            </If>
                            <Filter
                                preSelect
                                noLogic
                                noAddGroups
                                noDeleteGroups
                                buttonText="Save"
                                buttonIcon="reload"
                                comparisons={FILTER.COMPARISONS}
                                groups={[{ logic: '', rows: filter }]}
                                options={filterOptions}
                                onChange={handleFilterChange}
                            />
                        </View>
                    </Panel>
                </If>
                <If if={!advanced}>
                    <View row class="mb-900 mt-900">
                        <View flex={1}>
                            <View flex={1} class="p-relative">
                                <If if={limited.channels}>
                                    <LimitedComponent onClick={handleLimitedClick} />
                                </If>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label={getLimitedSelectLabel('Filter by channels', limited.channels)}
                                    selected={channelsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setChannelsSelectedCache(indexes)}
                                >
                                    {filterChannels.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1} class="pl-900 pr-900">
                            <View flex={1} class="p-relative">
                                <If if={limited.sources}>
                                    <LimitedComponent onClick={handleLimitedClick} />
                                </If>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label={getLimitedSelectLabel('Filter by sources', limited.sources)}
                                    selected={sourcesSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setSourcesSelectedCache(indexes)}
                                >
                                    {filterSources.map((filterOption: any, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View flex={1}>
                            <View flex={1} class="p-relative">
                                <If if={limited.campaigns}>
                                    <LimitedComponent onClick={handleLimitedClick} />
                                </If>
                                <Select
                                    multi
                                    showClear
                                    filterable
                                    width="100%"
                                    label={getLimitedSelectLabel('Filter by campaigns', limited.campaigns)}
                                    selected={campaignsSelectedCache}
                                    placeholder="None selected"
                                    backgroundColor={COLOR.COLOR_GRAY_100}
                                    onSelect={(indexes: number[]) => setCampaignsSelectedCache(indexes)}
                                >
                                    {filterCampaigns.map((filterOption, index: number) => (
                                        <SelectOption key={index}>{filterOption.label}</SelectOption>
                                    ))}
                                </Select>
                            </View>
                        </View>

                        <View class="ml-800 p-relative" style={{ top: 12 }} column flex="none">
                            <Button circle onClick={handleFilterUpdate} small>
                                <ButtonIcon icon="reload" />
                            </Button>
                        </View>
                    </View>
                </If>
                <View row class="mb-500 p-relative" justify="space-between">
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label={accountId === 'abb4659a-3d04-11ee-91f0-eb4c1d749b4d' ? 'Conversions CLV' : 'Conversions'}
                        icon="thumbs-up"
                        color="#00AEFF"
                        amount={metrics.total.conversions}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.conversions > 0}
                                down={metrics.percent.conversions < 0}
                                green={metrics.colors.conversions == COLORS.GREEN}
                                red={metrics.colors.conversions == COLORS.RED}
                            />
                        }
                        format={NUMERAL.FORMATS.NUMERIC}
                        description={numeral(metrics.percent.conversions).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label="Revenue"
                        icon="money"
                        color="#1CC7D0"
                        amount={metrics.total.revenue}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.revenue > 0}
                                down={metrics.percent.revenue < 0}
                                green={metrics.colors.revenue == COLORS.GREEN}
                                red={metrics.colors.revenue == COLORS.RED}
                            />
                        }
                        currencySymbol={getSymbolFromCurrency(selectedProject?.currency ?? 'EUR')}
                        format={NUMERAL.FORMATS.NUMERIC}
                        description={numeral(metrics.percent.revenue).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label={accountId === 'abb4659a-3d04-11ee-91f0-eb4c1d749b4d' ? 'Conversions' : 'Conversions CLV'}
                        icon="thumbs-up"
                        color="#00AEFF"
                        amount={metrics.total.clv}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.clv > 0}
                                down={metrics.percent.clv < 0}
                                green={metrics.colors.clv == COLORS.GREEN}
                                red={metrics.colors.clv == COLORS.RED}
                            />
                        }
                        format={NUMERAL.FORMATS.NUMERIC}
                        description={numeral(metrics.percent.clv).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label="Spend"
                        icon="tag"
                        color="#4885ef"
                        amount={metrics.total.spend}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.spend > 0}
                                down={metrics.percent.spend < 0}
                                green={metrics.colors.spend == COLORS.GREEN}
                                red={metrics.colors.spend == COLORS.RED}
                            />
                        }
                        currencySymbol={getSymbolFromCurrency(selectedProject?.currency ?? 'EUR')}
                        format={NUMERAL.FORMATS.NUMERIC_SHORT}
                        description={numeral(metrics.percent.spend).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label="CPA"
                        icon="arrow-up"
                        color="#8E43E7"
                        amount={metrics.total.cpa}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.cpa > 0}
                                down={metrics.percent.cpa < 0}
                                green={metrics.colors.cpa == COLORS.GREEN}
                                red={metrics.colors.cpa == COLORS.RED}
                            />
                        }
                        currencySymbol={getSymbolFromCurrency(selectedProject?.currency ?? 'EUR')}
                        format={NUMERAL.FORMATS.NUMERIC_SHORT}
                        description={numeral(metrics.percent.cpa).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                    <Metric
                        loading={loadingTotals}
                        width="16%"
                        label="ROAS"
                        icon="arrow-up"
                        color="#B84592"
                        amount={metrics.total.roas}
                        trend={
                            <Trend
                                small
                                up={metrics.percent.roas > 0}
                                down={metrics.percent.roas < 0}
                                green={metrics.colors.roas == COLORS.GREEN}
                                red={metrics.colors.roas == COLORS.RED}
                            />
                        }
                        format={NUMERAL.FORMATS.PERCENT}
                        description={numeral(metrics.percent.roas).format(NUMERAL.FORMATS.PERCENT)}
                        showHelp
                    />
                </View>
                <Panel width="100%" class="mb-500 performance-component__table" loading={loadingPerformanceTableData}>
                    <Table
                        pageSize={30}
                        data={performanceTableData}
                        onCleanup={(data) => {
                            switch (level) {
                                case PERFORMANCE_LEVELS.SOURCE:
                                    setLevel(PERFORMANCE_LEVELS.CHANNEL)
                                    break
                                case PERFORMANCE_LEVELS.CAMPAIGN:
                                    setLevel(PERFORMANCE_LEVELS.SOURCE)
                                    break
                            }
                        }}
                        onLoad={(dataIndex: number, row: any) => {
                            const hash = row[row.length - 1]
                            const index = performanceData.findIndex((data: any) => data.hash == hash)
                            const data = performanceData[index]

                            switch (level) {
                                case PERFORMANCE_LEVELS.CHANNEL:
                                    setChannelLevel(data)
                                    setLevel(PERFORMANCE_LEVELS.SOURCE)
                                    break
                                case PERFORMANCE_LEVELS.SOURCE:
                                    if (process.env.REACT_APP_NODE_ENV == 'production') {
                                        posthog.capture('performancereport_campaignlevel', { accountId })
                                    }
                                    setSourceLevel(data)
                                    setLevel(PERFORMANCE_LEVELS.CAMPAIGN)
                                    break
                            }
                        }}
                    />
                </Panel>
                <Panel width="100%" class="mb-500" loading={loadingTimeline}>
                    <LineChartDual
                        title="ROAS / Spend Over Time"
                        header={
                            <Options
                                onOptionSelected={(index: number) => {
                                    const timeframe = CHART_TIME_PRESETS[index]

                                    setTimeframe(timeframe)
                                    app.setApp({
                                        ...app,
                                        fromDate: timeframe.startDate,
                                        toDate: timeframe.endDate,
                                    })
                                }}
                            >
                                {CHART_TIME_PRESETS.map((preset: any, index: number) => (
                                    <Option key={index} selected={preset.text == timeframe.text}>
                                        {preset.text}
                                    </Option>
                                ))}
                            </Options>
                        }
                        data={linechartData}
                    />
                </Panel>
                <Panel
                    width="100%"
                    class="mb-500 performance-component__comparison-table"
                    loading={loadingComparisonTableData}
                >
                    <Table
                        pageSize={30}
                        data={comparisonTableData}
                        onCleanup={(data) => {
                            switch (levelComparison) {
                                case COMPARISON_LEVELS.SOURCE:
                                    setLevelComparison(COMPARISON_LEVELS.CHANNEL)
                                    break
                                case COMPARISON_LEVELS.CAMPAIGN:
                                    setLevelComparison(COMPARISON_LEVELS.SOURCE)
                                    break
                            }
                        }}
                        onLoad={(dataIndex: number, row: any) => {
                            const hash = row[row.length - 1]
                            const index = comparisonData.findIndex((data: any) => data.hash == hash)
                            const data = comparisonData[index]

                            switch (levelComparison) {
                                case COMPARISON_LEVELS.CHANNEL:
                                    setChannelLevelComparison(data)
                                    setLevelComparison(COMPARISON_LEVELS.SOURCE)
                                    break
                                case COMPARISON_LEVELS.SOURCE:
                                    setSourceLevelComparison(data)
                                    setLevelComparison(COMPARISON_LEVELS.CAMPAIGN)
                                    break
                            }
                        }}
                    />
                </Panel>
                <Panel width="100%" class="mb-500" loading={loadingLastClickValidation}>
                    <Table
                        data={lastclickTableData}
                        onCleanup={(data: any) => setLastclickTableData(data)}
                        onLoad={(dataIndex: number, row: any) => {
                            // logger('LOADING! -> ', dataIndex, row)
                            // Fetch some new data via API
                            // And add that data to the history
                            // setData([...data, defaultTableData('Second one', 'Second Wine', 'Second Water', true)])
                        }}
                    />
                </Panel>
            </View>
        </>
    )
}
