// import * as environment from './environment'
import dayjs from 'dayjs'
import { IBarChartGroup } from '@adtriba/ui/lib/bar-chart/bar-chart'

// export const CZ_APP_KEY: string = environment.CZ_APP_KEY
// export const API_HOST: string = environment.API_HOST
// export const API_HOST_V2: string = environment.API_HOST_V2
// export const API_HOST_V3: string = environment.API_HOST_V3
// export const API_HOST_V4: string = environment.API_HOST_V4
// export const NODE_ENV: string = environment.NODE_ENV
// export const SENTRY_DSN: string = environment.SENTRY_DSN
// export const AUTH0_DOMAIN: string = environment.AUTH0_DOMAIN
// export const AUTH0_CLIENTID: string = environment.AUTH0_CLIENTID
// export const AUTH0_AUDIENCE: string = environment.AUTH0_AUDIENCE
export const JWT: string = 'JWT'
export const SUCCESS: any = {}
export const CHUNK_SIZE: number = 5
export const MESSAGE_TIMEOUT: number = 5000
export const SPINNERS = {
    CIRCLE: 'CIRCLE',
}
export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const DATE_FORMAT_PICKER = 'YYYY.MM.DD'
export const DATE_FORMAT_METRIC = 'MMM D, YYYY'
export const TABLE = {
    PAGE_SIZE: 7,
    SORT_ASC: 'SORT_ASC',
    SORT_DESC: 'SORT_DESC',
    ALIGN: {
        LEFT: 'LEFT',
        CENTER: 'CENTER',
        RIGHT: 'RIGHT',
    },
    HIDDEN: 'HIDDEN',
}
export const ERROR = {
    API: {
        GENERAL: 'There has been an error.',
        NO_REPORT_FOR_PROJECT: 'There is no report for this project.',
        NO_REPORT_DATA: 'There is no data for this report.',
    },
    CHANNEL_MAPPER: {
        NO_MAPPINGS: 'You have no mappings',
    },
    INTEGRATIONS: {
        GOOGLE_AUTH_FAILED: 'We could not connect your account',
    },
    FETCH_ACCOUNT_DATA: 'Could not fetch account data',
    INVALID_DATE_SELECTED: 'Please select a valid date range',
    INVITE_EMAIL_INVALID: 'Please use a valid email address',
    UNVERIFIED_EMAIL: 'Please verify your email address',
    USER_DELETE: 'Could not delete user',
    INVITE_DELETE: 'Could not delete invite',
    INVITE_SEND: 'Could not send invite',
    ACCOUNT_CREATE: 'We could create an account for you',
    S3_VERIFICATION: 'We could not verify your S3 account',
    GENERAL: 'There has been an error',
    MEDIAPLAN: {
        VALIDATION: 'Please complete all fields',
        MIN_SPEND_TOO_HIGH: 'Min spend cannot be higher than max',
        EMPTY_OR_0: 'This cannot be empty or 0',
        MIN_SPEND_HIGHER_TOTAL: 'Min spend cannot be higher than total',
    },
    ACCOUNT: {
        NAME: 'Name cannot be blank.',
    },
    VALID_SETTINGS: 'Please select valid a project or report',
    SPENDMAPPER: {
        INVALID_RULES: 'You cannot have an invalid ruleset.',
        DUPLICATE_MAPPING: 'One or more of these campaigns have already been mapped.',
    },
}
export const COLOR = {
    COLOR_GRAY_100: '#F8F9FA',
    COLOR_GRAY_300: '#EAEDEF',
    COLOR_GRAY_400: '#CFD4D9',
    COLOR_GRAY_500: '#ACB5BD',
    COLOR_GRAY_700: '#404c5a',
    COLOR_WHITE: '#ffffff',
    RED: 'RED',
    GREEN: 'GREEN',
    COLOR_ELECTRIC: '#00AEFF',
    COLOR_AQUA: '#1CC7D0',
    COLOR_MAGENTA: '#B84592',
}
export const NO_DATE = 'No date selected'
export const DATE_PRESETS = [
    [
        dayjs().subtract(6, 'months'),
        dayjs().subtract(5, 'months'),
        dayjs().subtract(4, 'months'),
        dayjs().subtract(3, 'months'),
        dayjs().subtract(2, 'months'),
        dayjs().subtract(1, 'months'),
    ].map((date) => ({
        name: date.format('MMMM, YYYY'),
        start: date.startOf('month'),
        end: date.endOf('month'),
    })),
    [
        {
            name: 'Last 12 months',
            start: dayjs().subtract(12, 'months'),
            end: dayjs(),
        },
        {
            name: 'Last 3 months',
            start: dayjs().subtract(3, 'months'),
            end: dayjs(),
        },
        {
            name: 'Last 60 days',
            start: dayjs().subtract(60, 'days'),
            end: dayjs(),
        },
        {
            name: 'Last 30 days',
            start: dayjs().subtract(30, 'days'),
            end: dayjs(),
        },
        {
            name: 'Last month',
            start: dayjs().subtract(1, 'months').startOf('month'),
            end: dayjs().subtract(1, 'months').endOf('month'),
        },
        {
            name: 'This month',
            start: dayjs().startOf('month'),
            end: dayjs(),
        },
    ],
    [
        {
            name: 'Christmas',
            start: dayjs('2023-12-15'),
            end: dayjs('2023-12-31'),
        },
        {
            name: 'Winter',
            start: dayjs('2023-12-21'), // Winter starts in December of the previous year
            end: dayjs('2024-03-20'), // Ends in March of the current year
        },
        {
            name: 'Easter',
            // Assuming Easter dates vary, let's place it in 2023 considering the context
            start: dayjs('2023-04-02'), // Estimated start date for Easter period in 2023
            end: dayjs('2023-04-16'), // Estimated end date for Easter period in 2023
        },
        {
            name: 'Spring',
            start: dayjs('2023-03-20'), // Spring starts in March of 2023
            end: dayjs('2023-06-21'), // Ends in June of 2023
        },
        {
            name: 'Summer',
            start: dayjs('2023-06-21'), // Summer starts in June of 2023
            end: dayjs('2023-09-22'), // Ends in September of 2023
        },
        {
            name: 'Fall',
            start: dayjs('2023-09-22'), // Fall starts in September of 2023
            end: dayjs('2023-12-21'), // Ends in December of 2023
        },
        {
            name: 'Black Friday',
            // Black Friday 2023 based on the 4th Thursday of November
            start: dayjs('2023-11-24'), // Estimated Black Friday 2023
            end: dayjs('2023-11-24'), // Single day event, assuming Black Friday is the day after Thanksgiving
        },
        {
            name: 'Cyber Monday',
            // Cyber Monday 2023 following Thanksgiving
            start: dayjs('2023-11-27'), // Estimated Cyber Monday 2023
            end: dayjs('2023-11-27'), // Single day event
        },
    ],
]
export const ADMIN = 'ADMIN'
export const STATUS = {
    BUSY: 'BUSY',
    FAILED: 'FAILED',
    READY: 'READY',
}
export const THEME = {
    LABEL: {
        DARK: 'DARK',
        LIGHT: 'LIGHT',
        RED: 'RED',
        ORANGE: 'ORANGE',
        GREEN: 'GREEN',
    },
    BUTTON: {
        OUTLINE: 'OUTLINE',
    },
    TREND: {
        GREEN: 'GREEN',
        RED: 'RED',
        GREY: 'GREY',
    },
}
export const SIZE = {
    XSMALL: 'XSMALL',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    XLARGE: 'XLARGE',
}
export const NOT_AVAILABLE = 'Not Available'
export const POSITION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    CENTER: 'CENTER',
}
export const UP = 'UP'
export const DOWN = 'DOWN'
export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'
export const TOP = 'TOP'
export const SMALL = 'SMALL'
export const LARGE = 'LARGE'
export const BOTTOM = 'BOTTOM'
export const NONE = 'NONE'
export const NUMERAL = {
    FORMATS: {
        PERCENT: '0,0.00%',
        PERCENT_SHORT: '0.00%',
        PERCENT_MEDIUM: '0.000%',
        PERCENT_LONG: '0,0.00%',
        CURRENCY: '$ 0,0.00',
        CURRENCY_NO_LOCALE: '0,0.00',
        CURRENCY_SHORT: '$ 0.00a',
        CURRENCY_MEDIUM: '$ 0.000a',
        CURRENCY_LONG: '$ 0,0.00',
        NUMERIC: '0,0.00',
        NUMERIC_WHOLE: '0a',
        NUMERIC_SHORT: '0.00a',
        NUMERIC_MEDIUM: '0.000a',
        NUMERIC_LONG: '0,0.00',
    },
}
export const POPOVER = {
    POSITION: {
        TOP: 'TOP',
        BOTTOM: 'BOTTOM',
        LEFT: 'LEFT',
        RIGHT: 'RIGHT',
    },
}
export const TOOLTIP = {
    POSITION: {
        TOP: 'TOP',
        BOTTOM: 'BOTTOM',
        LEFT: 'LEFT',
        RIGHT: 'RIGHT',
    },
}
export const PRODUCT: any = {
    CORE: 'CORE',
    SPHERE: 'SPHERE',
    RISE: 'RISE',
}
export const ROLE: any = {
    ADMIN: 'ADMIN',
    READER: 'READER',
    EDITOR: 'EDITOR',
}
export const CORE = 'CORE'
export const SPHERE = 'SPHERE'
export const RISE = 'RISE'
export const COMPARISON: any = {
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    CONTAINS: 'CONTAINS',
    NOT_CONTAINS: 'NOT_CONTAINS',
    REGEX: 'REGEX',
}
export const LOGIC: any = {
    OR: 'OR',
    AND: 'AND',
}
export const COLORS = {
    TOOLBAR: {
        INACTIVE: '#D0D4D9',
        ACTIVE: '#54BBC5',
    },
    RED: 'RED',
    GREEN: 'GREEN',
}
export const COUNTS = [10, 25, 50, 75, 100]
export const DEFAULT_DAYS: number = 10
export const CHART_TIME_PRESETS = [
    {
        endDate: dayjs(),
        startDate: dayjs().subtract(1, 'months'),
        text: '1M',
    },
    {
        endDate: dayjs(),
        startDate: dayjs().subtract(3, 'months'),
        text: '3M',
    },
    {
        endDate: dayjs(),
        startDate: dayjs().subtract(1, 'years'),
        text: '1Y',
    },
    {
        endDate: dayjs(),
        startDate: dayjs().startOf('year'),
        text: 'YTD',
    },
]
export const LEVELS = {
    CHANNEL: 'CHANNEL',
    SOURCE: 'SOURCE',
    CAMPAIGN: 'CAMPAIGN',
}
export const ATTRIBUTION_MODELS = {
    CLV: 'CLV',
    REGULAR: 'REGULAR',
}
export const NEW_RETURNING = {
    NEW: 'NEW',
    RETURNING: 'RETURNING',
    ALL: 'ALL',
}
export const FILTERS = {
    TYPE_ONE: 'TYPE_ONE',
    TYPE_TWO: 'TYPE_TWO',
}
export const OPPORTUNITY_LABELS = {
    PERFORMANCE: {
        icon: 'performance',
        text: 'Performance',
        red: false,
        green: false,
        electric: false,
        aqua: true,
        link: 'performance',
    },
    CUSTOMER_JOURNEY: {
        icon: 'customerjourney',
        text: 'Customer Journey',
        red: false,
        green: false,
        electric: true,
        aqua: false,
        link: 'customer-journey',
    },
    TRACKING: {
        icon: 'warning-solid',
        text: 'Tracking',
        red: true,
        green: false,
        electric: false,
        aqua: false,
        link: 'tracking',
    },
}
export const CONVERSION_TYPES = {
    ALL: 'ALL',
    TRANSACTION: 'TRANSACTION',
}
export const CURRENCIES = {
    EUR: 'EUR',
    USD: 'USD',
}
export const DEMO_BARCHART: IBarChartGroup[] = [
    {
        legend: 'CPV',
        color: COLOR.COLOR_GRAY_500,
        values: [
            { metric: 'CPV', count: 1, label: 'Bar one' },
            { metric: 'CPV', count: 1.5, label: 'Bar two' },
        ],
    },
    {
        legend: 'CPA',
        color: COLOR.COLOR_GRAY_300,
        values: [
            { metric: 'CPA', count: 2, label: 'Bar one' },
            { metric: 'CPA', count: 1.75, label: 'Bar two' },
        ],
    },
]
export const TIMEZONES = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg',
]
export const JOURNEY_MULTIPLIER: number = 20
export const CHANNEL_MAPPER_HELP: string = 'CHANNEL_MAPPER_HELP'
export const CHANNEL_FILTER_OPTIONS = ['Medium', 'Sources']
export const FILTER_STORAGE = 'FILTER_STORAGE'
export const CHANNEL_MAPPER_V1 = {
    LEVELS: ['channel', 'source', 'campaign', 'medium'],
    OPERATORS: [
        'equals',
        'not_equals',
        'contains',
        'not_contains',
        'starts_with',
        'in',
        'equals_ignore_case',
        'not_equals_ignore_case',
        'contains_ignore_case',
        'not_contains_ignore_case',
        'starts_with_ignore_case',
        'in_ignore_case',
        'regex',
        'not_regex',
    ],
}

export const SPEND_MAPPER = {
    TABLE_CELL_CONTENT_MAX_LENGTH: 70,
    PAGE_SIZE: 12,
    HELP: 'SPEND_MAPPER_HELP',
}

export const SPEND_MAPPER_V2 = {
    MAPPING_TYPES: [
        { label: 'all', value: 'all' },
        { label: 'hidden', value: 'hidden' },
        { label: 'mapped', value: 'mapped' },
        { label: 'unmapped', value: 'unmapped' },
        { label: 'unmapped with recommendation', value: 'with_recommendation' },
        { label: 'premapped', value: 'premapped' },
    ],
    ORDERS: [
        { label: 'Adtriba Campaign', value: 'adtriba_campaign' },
        { label: 'Adtriba Source', value: 'adtriba_source' },
        { label: 'Connection Name', value: 'connection_name' },
    ],
}
export const SPEND_MAPPER_V1 = {
    MAPPING_TYPES: [
        { label: 'all', value: 'all' },
        { label: 'hidden', value: 'hidden' },
        { label: 'mapped', value: 'mapped' },
        { label: 'unmapped', value: 'unmapped' },
        { label: 'unmapped with recommendation', value: 'with_recommendation' },
    ],
    ORDERS: [
        { label: 'Adtriba Campaign', value: 'adtriba_campaign' },
        { label: 'Adtriba Source', value: 'adtriba_source' },
        { label: 'Connection Name', value: 'connection_name' },
    ],
}
export const PUB_SUB = {
    CHANNEL_MAPPER_V1: {
        EXPAND_ALL: 'EXPAND_ALL',
        COLLAPSE_ALL: 'COLLAPSE_ALL',
        EXPAND_ONE: 'EXPAND_ONE',
    },
    SPEND_MAPPER_V1: {
        RESET_SELECTION: 'RESET_SELECTION',
        APPLY_RECOMMENDATIONS: 'APPLY_RECOMMENDATIONS',
        SAVE_MAPPINGS: 'SAVE_MAPPINGS',
        HIDE_MAPPINGS: 'HIDE_MAPPINGS',
        SELECT_ALL: 'SELECT_ALL',
        SELECT_CAMPAIGN: 'SELECT_CAMPAIGN',
        SET_CHECKED: 'SET_CHECKED',
    },
}
export const REPORTS = 'REPORTS'
export const PROJECTS = 'PROJECTS'
export const ACCOUNTS = 'ACCOUNTS'
export const PLACEHOLDER_UUID_FOR_URL = 'de70ff6c-f6a6-11eb-9a03-0242ac130003'
export const INTEGRATIONS = {
    UNAUTHORIZED: 'UNAUTHORIZED',
    INACTIVE: 'inactive',
    ACTIVE: 'active',
    FAILURES: {
        REDIRECT_FAILED: 'REDIRECT_FAILED',
        REFRESH_TOKEN_CONSENT: 'REFRESH_TOKEN_CONSENT',
        NO_TOKEN_GENERATED: 'NO_TOKEN_GENERATED',
        GENERAL_EXCEPTION: 'GENERAL_EXCEPTION',
    },
}
export const JSON = 'JSON'
export const TEXT = 'TEXT'
export const COMPARISON_LEVELS = {
    CHANNEL: 'channel',
    SOURCE: 'source',
    CAMPAIGN: 'campaign',
}
export const PERFORMANCE_LEVELS = {
    CHANNEL: 'channel',
    SOURCE: 'source',
    CAMPAIGN: 'campaign',
}
export const TV_LEVELS = {
    FLIGHT: 'flight',
    STATION: 'station',
    TIMESLOT: 'timeslot',
}
export const DEFAULT_TABLE = [
    {
        lastDataSet: true,
        heading: '',
        headings: [],
        align: [],
        formats: [],
        data: [],
    },
]
export const INSIGHTS = {
    LEVELS: {
        REPORT: 'report',
    },
    TYPES: {
        PERFORMANCE: 'performance',
        TV: 'tv',
        TRACKING: 'tracking',
        CUSTOMER_JOURNEY: 'customer_journey',
    },
}
export const INSIGHTS_REPORTS = {
    performance: { name: 'Performance Report', color: '#00AEFF', icon: 'performance' },
    customer_journey: { name: 'Customer Journey Report', color: '#8E43E7', icon: 'customer-journey' },
    tracking: { name: 'Tracking Report', color: '#B84592', icon: 'dataquality' },
    tv: { name: 'TV Report', color: '#21262A', icon: 'tv' },
    none: { name: 'All Insights', color: '#21262A', icon: 'zap' },
}
export const ADTRIBA_FIRST_LOGIN = 'ADTRIBA_FIRST_LOGIN'
export const ADTRIBA_SM3_CAMPAIGN_CACHE = 'ADTRIBA_SM3_CAMPAIGN_CACHE'
export const ADTRIBA_SM3_CACHE = 'ADTRIBA_SM3_CACHE'
export const ADTRIBA_SM3_USE_NEW = 'ADTRIBA_SM3_USE_NEW'
export const ADTRIBA_EVENTS = 'ADTRIBA_EVENTS'
export const STORAGE = {
    PERFORMANCE: 'STORAGE_PERFORMANCE',
    TV: 'STORAGE_TV',
    CUSTOMER_JOURNEY: 'STORAGE_CUSTOMER_JOURNEY',
    SPEND_MAPPER: 'STORAGE_SPEND_MAPPER',
}
export const FILTER = {
    COMPARISONS: ['equals', 'not_equals', 'contains', 'not_contains', 'regex'],
}
export const SPEND_MAPPER_V2_ADTRIBA_CAMPAIGNS = 'SPEND_MAPPER_V2_ADTRIBA_CAMPAIGNS'
export const SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS = 'SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS'

export const ATB_SPEND_FALLBACK = 'ATB_SPEND_FALLBACK'
export const ADTRIBA_AUTO_OPEN_CHANNELMAPPING_RULE_INDEX = 'ADTRIBA_AUTO_OPEN_CHANNELMAPPING_RULE_INDEX'

// enable or disable errors` console logging
export const LOG_ERROR = false
export const LOG_ENABLED_ENVIRONMENTS = ['development']
