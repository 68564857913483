import {
    Button,
    ButtonIcon,
    Flexer,
    Heading,
    Icon,
    If,
    Input,
    Label,
    Option,
    Options,
    Panel,
    Table,
    Text,
    View,
} from '@adtriba/ui'
import { IDataSet } from '@adtriba/ui/lib/table/table'
import { useAuth0 } from '@auth0/auth0-react'
import numeral from 'numeral'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import { ATB_SPEND_FALLBACK, COLOR, ERROR, LEVELS, NUMERAL, SPEND_MAPPER, TABLE } from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { getSymbolFromCurrency, logError, sanitizeLocale, setLocale, waitForState } from '../../../../helpers/util'
import { SpendMapperService } from '../../services/spend-mapper-v3.service'
import { CampaignSelect } from './spend-mapper-v3-campaign-select'
import { ChannelMapped } from './spend-mapper-v3-channel-mapped'
import { MappedProgress } from './spend-mapper-v3-mapped-progress'
import { SourceRuleset } from './spend-mapper-v3-source-ruleset'
import { UnmappedSpendTable } from './spend-mapper-v3-unmapped-spends-table'
import './spend-mapper-v3.component.sass'

interface ISpendMapperComponent {
    children?: any
}

export const SpendMapperV3Component: FunctionComponent = (props: ISpendMapperComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId, projectId } = useParams()
    const [filter, setFilter] = useState('')
    const [mapping, setMapping] = useState(0)
    const [level, setLevel] = useState<any>(LEVELS.CHANNEL)
    const [tableData, setTableData] = useState<IDataSet[]>([])
    const [channel, setChannel] = useState<any>({})
    const [source, setSource] = useState<any>({})
    const [unmapped, setUnmapped] = useState('')
    const [enabled, setEnabled] = useState(true)
    const [sourceUnmapped, setSourceUnmapped] = useState('')
    const [unmappedTable, setUnmappedTable] = useState(false)
    const dataCache = useRef(null)
    const projectCache = useRef(null)
    const history = useHistory()
    const [isOldProjectId, setIsOldProjectId] = useState<boolean | undefined>(undefined)
    const selectedProject = app.selectedAccount.find((project: any) => project.id == app.selectedProjectId)

    const getChannels = async () => {
        // Router projectId gets delayed
        // So use global state instead
        const projectId = selectedProject.id
        const token = await getAccessTokenSilently()
        const result = await SpendMapperService.getChannelTable(token, accountId, projectId, {
            project_id: projectId,
        })

        return {
            raw: result,
            table: {
                lastDataSet: false,
                heading: 'Channels',
                headings: ['Channel', 'Status', 'Spend', 'Conversions', 'Sources', 'Mapped Sources'],
                align: [null, null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                widths: [null, 100, null, null, null, 250],
                formats: [
                    null,
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC_WHOLE),
                    null,
                ],
                data: result.map((channel: any) => [
                    channel.channel,
                    <ChannelMapped mapped={channel.total_sources == channel.mapped_sources} />,
                    channel.spend,
                    channel.conversions,
                    channel.total_sources,
                    <MappedProgress
                        blue={Number(channel.mapped_sources)}
                        red={0}
                        total={Number(channel.total_sources)}
                    />,
                ]),
            },
        }
    }

    const getSources = async () => {
        // Router projectId gets delayed
        // So use global state instead
        const projectId = selectedProject.id
        const token = await getAccessTokenSilently()
        const mappings = ['all', 'mapped', 'unmapped']
        const result = await SpendMapperService.getSourceTable(token, accountId, projectId, {
            project_id: projectId,
            channel: channel.channel,
            mapping_state: mappings[mapping],
        })

        return {
            raw: result,
            table: {
                lastDataSet: false,
                heading: channel.channel,
                headings: ['Source', 'Status', 'Spend', 'Conversions', 'Campaigns', 'Mapped Campaigns'],
                align: [null, null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                widths: [null, 150, null, null, null, 250],
                formats: [
                    null,
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC_WHOLE),
                    null,
                ],
                data: result.map((source: any) => {
                    return [
                        source.source === null || source.source === '' ? '' : source.source,
                        <SourceRuleset
                            mapped={source.mapped}
                            source={source.source ?? ''}
                            channel={channel.channel}
                            onMap={handleFetchData}
                        />,
                        source.spend,
                        source.conversions,
                        source.total_campaigns,
                        <MappedProgress
                            blue={Number(source.mapped_campaigns)}
                            red={Number(source.premapped_campaigns)}
                            total={Number(source.total_campaigns)}
                        />,
                    ]
                }),
            },
        }
    }

    const getCampaigns = async () => {
        // Router projectId gets delayed
        // So use global state instead
        const projectId = selectedProject.id
        const sort = (a: any, b: any) => (a.campaign ? a.campaign.localeCompare(b.campaign) : false)
        const token = await getAccessTokenSilently()
        const mappings = ['all', 'mapped', 'unmapped', 'premapped']

        // This list populates the right hand side campaign dropdown
        // when you navigate to the campaigns table view
        const resultCampaignsAdPlatform = await SpendMapperService.getCampaignsAdPlatform(token, accountId, projectId, {
            project_id: projectId,
            channel: channel.channel,
            source: source.source,
        })

        // Get a list of all campaigns
        const result = await SpendMapperService.getCampaignTable(token, accountId, projectId, {
            project_id: projectId,
            channel: channel.channel,
            source: source.source,
            mapping_state: mappings[mapping],
        })

        // Filter out the fallback campaign
        // but also update the source amount for the user to see
        const resultWithoutFallback = result.filter((campaign: any) => {
            if (campaign.campaign == ATB_SPEND_FALLBACK) {
                setSourceUnmapped(campaign.spend || 0)
                return false
            } else {
                return true
            }
        })

        const getCampaignName = (campaign: any) => {
            // If campaign name is longer than the max characters, cut it off and add ellipsis.
            const max = SPEND_MAPPER.TABLE_CELL_CONTENT_MAX_LENGTH
            const campaignName = campaign.length > max ? campaign.substring(0, max) + '...' : campaign
            return campaign === ATB_SPEND_FALLBACK ? 'default source spend' : campaignName
        }

        return {
            raw: resultWithoutFallback,
            table: {
                lastDataSet: true,
                heading: source.source,
                headings: ['Campaign', 'Spend', 'Conversions', 'Ad platform campaign mapping'],
                align: [null, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT, TABLE.ALIGN.RIGHT],
                widths: [null, null, null, 450],
                formats: [
                    null,
                    (val) => numeral(val).format(NUMERAL.FORMATS.CURRENCY),
                    (val) => numeral(val).format(NUMERAL.FORMATS.NUMERIC),
                    null,
                ],
                data: resultWithoutFallback.map((campaign: any) => [
                    // set a maximum length of 70 characters
                    // and applpy ellipsis if it's longer
                    getCampaignName(campaign.campaign),
                    campaign.spend,
                    campaign.conversions,
                    <CampaignSelect
                        isFallback={campaign.campaign == ATB_SPEND_FALLBACK}
                        initialSelected={campaign.mapped_ad_platform_campaign_mapping_ids}
                        mappingId={campaign.adtriba_campaign_mapping_id}
                        options={resultCampaignsAdPlatform.sort(sort)}
                        premapped={campaign.premapped}
                        onSave={handleFetchData}
                    />,
                ]),
            },
        }
    }

    const updateUnmappedSpend = async () => {
        const projectId = selectedProject.id
        const token = await getAccessTokenSilently()
        const result = await SpendMapperService.getCampaignsUnmappedSpend(token, accountId, projectId)

        setUnmapped(numeral(result).format(NUMERAL.FORMATS.CURRENCY_NO_LOCALE))
    }

    const updateDataCheck = async () => {
        const projectId = selectedProject.id
        const token = await getAccessTokenSilently()
        const result = await SpendMapperService.getDataCheck(token, accountId, projectId, {
            project_id: projectId,
        })

        setEnabled(result)
    }

    const handleBreadcrumbNavigation = (level: string) => {
        // Move back through the hierarchy
        switch (level) {
            case LEVELS.SOURCE:
                setLevel(LEVELS.CHANNEL)
                setMapping(0)
                break
            case LEVELS.CAMPAIGN:
                setLevel(LEVELS.SOURCE)
                setMapping(0)
                break
        }
    }

    useEffect(() => {
        if (!selectedProject.id) return
        if (selectedProject.id !== projectId && level !== LEVELS.CHANNEL) {
            setLevel(LEVELS.CHANNEL)
            setMapping(0)
        }
    }),
        [tableData]

    const handleFetchData = async () => {
        setLoading(true)
        setError(null)

        // Update the spend on every page
        // And check validity
        updateUnmappedSpend()
        updateDataCheck()

        try {
            switch (level) {
                case LEVELS.CHANNEL:
                    const channels = await getChannels()
                    dataCache.current = channels.raw
                    setTableData([channels.table])
                    break
                case LEVELS.SOURCE:
                    const sources = await getSources()
                    dataCache.current = sources.raw
                    setTableData([tableData[0], sources.table])
                    break
                case LEVELS.CAMPAIGN:
                    const campaigns = await getCampaigns()
                    dataCache.current = campaigns.raw
                    setTableData([tableData[0], tableData[1], campaigns.table])
                    break
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    useEffect(() => {
        if (!selectedProject) return
        const projectId = selectedProject.id

        const oldProjectId = projectCache.current

        if (isOldProjectId === undefined) {
            setIsOldProjectId(projectId != oldProjectId)
        }
    }, [app])

    useEffect(() => {
        if (!selectedProject) return
        const projectId = selectedProject.id

        if (projectId) {
            if (isOldProjectId !== undefined && !isOldProjectId) {
                projectCache.current = projectId

                setMapping(0)
                setLevel(LEVELS.CHANNEL)
                setTableData([])

                waitForState(() => {
                    handleFetchData()
                })
            } else {
                handleFetchData()
            }
        }
    }, [app, level, channel, mapping])

    useEffect(() => {
        setLocale(sanitizeLocale(selectedProject ? selectedProject.currency : 'EUR'))
    }, [app])

    return (
        <>
            <LoaderComponent loading={loading} />
            <NotificationComponent notification={notification} error={error} />

            <If if={unmappedTable}>
                <UnmappedSpendTable onDismiss={() => setUnmappedTable(false)} />
            </If>

            <View row justify="flex-end" class="mb-900">
                <Heading large>Spend Mapper</Heading>
                <Flexer />
                <If if={enabled}>
                    <Button light small class="mr-200" onClick={() => setUnmappedTable(true)}>
                        <ButtonIcon icon="money" />
                    </Button>
                    <Label red={parseFloat(unmapped) > 0} green={parseFloat(unmapped) == 0} large>
                        Unmapped spend: {getSymbolFromCurrency(selectedProject ? selectedProject.currency : 'EUR')}
                        {unmapped}
                    </Label>
                </If>
            </View>

            <If if={!enabled}>
                <Panel width="100%" class="spend-mapper-component">
                    <View column class="p-900">
                        <Icon icon="info" color={COLOR.COLOR_GRAY_400} size={60} />
                        <Heading class="mt-900">No country or brand detected!</Heading>
                        <Text large class="pt-500 pb-500">
                            We see you haven't defined any country or brand dimensions.
                        </Text>
                        <p className="fs-500 cl-gray-600">
                            To find out more about this, contact our{' '}
                            <a href="mailto:support@adtriba.com" target="_blank" className="cl-electric fw-900">
                                {' '}
                                customer support
                            </a>{' '}
                            or view our{' '}
                            <a
                                href="https://help.adtriba.com/en/articles/4507112-spend-mapper"
                                target="_blank"
                                className="cl-electric fw-900"
                            >
                                help centre
                            </a>
                            .
                        </p>
                    </View>
                </Panel>
            </If>

            {/* We don't use the <If /> component here because we want to */}
            {/* completely block the component render tree (complexity) */}
            {enabled && (
                <Panel width="100%" class="spend-mapper-component">
                    <View>
                        <Input
                            clear
                            type="text"
                            placeholder="Filter"
                            value={filter}
                            onChange={(value) => setFilter(value)}
                            onBlur={(value) => setFilter(value)}
                        />
                    </View>
                    <Table
                        large
                        filter={filter}
                        header={
                            <View class="mr-900" row justify="flex-end">
                                <If if={level == LEVELS.CAMPAIGN && !!sourceUnmapped}>
                                    <Label red large class="ml-900">
                                        UNMAPPED SOURCE SPEND:{' '}
                                        {numeral(sourceUnmapped).format(NUMERAL.FORMATS.CURRENCY)}
                                    </Label>
                                </If>

                                <Flexer />

                                <If if={level == LEVELS.SOURCE}>
                                    <Options onOptionSelected={(index: number) => setMapping(index)}>
                                        <Option selected={mapping == 0}>All</Option>
                                        <Option selected={mapping == 1}>Mapped</Option>
                                        <Option selected={mapping == 2}>Unmapped</Option>
                                    </Options>
                                </If>

                                <If if={level == LEVELS.CAMPAIGN}>
                                    <Options onOptionSelected={(index: number) => setMapping(index)}>
                                        <Option selected={mapping == 0}>All</Option>
                                        <Option selected={mapping == 1}>Mapped</Option>
                                        <Option selected={mapping == 2}>Unmapped</Option>
                                        <Option selected={mapping == 3}>Premapped</Option>
                                    </Options>
                                </If>
                            </View>
                        }
                        pageSize={SPEND_MAPPER.PAGE_SIZE}
                        data={tableData}
                        onCleanup={() => handleBreadcrumbNavigation(level)}
                        onLoad={(dataIndex: number, row: any) => {
                            const hash = row[0]
                            const index = dataCache.current.findIndex((data: any) => {
                                if (level == LEVELS.CHANNEL) return data.channel == hash
                                if (level == LEVELS.SOURCE) return data.source == hash
                                return false
                            })
                            const data = dataCache.current[index]

                            // If this is disabled, then don't click through
                            if (level == LEVELS.SOURCE && !data.mapped) return

                            // channelLevel & sourceLevel contains the row data of the
                            // clicked on row - we still need to call the API
                            switch (level) {
                                case LEVELS.CHANNEL:
                                    setChannel(data)
                                    setLevel(LEVELS.SOURCE)
                                    setMapping(0)
                                    break
                                case LEVELS.SOURCE:
                                    setSource(data)
                                    setLevel(LEVELS.CAMPAIGN)
                                    setMapping(0)
                                    break
                            }
                        }}
                    />
                </Panel>
            )}
        </>
    )
}
