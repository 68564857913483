 
import { returnParser } from '../../../helpers/util'

export class UserService {
    static async get(token: string): Promise<any> {
        const result: any = await fetch(`${process.env.REACT_APP_API_HOST_V4}/users/me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await returnParser(result)
    }

    static async postVerificationEmail(token: string): Promise<any> {
        const result: any = await fetch(`${process.env.REACT_APP_API_HOST_V2}/users/me/verification-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        console.log(result)
        return await returnParser(result)
    }
}
