import React, { FunctionComponent, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import process from 'process'
import { any } from 'prop-types'

interface IAuth0ProviderWithHistory {
    children: any
}

const Auth0ProviderWithHistory = (props: IAuth0ProviderWithHistory) => {
    const history = useHistory()

    const onRedirectCallback = (appState: any) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {props.children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
