 
import { createURLQueryString, returnParser } from '../../../helpers/util'

export type GetConversionsTable = {
    date_end: string
    date_start: string
}

export type GetConversionsTimeline = {
    date_end: string
    date_start: string
    conversion_type_hash?: string // transaction
}

export type GetImpressionsTimeline = {
    date_end: string
    date_start: string
    publisher_hash?: string // all
}

export type GetMicroConversionCountTimeline = {
    date_end: string
    date_start: string
    event_hash?: string
}

export type GetPageviewsTimeline = {
    date_end: string
    date_start: string
}

export class TrackingService {
    static async getConversionsTable(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetConversionsTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/table?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getConversionsTableDownload(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetConversionsTable
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/table/download?${queryString}`
        return await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
    }

    static async getConversionsTimeline(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetConversionsTimeline
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/timeline?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getConversionsTypes(token: string, accountId: string, projectId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/types`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getImpressionsPublisher(token: string, accountId: string, projectId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/impressions/publisher`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getImpressionsTimeline(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetImpressionsTimeline
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/impressions/timeline?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getMicroConversionCountEvents(token: string, accountId: string, projectId: string): Promise<any> {
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/micro-conversion-count/events`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getMicroConversionCountTimeline(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetMicroConversionCountTimeline
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/micro-conversion-count/timeline?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }

    static async getPageviewsTimeline(
        token: string,
        accountId: string,
        projectId: string,
        queryStringParameters: GetPageviewsTimeline
    ): Promise<any> {
        const queryString = createURLQueryString(queryStringParameters)
        const url = `${process.env.REACT_APP_API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/pageviews/timeline?${queryString}`
        const result: any = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })

        return await returnParser(result)
    }
}
