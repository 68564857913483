import {
    Button,
    ButtonIcon,
    ButtonText,
    Check,
    Confirm,
    Filter,
    Flexer,
    Heading,
    Icon,
    If,
    Option,
    Options,
    Panel,
    Popover,
    Select,
    SelectExt,
    SelectOption,
    TagList,
    Text,
    Tooltip,
    View,
    showToast,
} from '@adtriba/ui'
import { ITagListItem } from '@adtriba/ui/lib/tag/tag'
import { useAuth0 } from '@auth0/auth0-react'
import EventEmitter from 'eventemitter3'
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoaderComponent } from '../../../../components/loader/loader.component'
import { NotificationComponent } from '../../../../components/notification/notification.component'
import {
    COLOR,
    COUNTS,
    ERROR,
    FILTER,
    POPOVER,
    PUB_SUB,
    SPEND_MAPPER,
    SPEND_MAPPER_V2,
    SPEND_MAPPER_V2_ADTRIBA_CAMPAIGNS,
    SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS,
    STORAGE,
    TOOLTIP,
} from '../../../../constants'
import { AppContext } from '../../../../contexts/app.context'
import { classNames, getCacheKey, logError, waitForState } from '../../../../helpers/util'
import { StorageService } from '../../../../services/storage.service'
import { SpendMapperService } from '../../services/spend-mapper-v2.service'
import './spend-mapper-v2.component.sass'

const eventEmitter = new EventEmitter()

const OriginCampaign = (props: any) => {
    const { getAccessTokenSilently } = useAuth0()
    const { accountId } = useParams()
    const [checked, setChecked] = useState(false)
    const [selected, setSelected] = useState(-1)
    const [premapped, setPremapped] = useState(false)
    const { originCampaign, adtribaCampaignsReadyOnWindow } = props
    const adtribaCampaigns = window[SPEND_MAPPER_V2_ADTRIBA_CAMPAIGNS] || []
    const [loadingRecomendations, setLoadingRecomendations] = useState(false)
    const [recommendedAdtribaCampaigns, setRecommendedAdtribaCampaigns] = useState<any>([])
    const [mappingId, setMappingId] = useState(null)
    const classes = classNames({
        'mb-500': true,
        'bdr': checked,
        'bdr-cl-electric': checked,
        'p-300': true,
        'bdr-r-500': true,
        'spend-mapper-component': true,
    })

    const updateCheckedOnWindowObject = (checked: boolean) => {
        if (!window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS]) window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS] = {}
        window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS][originCampaign.id] = checked
    }

    const setCheckedSubscriber = (data) => {
        setChecked(data)
        updateCheckedOnWindowObject(data)
    }

    const saveMappingsSubscriber = (data) => {
        if (!checked) return
        handlePostOrDeleteMappings(selected)
    }

    const resetSelectionSubscriber = (data) => {
        if (!checked) return
        setSelected(-1)
        handleGetCampaignRecommendations()
        setChecked(false)
        updateCheckedOnWindowObject(false)
    }

    const applyRecommendationsSubscriber = (data) => {
        if (!checked) return
        if (recommendedAdtribaCampaigns.length == 0) return
        if (!recommendedAdtribaCampaigns[0]) return
        applyRecommendations(recommendedAdtribaCampaigns[0].id)
    }

    const applyRecommendations = (adtribaCampaignId: string) => {
        const selectedAdtribaCampaignIndex = adtribaCampaigns.findIndex(
            (adtribaCampaign: any) => adtribaCampaign.id == adtribaCampaignId
        )
        setSelected(selectedAdtribaCampaignIndex)
        setChecked(false)
        updateCheckedOnWindowObject(false)
    }

    const handlePostOrDeleteMappings = async (adtribaCampaignIndex: number) => {
        try {
            const token = await getAccessTokenSilently()

            // If there is none selected - then remove the mapping
            // This might be redundant if they click save with nothing selected
            // So make sure there actually is a mappingId
            if (selected == -1 && !!mappingId) {
                await SpendMapperService.deleteCampaignMappings(token, accountId, mappingId)
                setMappingId(null)
                showToast('Successfully deleted!')
                setChecked(false)
                updateCheckedOnWindowObject(false)
            }

            // Only do this if there is a valid adtriba campaign selected
            if (selected != -1) {
                if (!!mappingId) {
                    // If there is a mapping ID - then update it
                    await SpendMapperService.updateCampaignMappings(
                        token,
                        accountId,
                        {
                            adtriba_campaign_id: adtribaCampaigns[adtribaCampaignIndex].id,
                            origin_campaign_id: originCampaign.id,
                        },
                        mappingId
                    )

                    // Notify the user after async
                    showToast('Successfully updated!')

                    // Unselect it
                    setChecked(false)
                    updateCheckedOnWindowObject(false)
                } else {
                    // Otherwise create it
                    const result = await SpendMapperService.createCampaignMappings(token, accountId, {
                        adtriba_campaign_id: adtribaCampaigns[adtribaCampaignIndex].id,
                        origin_campaign_id: originCampaign.id,
                    })

                    // Update the our campaign mappingId
                    setMappingId(result.id)

                    // Notify the user after async
                    showToast('Successfully updated!')

                    // Unselect it
                    setChecked(false)
                    updateCheckedOnWindowObject(false)
                }
            }

            // And also remove the premapping if anything is done
            // with delete / post / put
            setPremapped(false)
            setChecked(false)
            updateCheckedOnWindowObject(false)
        } catch (e) {
            logError(e)
        }
    }

    const handleGetCampaignRecommendations = async () => {
        setLoadingRecomendations(true)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getCampaignRecommendations(token, accountId, {
                origin_campaign_ids: [originCampaign.id],
            })

            // The API returns values that hold the ID for an adtriba campaign & origin campaign
            // it return recommendation for origin campaigns that are not this one
            // so here it's necessary to remove the ones that are not for this origin campaign
            setLoadingRecomendations(false)
            setRecommendedAdtribaCampaigns(
                result
                    // Only use THIS origin campaign recommendations
                    .filter(
                        (recommendedAdtribaCampaign: any) =>
                            recommendedAdtribaCampaign.origin_campaign_id == originCampaign.id
                    )
                    // Find the corresponding rich object adtriba campaign
                    .map((recommendedAdtribaCampaign: any) => {
                        return adtribaCampaigns.find(
                            (adtribaCampaign: any) =>
                                adtribaCampaign.id == recommendedAdtribaCampaign.adtriba_campaign_id
                        )
                    })
            )
        } catch (e) {
            setLoadingRecomendations(false)
            logError(e)
        }
    }

    useEffect(() => {
        eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, saveMappingsSubscriber)
        eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, resetSelectionSubscriber)
        eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS, applyRecommendationsSubscriber)
        eventEmitter.on(PUB_SUB.SPEND_MAPPER_V1.SET_CHECKED, setCheckedSubscriber)

        return () => {
            eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, saveMappingsSubscriber)
            eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, resetSelectionSubscriber)
            eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS, applyRecommendationsSubscriber)
            eventEmitter.removeListener(PUB_SUB.SPEND_MAPPER_V1.SET_CHECKED, setCheckedSubscriber)
        }
    })

    useEffect(() => {
        if (!originCampaign.id) return
        if (!window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS]) window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS] = {}

        window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS][originCampaign.id] = checked
    }, [originCampaign, checked])

    useEffect(() => {
        if (!originCampaign.id) return

        updateCheckedOnWindowObject(false)
        setChecked(false)
        setMappingId(originCampaign.campaign_mapping_id)
        setPremapped(originCampaign.premapped)
    }, [originCampaign])

    useEffect(() => {
        if (!originCampaign.id) return
        if (adtribaCampaigns.length == 0) return

        setSelected(
            adtribaCampaigns.findIndex(
                (adtribaCampaign: any) => originCampaign.adtriba_campaign_mapping_id == adtribaCampaign.id
            )
        )
    }, [adtribaCampaigns, adtribaCampaignsReadyOnWindow, originCampaign])

    useEffect(() => {
        if (!originCampaign.id) return
        if (adtribaCampaigns.length == 0) return
        if (selected != -1) return

        handleGetCampaignRecommendations()
    }, [adtribaCampaigns, adtribaCampaignsReadyOnWindow, originCampaign, selected])

    const connectionText = `${originCampaign.connection_name} → ${originCampaign.adtriba_source}`
    const tooltipText = !!originCampaign.adtriba_campaign ? originCampaign.adtriba_campaign : connectionText

    return (
        <>
            <View row class={classes}>
                <View row justify="flex-start">
                    <Check
                        checked={checked}
                        disabled={false}
                        onChange={() => {
                            const newCheck = !checked
                            setChecked(newCheck)
                            updateCheckedOnWindowObject(newCheck)

                            // Wait for the event loop to complete
                            // before checking the window in the parent component
                            waitForState(() => props.onCheck())
                        }}
                    />

                    <Tooltip position={TOOLTIP.POSITION.RIGHT} text={tooltipText}>
                        <View column align="flex-start" flex="none" class="ml-400">
                            <Heading small>{originCampaign.adtriba_campaign}</Heading>
                            <Text>{connectionText}</Text>
                        </View>
                    </Tooltip>
                </View>

                <View column flex={2}>
                    <View row width="100%">
                        <View row class="mr-200">
                            {/*
                            <Select
                                showClear
                                filterable
                                optionsContainerStyles={{ minHeight: '30rem' }}
                                backgroundColor={COLOR.COLOR_GRAY_100}
                                width="100%"
                                placeholder="..."
                                selected={selected}
                                onSelect={(index: number) => setSelected(index)}
                            >
                                {adtribaCampaigns.map((adtribaCampaign: any, index: number) => {
                                    const {
                                        campaign,
                                        project_name,
                                        channel,
                                        source,
                                        formatted_revenue,
                                    } = adtribaCampaign
                                    const selectOptionText = `${campaign} [${project_name} → ${channel} → ${source}] (${formatted_revenue})`

                                    return <SelectOption key={index}>{selectOptionText}</SelectOption>
                                })}
                            </Select>
                            */}

                            <SelectExt
                                width="100%"
                                placeholder="..."
                                selected={selected}
                                onSelect={(index: number) => {
                                    setSelected(index)
                                    if (index == -1) setPremapped(false)
                                }}
                                options={adtribaCampaigns.map((adtribaCampaign: any, index: number) => {
                                    const {
                                        campaign,
                                        project_name,
                                        channel,
                                        source,
                                        formatted_revenue,
                                    } = adtribaCampaign

                                    return {
                                        value: index,
                                        label: `${campaign} [${project_name} → ${channel} → ${source}] (${formatted_revenue})`,
                                    }
                                })}
                            />
                        </View>
                        <Button onClick={() => handlePostOrDeleteMappings(selected)}>
                            <ButtonIcon icon="save" />
                            <ButtonText>Save</ButtonText>
                        </Button>
                    </View>

                    <If if={premapped}>
                        <View row justify="flex-start" width="100%" class="mt-300">
                            <Text small>
                                This campaign has been pre-mapped.{' '}
                                <a
                                    href="https://help.adtriba.com/en/articles/4507112-spend-mapper"
                                    className="cl-electric buttonize fw-700"
                                    target="_blank"
                                >
                                    Find out more.
                                </a>
                            </Text>
                        </View>
                    </If>

                    <If if={!premapped}>
                        <If if={loadingRecomendations}>
                            <View row justify="flex-start" width="100%" class="mt-300">
                                <Text small>Fetching recommendations...</Text>
                            </View>
                        </If>

                        {recommendedAdtribaCampaigns
                            // Filter out invalid adtriba campaigns
                            .filter((recommendedAdtribaCampaign: any) => !!recommendedAdtribaCampaign)
                            // Filter our the selected one (if there is a selection)
                            .filter((recommendedAdtribaCampaign: any) =>
                                adtribaCampaigns[selected]
                                    ? adtribaCampaigns[selected].id != recommendedAdtribaCampaign.id
                                    : true
                            )
                            // Display to the UI
                            .map((recommendedAdtribaCampaign: any, index: number) => {
                                const {
                                    id,
                                    campaign,
                                    project_name,
                                    channel,
                                    source,
                                    formatted_revenue,
                                } = recommendedAdtribaCampaign
                                const recommendationText = `${campaign} [${project_name} → ${channel} → ${source}] (${formatted_revenue})`

                                return (
                                    <View row justify="flex-start" width="100%" class="mt-300" key={index}>
                                        <Text class="ml-300">{recommendationText}</Text>
                                        <Flexer />
                                        <Button light small onClick={() => applyRecommendations(id)}>
                                            <ButtonText>Apply</ButtonText>
                                        </Button>
                                    </View>
                                )
                            })}
                    </If>
                </View>
            </View>
        </>
    )
}

interface ISpendMapperComponent {
    children?: any
}

export const SpendMapperV2Component: FunctionComponent = (props: ISpendMapperComponent): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0()
    const app = useContext(AppContext)
    const [error, setError] = useState(null)
    const [errorOrigin, setErrorOrigin] = useState(null)
    const [errorAdtriba, setErrorAdtriba] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(null)
    const { accountId } = useParams()
    const [confirm, setConfirm] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [count, setCount] = useState(COUNTS[0])
    const [mappingType, setMappingType] = useState(0)
    const [orderBy, setOrderBy] = useState(2)
    const [selectedChannels, setSelectedChannels] = useState<ITagListItem[]>([])
    const [allChannels, setAllChannels] = useState<ITagListItem[]>([])
    const [originCampaigns, setOriginCampaigns] = useState<any>([])
    const [adtribaCampaignsReadyOnWindow, setAdtribaCampaignsReadyOnWindow] = useState(false)
    const showingHiddenMappingType = SPEND_MAPPER_V2.MAPPING_TYPES[mappingType].value == 'hidden'
    const [anyIsChecked, setAnyIsChecked] = useState(false)
    const [allIsChecked, setAllIsChecked] = useState(false)
    const [rules, setRules] = useState([])
    const [filter, setFilter] = useState(false)
    const options = [
        { label: 'Adplatform name', value: 'adtriba_source' },
        { label: 'Adplatform campaign', value: 'adtriba_campaign' },
        { label: 'Data source name', value: 'connection_name' },
    ]
    const history = useHistory()

    const getCheckedOriginCampaignIds = () => {
        return Object.entries(window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS])
            .filter((originCampaign: any) => originCampaign[1])
            .map((originCampaign: any) => originCampaign[0])
    }

    const handleGetOriginCampaigns = async () => {
        setLoading(true)
        setErrorOrigin(null)

        try {
            const filter = rules.map((rule: any) => ({
                field: options[rule.option].value,
                operator: rule.comparison.toLowerCase(),
                value: rule.value,
            }))
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getOriginCampaigns(
                token,
                accountId,
                {
                    count,
                    mapping_type: SPEND_MAPPER_V2.MAPPING_TYPES[mappingType].value,
                    order_by: SPEND_MAPPER_V2.ORDERS[orderBy].value,
                    page: currentPage,
                },
                [filter]
            )
            const { content, page_count, max_page, total_size, page } = result

            setLoading(false)
            setTotalRecords(total_size)
            setCurrentPage(page)
            setPageCount(page_count)
            setMaxPage(max_page)
            setOriginCampaigns(content.sort((a, b) => a.adtriba_campaign.localeCompare(b.adtriba_campaign)))
            setAnyIsChecked(false)
            setAllIsChecked(false)
        } catch (e) {
            setOriginCampaigns([])
            setLoading(false)
            setErrorOrigin(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handleGetAdtribaCampaigns = async () => {
        setLoading(true)
        setErrorAdtriba(null)
        setAdtribaCampaignsReadyOnWindow(false)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getAdtribaCampaigns(token, accountId, {
                exclude_channels: selectedChannels.map((channel: any) => channel.key),
            })

            // Why are we using such an ugly hack instead of React state?
            // Because it's the easy way to solve global state without
            // adding yet another context object & triggering re-renders
            // TODO: in future, we need to migrate this to either Redux
            // or create another global context object - but this works fine
            window[SPEND_MAPPER_V2_ADTRIBA_CAMPAIGNS] = result

            // This flag simply triggers some compure in each component when
            // the campaigns are read on the window object - like recommedations, etc
            setAdtribaCampaignsReadyOnWindow(true)
            setLoading(false)
            setNotification(result.length == 0 ? 'There are no adtriba campaigns' : null)
        } catch (e) {
            setLoading(false)
            setErrorAdtriba(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handleGetAdtribaCampaignsChannel = async () => {
        setLoading(true)
        setError(null)

        try {
            const token = await getAccessTokenSilently()
            const result = await SpendMapperService.getChannelFilter(token, accountId)

            setLoading(false)
            setAllChannels(
                result.map((channel: any) => ({
                    key: channel.channel_hash,
                    label: channel.channel_label,
                }))
            )
        } catch (e) {
            setLoading(false)
            setError(ERROR.API.GENERAL)
            logError(e)
        }
    }

    const handlePostOriginCampaignStatus = async (hidden: boolean) => {
        try {
            const token = await getAccessTokenSilently()
            const checkedOriginCampaignIds = getCheckedOriginCampaignIds()

            // One big API call to mark all selected as hidden
            await Promise.all(
                checkedOriginCampaignIds.map((checkedOriginCampaignId: string) => {
                    return SpendMapperService.patchOriginCampaign(token, accountId, { hidden }, checkedOriginCampaignId)
                })
            )

            // Set these to false
            checkedOriginCampaignIds.map(
                (checkedOriginCampaignId: string) =>
                    (window[SPEND_MAPPER_V2_CHECKED_ORIGIN_CAMPAIGNS][checkedOriginCampaignId] = false)
            )

            // Refetch all origin campaigns
            // This will also uncheck eveerything
            handleGetOriginCampaigns()
        } catch (e) {
            logError(e)
        }
    }

    const handleNext = () => {
        if (currentPage < maxPage) setCurrentPage(currentPage + 1)
    }

    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1)
    }

    const handleReload = () => {
        handleGetOriginCampaigns()
    }

    const handleSelectAll = () => {
        if (allIsChecked) {
            eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.SET_CHECKED, false)
            setAllIsChecked(false)
            setAnyIsChecked(false)
        } else {
            eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.SET_CHECKED, true)
            setAllIsChecked(true)
            setAnyIsChecked(true)
        }
    }

    const handleHideMappings = async () => {
        handlePostOriginCampaignStatus(!showingHiddenMappingType)
    }

    const handleSaveChangedMappings = async () => {
        setConfirm(false)
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.SAVE_MAPPINGS, true)
        setAnyIsChecked(false)
        setAllIsChecked(false)
    }

    const handleApplyRecommendationsSelection = async () => {
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.APPLY_RECOMMENDATIONS, true)
    }

    const handleResetSelection = async () => {
        eventEmitter.emit(PUB_SUB.SPEND_MAPPER_V1.RESET_SELECTION, true)
    }

    const handleOriginCampaignCheck = () => {
        const checkedCampaigns = getCheckedOriginCampaignIds()
        setAnyIsChecked(checkedCampaigns.length > 0)
        setAllIsChecked(checkedCampaigns.length == originCampaigns.length)
    }

    const handleFilterChange = (groups: any) => {
        const rules = groups[0].rows
        setRules(rules)
        StorageService.setStorage(getCacheKey(accountId, STORAGE.SPEND_MAPPER), JSON.stringify(rules))
    }

    // Get origin campaigns
    useEffect(() => {
        if (!accountId) return
        handleGetOriginCampaigns()
    }, [accountId, currentPage, count, mappingType, orderBy, rules])

    // Get adtriba camapaigns
    useEffect(() => {
        if (!accountId) return
        handleGetAdtribaCampaigns()
    }, [accountId, selectedChannels])

    // Get channels
    useEffect(() => {
        if (!accountId) return
        handleGetAdtribaCampaignsChannel()
    }, [accountId])

    // Filter cache - but call this after the event loop is done
    // There might be cases where groups don't affect state updates
    useEffect(() => {
        waitForState(() => {
            const groups = StorageService.getStorage(getCacheKey(accountId, STORAGE.SPEND_MAPPER))

            if (groups) {
                const parsedRules = JSON.parse(groups)

                if (parsedRules.length != 0) {
                    setRules(parsedRules)
                    setFilter(true)
                }
            }
        })
    }, [])

    // Disabled for now - but keeping for future
    useEffect(() => {
        if (!StorageService.getStorage(SPEND_MAPPER.HELP)) {
            // Set any modal state only once
            // StorageService.setStorage(SPEND_MAPPER_HELP, 'true')
        }
    }, [])

    // Calculate the mins/maxes
    let min = currentPage * pageCount
    let max = currentPage * pageCount + pageCount

    // If the max is higher than total record count
    // Then rather use the totalRecords
    if (max > totalRecords) max = totalRecords

    // Catch 0 records, otherwise start from 1 (more readable)
    if (totalRecords == 0) min = 0
    if (totalRecords > 0) min = min + 1

    return (
        <>
            <Confirm
                visible={confirm}
                title="Are you sure?"
                description="Are you sure you want to apply selected actions?"
                onConfirm={handleSaveChangedMappings}
                onDismiss={() => setConfirm(false)}
            />

            <LoaderComponent loading={loading} />
            <NotificationComponent notification={notification} error={error || errorOrigin || errorAdtriba} />

            <View row justify="flex-end" class="mb-900">
                <Heading large>Spend Mapper</Heading>
                <Flexer />
            </View>

            <Panel width="100%">
                <View width="100%">
                    <View
                        style={{ zIndex: 1 }}
                        row
                        justify="flex-start"
                        class={!filter ? 'p-500 buttonize' : 'bdr-bottom bdr-cl-gray-200 p-500 buttonize p-relative'}
                    >
                        <View onClick={() => setFilter(!filter)} class="buttonize p-relative" style={{ top: 2 }}>
                            <Icon icon={filter ? 'minus' : 'plus'} color={COLOR.COLOR_GRAY_500} size={15} />
                        </View>
                        <Text
                            small
                            class="fw-700 cl-gray-500 ml-200 flex-1 buttonize"
                            onClick={() => setFilter(!filter)}
                        >
                            {filter ? 'Hide' : 'See'} applied filter rules
                        </Text>
                        <Popover
                            width={300}
                            header={null}
                            icon="info"
                            title="Regex"
                            position={POPOVER.POSITION.LEFT}
                            render={({ dismiss }) => (
                                <View class="p-500">
                                    <Text style={{ marginBottom: 10 }}>
                                        Regex is a string of text that allows you to create patterns that help match &
                                        filter text.{' '}
                                        <a
                                            href="https://help.adtriba.com/en/articles/4507112-spend-mapper"
                                            target="_blank"
                                            className="buttonize cl-electric"
                                        >
                                            Click here to find out more
                                        </a>
                                        .
                                    </Text>
                                    <Button onClick={() => dismiss()} small>
                                        <ButtonText>Okay</ButtonText>
                                    </Button>
                                </View>
                            )}
                        >
                            <View class="buttonize">
                                <Icon icon="info" color={COLOR.COLOR_GRAY_500} size={15} />
                            </View>
                        </Popover>
                    </View>
                    <If if={filter}>
                        <View style={{ zIndex: 0 }} class="p-relative">
                            <If if={rules.length == 0}>
                                <Text class="mt-900">
                                    You do not have any filter rules applied. Click on the "Add a new filter row" button
                                    to add a rule. Remember to select "save".
                                </Text>
                            </If>
                            <Filter
                                preSelect
                                noLogic
                                noAddGroups
                                noDeleteGroups
                                buttonText="Save"
                                buttonIcon="check"
                                comparisons={FILTER.COMPARISONS}
                                groups={[{ logic: '', rows: rules }]}
                                options={options}
                                onChange={handleFilterChange}
                            />
                        </View>
                    </If>
                </View>
            </Panel>

            <View row justify="flex-start" class="mt-900 mb-500">
                <Heading xSmall class="uppercase">
                    EXCLUDE CHANNELS
                </Heading>
            </View>

            <View row justify="flex-start" class="mt-500 mb-900">
                <TagList
                    max={allChannels.length}
                    dark
                    selected={[]}
                    tags={selectedChannels}
                    allTags={allChannels}
                    onAdd={(channel: ITagListItem) => setSelectedChannels([...selectedChannels, channel])}
                    onDelete={(index1: number) =>
                        setSelectedChannels(
                            selectedChannels.filter((_: ITagListItem, index2: number) => index1 != index2)
                        )
                    }
                    onSelect={() => {}}
                />
            </View>

            <View row justify="flex-start" class="mt-900">
                <Check onChange={handleSelectAll} checked={allIsChecked} disabled={false} />
                <Text class="pl-500">Select all</Text>
                <Flexer />

                <Button light small class="mr-900" onClick={handleReload}>
                    <ButtonIcon icon="reload" />
                </Button>

                <View row class="mr-900 p-relative" style={{ top: -11 }}>
                    <View class="mr-200" width={200}>
                        <View row>
                            <Select
                                width="100%"
                                placeholder="Mapping status"
                                label="Mapping status"
                                selected={mappingType}
                                onSelect={(index: number) => {
                                    setMappingType(index)
                                    setCurrentPage(0)
                                }}
                            >
                                {SPEND_MAPPER_V2.MAPPING_TYPES.map((mapping: any, index: number) => (
                                    <SelectOption key={index}>{mapping.label}</SelectOption>
                                ))}
                            </Select>
                        </View>
                    </View>
                    <View width={200}>
                        <View row>
                            <Select
                                width="100%"
                                placeholder="Order by"
                                label="Order by"
                                selected={orderBy}
                                onSelect={(index: number) => {
                                    setOrderBy(index)
                                    setCurrentPage(0)
                                }}
                            >
                                {SPEND_MAPPER_V2.ORDERS.map((order: any, index: number) => (
                                    <SelectOption key={index}>{order.label}</SelectOption>
                                ))}
                            </Select>
                        </View>
                    </View>
                </View>

                <Options
                    onOptionSelected={(index: number) => {
                        setCount(COUNTS[index])
                        setCurrentPage(0)
                    }}
                    class="mr-500"
                >
                    {COUNTS.map((c: number, index: number) => (
                        <Option key={index} selected={count == c}>
                            {c}
                        </Option>
                    ))}
                </Options>

                <Text class="pr-900">
                    {min} - {max} / {totalRecords}
                </Text>
                <View class="buttonize" width={20} height={20} onClick={handlePrevious}>
                    <Icon icon="chevron-left" size={20} color="#CFD4D9" />
                </View>
                <View class="buttonize ml-300" width={20} height={20} onClick={handleNext}>
                    <Icon icon="chevron-right" size={20} color="#CFD4D9" />
                </View>
            </View>

            <Panel width="100%" class="mt-900">
                <View row>
                    <View row justify="flex-start">
                        <Heading class="mr-500">Ad platform campaigns</Heading>
                        <Popover
                            width={300}
                            position={POPOVER.POSITION.TOP}
                            render={({ dismiss }) => (
                                <View class="p-500">
                                    <Heading small>What is this?</Heading>
                                    <Text style={{ marginBottom: 10 }}>All origin campaigns</Text>
                                </View>
                            )}
                        >
                            <View width={20} height={20} class="buttonize">
                                <Icon icon="info" color={COLOR.COLOR_GRAY_400} size={20} />
                            </View>
                        </Popover>
                    </View>
                    <View row justify="flex-start" flex={2}>
                        <Heading class="mr-500">Adtriba campaigns</Heading>
                        <Popover
                            width={300}
                            position={POPOVER.POSITION.TOP}
                            render={({ dismiss }) => (
                                <View class="p-500">
                                    <Heading small>What is this?</Heading>
                                    <Text style={{ marginBottom: 10 }}>
                                        Available Adtriba campaigns & suggested mappings
                                    </Text>
                                </View>
                            )}
                        >
                            <View width={20} height={20} class="buttonize">
                                <Icon icon="info" color={COLOR.COLOR_GRAY_400} size={20} />
                            </View>
                        </Popover>
                    </View>
                </View>
            </Panel>

            <Panel width="100%" class="mt-100">
                {originCampaigns.map((originCampaign: any, index: number) => {
                    return (
                        <OriginCampaign
                            key={index}
                            originCampaign={originCampaign}
                            adtribaCampaignsReadyOnWindow={adtribaCampaignsReadyOnWindow}
                            onCheck={handleOriginCampaignCheck}
                        />
                    )
                })}
            </Panel>

            <View height={100} />

            <View class="p-fixed bg-cl-aqua" style={{ bottom: 0, left: 0, zIndex: 1000 }} width="100%">
                <View row justify="flex-start" class="p-500">
                    <Button onClick={handleApplyRecommendationsSelection}>
                        <ButtonIcon icon="check" />
                        <ButtonText>Apply recommendations</ButtonText>
                    </Button>
                    <Button onClick={handleResetSelection}>
                        <ButtonIcon icon="check" />
                        <ButtonText>Reset recommendations</ButtonText>
                    </Button>
                    <Flexer />
                    <If if={anyIsChecked}>
                        <Button onClick={handleHideMappings}>
                            <ButtonIcon icon="check" />
                            <ButtonText>{showingHiddenMappingType ? 'Unhide selected' : 'Hide selected'}</ButtonText>
                        </Button>
                        <Button onClick={() => setConfirm(true)}>
                            <ButtonIcon icon="check" />
                            <ButtonText>Save mappings</ButtonText>
                        </Button>
                    </If>
                </View>
            </View>
        </>
    )
}
