import dayjs from 'dayjs'
import React, { createContext, useState } from 'react'
import { IAppContext } from '../types/IAppContext'

export const defaultAppContextState: any = {
    accountsList: [],
    selectedAccount: [],
    selectedAccountId: null,
    selectedProjectId: null,
    selectedReportId: null,
    account: -1,
    accounts: [],
    project: -1,
    projects: [],
    reports: [],
    report: -1,
    fromDate: dayjs().subtract(1, 'months'),
    toDate: dayjs().subtract(1, 'days'),
    role: null,
}

const defaultAppContext: IAppContext = {
    ...defaultAppContextState,
    setApp: (appState: IAppContext) => {},
}

export const AppContext = createContext(defaultAppContext)

export const AppProvider = (props) => {
    const setApp = (appState: IAppContext) => {
        setState({ ...state, ...appState })
    }
    const initialState: IAppContext = {
        ...defaultAppContext,
        setApp,
    }
    const [state, setState] = useState(initialState)

    return <AppContext.Provider value={{ ...state, setApp }}>{props.children}</AppContext.Provider>
}
