import { Button, ButtonIcon, ButtonText, Flexer, Modal, ScrollView, Text, View } from '@adtriba/ui'
import React from 'react'
import { POSITION } from '../../../../constants'
import './spend-mapper-v3.component.sass'

export const Validations = ({ campaign, source, title, visible, validations, button, onConfirm, onDismiss }) => {
    if (!visible) return null

    return (
        <Modal
            width="600px"
            height="75%"
            position={POSITION.CENTER}
            borderRadius={20}
            onDismiss={onDismiss}
            footer={
                <View row>
                    <Button onClick={onDismiss} light>
                        <ButtonIcon icon="x" />
                        <ButtonText>Cancel</ButtonText>
                    </Button>
                    <Flexer />
                    <Button onClick={onConfirm}>
                        <ButtonIcon icon="check" />
                        <ButtonText>{button}</ButtonText>
                    </Button>
                </View>
            }
            header={null}
            icon="info"
            title={title}
            showClose={true}
        >
            <ScrollView>
                {source && (
                    <>
                        {validations.map((validation: any, index: number) => (
                            <View class="w-100 bdr-cl-gray-300 bdr-bottom" key={index}>
                                <Text class="p-500">
                                    <span className="cl-gray-600">Ruleset conflicts with</span>{' '}
                                    <span className="fw-900">
                                        {validation.channel} → {validation.source}
                                    </span>
                                </Text>
                            </View>
                        ))}
                    </>
                )}

                {campaign && (
                    <>
                        {validations.map((validation: any, index: number) => (
                            <View class="w-100 bdr-cl-gray-300 bdr-bottom" key={index}>
                                <Text class="p-500">
                                    <span className="fw-900">{validation.adplatform_campaign}</span>{' '}
                                    <span className="cl-gray-600">is already present in</span>{' '}
                                    <span className="fw-900">
                                        {validation.current_adtriba_channel} → {validation.current_adtriba_source} →{' '}
                                        {validation.current_adtriba_campaign}
                                    </span>
                                </Text>
                            </View>
                        ))}
                    </>
                )}
            </ScrollView>
        </Modal>
    )
}
